import React from "react";
import "./Footer.css";
import Logo from "../../assests/images/LogoDM.png";
import { useNavigate } from "react-router-dom"; // Example for React Router
import { SiWhatsapp } from "react-icons/si";
import { Link } from "react-router-dom";
function Footer() {
  const navigate = useNavigate();
  return (
 
    <div style={{ background: "rgb(223,223,223)" }}>
      <div className="container  mt-5">
        <div className="row">
          <div className="col-lg-4 mt-4">
            <div>
              <img src={Logo} alt="Logo" width="80%" />
            </div>
            <div>
              <p className="text-reset ps-1 pe-5 text-justify mt-2">
                <b>
                  " Success is not only about building a network but also about
                  that the reclaimings controls of one's time and the destiny,
                  choosing life that aligns with the personal aspirations."
                </b>
              </p>
            </div>
          </div>
          <div className="col-lg-1"></div>
          <div className="col-lg-3 mt-4 ">
            <h6 className="text-uppercase fw-bold mb-4">Help & Support</h6>
          
              <div className="">
              <p>
            <Link
                to="/about"
                className="text-decoration-none"
                style={{ color: "black" }}
              >
              ABOUT
              </Link>
            </p>
            {/* <p>  
              <a href="#!" className="text-reset text-decoration-none">
                FAQ
              </a>
            </p> */}
            <p>
            <Link
                to="/term"
                className="text-decoration-none"
                style={{ color: "black" }}
              >
               TERMS AND CONDITION
              </Link>
            </p>
            <p>
              <Link
                to="/policy"
                className="text-decoration-none"
                style={{ color: "black" }}
              >
                PRIVACY POLICY

              </Link>
            </p>
            <p>
              <Link
                to="/shippingPolicy"
                className="text-decoration-none"
                style={{ color: "black" }}
              >
                SHIPPING POLICY

              </Link>
            </p>
            <p>
              <Link
                to="/return&refund"
                className="text-decoration-none"
                style={{ color: "black" }}
              >
               RETURN & REFUND POLICY
              </Link>
            </p>
              </div>
             

               
            
           
          </div>
          <div className="col-lg-1"></div>
          <div className="col-lg-3 mt-4">
            <h6 className="text-uppercase fw-bold mb-4">Contact Us </h6>
            <p className="text">
              {" "}
              <img
                className="img-responsive me-1"
                src={require("../../assests/images/whatsapp.png")}
                alt=""
                width="9%"
              />{" "}
              Whatsapp Us<span className="text-primary"> : 8778960600</span>
            </p>

            <p className="text ">
              {" "}
              <img
                className="img-responsive me-2 "
                src={require("../../assests/images/phone.png")}
                alt=""
                width="7%"
              />
              Call Us<span className="text-primary"> : 8778960600</span>
            </p>

            <p className="text ">
              {" "}
              <img
                className="img-responsive me-2 "
                src={require("../../assests/images/gmail.png")}
                alt=""
                width="7%"
              />
             Email<span className="text-primary"> :  <a href="mailto:dcrownmart@gmail.com">dcrownmart@gmail.com</a></span>
            </p>


            {/* <div className="main ">
              <div className="icons whatsapp">
                <i className="fa-brands fa-whatsapp"></i>
                <span >WhatsApp</span>
                
              </div>
              <div className="icons phone">
              <i className="fa-solid fa-phone-flip"></i>
              <span >Phone</span>
              </div>
              
            </div> */}
            <h3>Download the app</h3>
            <a href="https://play.google.com/store/apps/details?id=com.crownmart">
              {" "}
              <img
                className="img-responsive "
                src={require("../../assests/images/playstore.png")}
                alt=""
                width="40%"
              />
            </a>
          </div>
        </div>
       
    
      </div>
      <div className="text-center p-4  " id='copy'>
        © 2024 Copyright:
           <a className="text-reset fw-bold text-decoration-none" target="blank" href="https://www.dcrownmart.com/" >Dcrownmart</a>
     </div>
    </div>
  );
}

export default Footer;
