import React from 'react'
import { useNavigate } from 'react-router-dom'

function PageNotFound() {
  const navigate=useNavigate()
  return (
    <div className='notfoundmain'>

      <div className='contain notfound'>
      </div>
      <div className='text-center p-3'>
        <h2>404 Page Not Found</h2>
        <div className=" col-2 mx-auto sign-up  mt-5">
          <button className="btn " type="submit" onClick={()=>navigate(-1)}>Go Back Home</button>

        </div>
      </div>
    </div>
  )
}

export default PageNotFound