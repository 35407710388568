import React, { useState, useEffect } from "react";
import axios from "axios";
import Notransaction from "../../assests/images/notransaction.jpeg";
import { useNavigate } from "react-router-dom";
import { Grid, Paper, Typography, TextField, Box } from "@mui/material";

function WithdrawHistory() {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  useEffect(() => {
    async function fetchData() {
      try {
        const Data = JSON.parse(localStorage.getItem("user_detail"));
        const UserID = Data.id;
        console.log(UserID, "UserID");
        const token = localStorage.getItem("auth_token");
        if (!token) {
          return navigate("/");
        }
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/withdrawl/getWithdrawlTransactionDetials`,
          {
            params: { UserID },
            headers: {
              Authorization: token,
            },
          }
        );
        if (response.status === 200) {
          const jsonData = response.data.AccountId;
          console.log(jsonData);
          const pendingData = jsonData.filter(
            (product) => product.transaction_Status === "pending"
          );
          if (pendingData.length > 0) {
            setData([
              ...pendingData,
              ...jsonData.filter(
                (product) => product.payment_status !== "pending"
              ),
            ]);
          } else {
            setData(jsonData);
          }
        }
      } catch (err) {
        console.error("Error:", err);
      }
    }

    fetchData();
  }, []);

  return (
    <div>
      {data ? (
        <div>
          <Paper
            elevation={2}
            sx={{
              paddingBottom: "20px",
              width: {
                lg: "30%",
              },
              // backgroundColor:"#555587",

              margin: "110px auto",
            }}
          >
            <Box
              className="p-3"
              sx={{
                textAlign: "center",
                backgroundColor: "#ffc107",
                color: "white",
                marginBottom: "10px",
              }}
            >
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Withdraw History
              </Typography>
            </Box>
            <Box>
              <Grid>
                <div className="pt-1 ">
                  {data.map((item) => (
                    <Paper
                      elevation={2}
                      sx={{
                        margin: "20px",
                        width: {
                          lg: "90%",
                        },
                      }}
                    >
                      <div className="  container ">
                        <div className="pt-2">
                          <p>Withdraw ID : {item.id}</p>
                        </div>
                        <div className="d-flex justify-content-around">
                          <div>
                            <div class="dropdown">
                              <button
                                style={{
                                  color: "green",
                                }}
                                class="btn_with fs-5  dropdown-toggle fw-bold"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                ₹{item.enterAmount}
                              </button>
                              <ul class="dropdown-menu fw-bold">
                                <li className="d-flex">
                                  <a class="dropdown-item fw-bold">
                                    Your Enter Amount
                                  </a>
                                  <p className="fw-bold">:</p>
                                  <b
                                    class="dropdown-item fw-bold"
                                    style={{
                                      color: "green",
                                    }}
                                  >
                                    ₹{item.enterAmount}
                                  </b>
                                </li>
                                <li className="d-flex ">
                                  <a class="dropdown-item fw-bold">
                                    3.0% Transaction Fee
                                  </a>
                                  <p className="fw-bold">:</p>
                                  <b
                                    class="dropdown-item fw-bold"
                                    style={{
                                      color: "red",
                                    }}
                                  >
                                    - ₹{item.withadrawChargeFee}
                                  </b>
                                </li>

                                <li className="d-flex ">
                                  <a class="dropdown-item fw-bold">
                                    Total Amount
                                  </a>
                                  <p className="fw-bold">:</p>
                                  <b
                                    class="dropdown-item fw-bold"
                                    style={{
                                      color: "green",
                                    }}
                                  >
                                    ₹{item.acutal_Amount_Recieved}
                                  </b>
                                </li>
                              </ul>
                            </div>
                            {/* <div>
                            
                              <h4 style={{ color: "green" }}>
                                {" "}
                              </h4>
                              
                            </div> */}

                            <h6>Ac.No : {item.Account_No}</h6>
                          </div>
                          <div>
                            <h5
                              className="d-flex justify-content-end"
                              style={{
                                color:
                                  item.transaction_Status === "Approved"
                                    ? "green"
                                    : "red",
                              }}
                            >
                              {item.transaction_Status === "Approved"
                                ? "Success"
                                : "Processing ..."}
                            </h5>

                            <p className="pt-2">{`${new Date(
                              item.updated_at
                            ).toLocaleDateString("en-US", {
                              weekday: "long",
                            })}, ${new Date(
                              item.updated_at
                            ).toLocaleDateString()},${new Date(
                              item.updated_at
                            ).toLocaleTimeString()},`}</p>
                          </div>
                        </div>
                      </div>
                    </Paper>
                  ))}
                </div>
              </Grid>
            </Box>
          </Paper>
        </div>
      ) : (
        <div className="text-center mt-5">
          <img
            src={Notransaction}
            alt="Notransaction.img"
            style={{ width: "40%" }}
          />
          <h2>No Withdraw Transaction</h2>
        </div>
      )}
    </div>
  );
}

export default WithdrawHistory;
