import React from "react";
import { useEffect } from "react";

function Privacypolicy() {

  useEffect(() => {
    // Scroll to the top of the page when component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{ marginTop: "150px" }}>
      <h3 className="text-center">Privacy & Policy</h3>
      <div className="p-5 text-justify">
        <p>
          Your privacy is important to us, so DCrown mart, a India, has created
          the following Privacy Policy ("Policy") to let you know what
          information we collect when you visit our Site
          <a href='www.dcrownmart.com'> www.dcrownmart.com</a> ("Site"), why we collect it and how we use
          it.
        </p>
        <p>
          The terms "You," "Your," "Yours" and "User" refer to the
          entity/person/organization using our Site. When this Policy mentions
          "We", "Us," and "Our" it refers to DCrown mart and its subsidiaries and
          affiliates. This Privacy Policy is governed by our Terms of Services
          For any questions regarding this Policy or any requests regarding the
          processing of personal data, please contact us at  <a href="mailto:dcrownmart@gmail.com">dcrownmart@gmail.com</a>
        </p>
        <h5>1. INFORMATION WE COLLECT</h5>
        <p>
          FROM YOU We collect the information You provide to us and this
          information is necessary for the adequate performance of the
          contractual arrangement which is in place between You and us and allow
          us to comply with our legal obligations. Account Signup Information.
          When You create the account, we ask You to provide the signup
          information, such as Email, Name, Surname, Phone, Username, Passwords,
          Personal Number, Address. Login information. We collect Login
          information if You are logging to our account with Authentication
          Data.
        </p>
        <h5>2. INFORMATION WE COLLECT</h5>
        <p>
          AUTOMATICALLY When you use our Site or contact us directly we may
          collect information, including your personal information, about the
          way you act in our Site, the services You use and how You use them.
          This information is necessary for the adequate performance of the
          contract between You and us, to enable us to comply with legal
          obligations and given our legitimate interest in being able to provide
          and improve the functionalities of the Site.
        </p>
        <p>
          Log data and Device information. We automatically collect log data and
          device information when you access and use the Site, even if you have
          not created an Account or logged in. That information includes, among
          other things: Internet protocol (IP) addresses, Browser type, Internet
          service provider (ISP), Referring/exit pages, Operating system, Date/
          time stamp, Clickstream data. Tracking technologies and Cookies. We
          use Cookies, Customer number. We also automatically collect
          information about device's operating system,. 3. THE WAY WE USE YOUR
          INFORMATION We process your information adhering to the general data
          processing principles. We may use the information we collect through
          our Site for a number of reasons, including to: to identify user to
          create trusted environment to provide support to ensure data security
          and prevent fraud We will normally collect personal information from
          you only where we have your consent to do so, where we need the
          personal information to perform a contract with you, or where the
          processing is in our legitimate business interests.
        </p>
        <h5>4. DIRECT MARKETING</h5>
        <p>
          We may use your provided contact details for direct marketing. These
          direct marketing offers, depending on your preferences, may be
          personalized taking into account any other information which you have
          provided to us (e.g. location, social media profile information, etc.)
          or we have collected or generated from other sources as described
          below. If you wish to change the preferences and withdraw the consent
          for direct marketing, you may exercise such option at any time you
          wish by updating your preferences in your account. You are free to opt
          out of our newsletters at any time. You can do this by clicking on a
          link for that purpose at the bottom of our e-mail with the newsletters
        </p>
        <h5> 5. COOKIES</h5>
        <p>We do not use cookies or other tracking technologies.</p>
        <h5>6. INFORMATION FROM MINORS</h5>
        <p>
          This site and our Services are not intended for or directed to persons
          under the age of 18. We do not knowingly collect or ask for
          information from minors. We do not knowingly allow minors to use our
          Site or Services. We will delete any information we discover is
          collected from a minor. Please contact us using the contact details
          below if you believe we might have information about a minor.
        </p>
        <h5>7. SENSITIVE INFORMATION</h5>
        <p>
          We do not collect sensitive information such as political opinions,
          religious or philosophical beliefs, racial or ethnic origin, genetic
          data, biometric data, health data or data related a sexual
          orientation. Please do not send, upload, or provide us any sensitive
          data and contact us using the contact details below if you believe
          that we might have such information. We have a right to delete any
          information we believe it might contain sensitive data.
        </p>
        <h5>8. PAYMENT INFORMATION</h5>
        <p>
          To order and use our services we may require you to provide certain
          financial information to facilitate the processing of payments. We may
          collect your name, credit or debit card type, expiration date, billing
          address, the certain digits of your card number and details of your
          purchase. The exact personal data collected will vary depending on the
          payment method.
        </p>
        <h5>9. THIRD PARTY LINKS</h5>
        <p>
          Our Site may have links to other websites. Please review their privacy
          policies to learn more about how they collect and use your personal
          data, because we do not control their policies and personal data
          Privacy & Policy processing practices.
        </p>
        <h5>10. RETENTION</h5>
        <p>
          We retain your personal information to provide services to you and as
          otherwise necessary to comply with our legal obligation, resolve
          disputes, and enforce our agreements. We will retain your personal
          information as long as we need it to provide services to you, unless
          we are otherwise required by law or regulations to retain your
          personal information longer. 11. SECURITY We have implemented security
          measures designed to protect the personal information you share with
          us, including physical, electronic and procedural measures. Among
          other things, we regularly monitor our systems for possible
          vulnerabilities and attacks. Regardless of the measures and efforts
          taken by us, the transmission of information via internet, email or
          text message is not completely secure. We do not guarantee the
          absolute protection and security of your personal information or any
          other User Content you upload, publish or otherwise share with us or
          anyone else. We therefore encourage you to avoid providing us or
          anyone with any sensitive information of which you believe its
          disclosure could cause you substantial or irreparable harm. If you
          have any questions regarding the security of our Site or Services, you
          are welcome to contact us at <a href="mailto:dcrownmart@gmail.com">dcrownmart@gmail.com</a>.
        </p>
        <h5>12. YOUR RIGHTS</h5>
        <p>
          You are entitled to a range of rights regarding the protection of your
          personal information. Those rights are: The right to access the
          information we have about you. If you wish to access your personal
          information that we collect, you can do so at any time by contacting
          us using the contact details provided below. TI The right to rectify
          inaccurate information about you. You can correct, update or request
          deletion of your personal information by contacting us using the
          contact details provided below. The right to object the processing.
          When we rely on your consent to process your personal information, you
          may withdraw consent at any time by contacting us using the contact
          details provided below. This will not affect the lawfulness of
          processing prior to the withdrawal of your consent. The right to lodge
          a complaint. You can raise questions or complaints to the national
          Data Protection Agency in your country of residence in the event where
          your rights may have been infringed. However, we recommend attempting
          to reach a peaceful resolution of the possible dispute by contacting
          us first. The right to erase any data concerning you. You may demand
          erasure of data without undue delay for legitimate reasons, e.g. where
          data is no longer necessary for the purposes it was collected, or
          where the data has been unlawfully processed.
        </p>
        <h5>13. APPLICATION OF POLICY</h5>
        <p>
          This Policy was created with the help of the TermsHub Privacy Policy
          Generator and applies only to the services offered by our Company. Our
          Policy does not apply to services offered by other companies or
          individuals, including products or sites that may be displayed to you
          in search results, sites that may include our services or other sites
          linked from our Site or Services.
        </p>
        <h5>14. AMENDMENTS</h5>
        <p>
          Our Policy may change from time to time. We will post any Policy
          changes on our Site and, if the changes are significant, we may
          consider providing a more explicit notice (including, for certain
          services, email notification of Policy changes).
        </p>
        <h5>15. ACCEPTANCE OF THIS POLICY</h5>
        <p>
          We assume that all Users of this Site have carefully read this
          document and agree to its contents. If someone does not agree with
          this Policy, they should refrain from using our Site. We reserve the
          right to change our Policy at any time and inform by using the way as
          indicated in Section 14. Continued use of this Site implies acceptance
          of the revised Policy.
        </p>
        <h5>16. FURTHER INFORMATION</h5>
        <p>
          If you have any further questions regarding the data we collect, or
          how we use it, then please feel free to contact us at the details as
          indicated above.
        </p>
      </div>
    </div>
  );
}

export default Privacypolicy;
