import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import profile from "../../assests/images/avatar_profile.png";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

function Adminview() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [value, setValue] = useState({});

  useEffect(()=>{
    const token = localStorage.getItem('auth_token')
    if (!token) {
        return navigate('/')
    }
},[])

  useEffect(() => {
    async function fetchdata() {
      try {
        const token = localStorage.getItem("auth_token");
        if (!token) {
          return navigate("/");
        }
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/admin/views/${id}`
        );
        if (response.status === 200) {
          const jsonData = response.data;
          setValue(jsonData);
        }
      } catch (err) {
        console.error(err);
      }
    }
    fetchdata();
  }, [id, navigate]);

  const fields = [
    { field: "id", label: "User Id" },
    { field: "user_address", label: "User Address" },
    { field: "name", label: "Name" },
    { field: "email", label: "Email" },
    { field: "contact", label: "Contact" },
    { field: "membership_package", label: "Membership Package" },
    { field: "package_level", label: "Package Level" },
    { field: "ref_id", label: "Ref Id" },
    { field: "discount_point", label: "Discount Point" },
    { field: `wallet`, label: "Wallet" },
    { field: "reinvestment", label: "Reinvestment" },
    { field: "country_code", label: "Country Code" },
    { field: "payment_status", label: "Payment Status" },
    { field: "super_deal_count_1", label: "Super Deal Count - 1" },
    { field: "super_deal_count_2", label: "Super Deal Count - 2" },
    { field: "super_deal_count_3", label: "Super Deal Count - 3" },
    { field: "kingdom_count", label: "Kingdom Count - 1" },
    { field: "kingdom_count_2", label: "Kingdom Count - 2" },
    { field: "crown_kingdom_count", label: "Crown Kingdom Count - 1" },
    { field: "crown_kingdom_count_2", label: "Crown Kingdom Count - 2" },
    { field: "royal_crown_kingdom_count", label: "Royal Crown Kingdom Count" },
  ];

  return (
    <>
      <Helmet>
        <title> DCrown Mart | AdminPanel</title>
      </Helmet>
      <div className="cards ">
        <div className=" cardview1 mt-5 mb-5 ">
          <div className="row">
            <div className="col-lg-4 col-12 p-5 ">
              <div className="text-center profile-img card-details">
                <img
                  src={profile}
                  className="img-fluid"
                  alt={value.name}
                  height={"50%"}
                  width={"50%"}
                />
                <h3 className="mt-2">{value.name}</h3>
                <h3 className="">{value.ref_id}</h3>
              </div>
            </div>

            <div className="col-lg-8 col-12">
              <div className="p-3">
                <h2 className="mb-3 profile-head">User Details</h2>
                <div className="row">
                  <div className="col-lg-12 col-12 user_data">
                    {fields.map((fieldObj, index) => (
                      <React.Fragment key={index}>
                        <div className="row data_user">
                          <div className="col-lg-5 col-4">
                            <h5>{fieldObj.label}</h5>
                          </div>
                          
                          <div className="col-lg-2 col-1">
                            <h5>:</h5>
                          </div>
                          <div className="col-lg-5 col-7">
                            <h5>{value[fieldObj.field]}</h5>
                          </div>
                          <hr></hr>
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Adminview;
