import React from 'react'
import giftvocher from '../assests/images/giftvocher3-removebg-preview.png'
import giftvocher2 from '../assests/images/giftvocher6-removebg-preview.png'
import giftvocher3 from '../assests/images/giftvocher1-removebg-preview.png'
import giftvocher4 from '../assests/images/giftvocher4-removebg-preview.png'
import giftvocher5 from '../assests/images/giftvocher2__2_-removebg-preview.png'
import giftvocher6 from '../assests/images/giftvocher5-removebg-preview.png'
import giftvocher7 from '../assests/images/giftvocher12.png'
import giftvocher8 from '../assests/images/giftvocher10.png'
import giftvocher9 from '../assests/images/giftvocher9.png'
import giftvocher10 from '../assests/images/giftvocher8.png'

import giftvocher12 from '../assests/images/giftvocher14.png'
import giftvocher13 from '../assests/images/giftvocher15.png'













const gift = [
    { id: 1, name: 'CROWN SILVER', description: '0-5% Discount for every purchase', voucher: 'Gift Voucher 1' ,image:giftvocher},
    { id: 2, name: 'CROWN STAR', description: 'Greeting Momento', voucher: 'Gift Voucher 2',image:giftvocher2 },
    { id: 3, name: 'CROWN GOLD', description: '1,000/- Cash Bonus', voucher: 'Gift Voucher 3',image:giftvocher3 }, 
    { id: 4, name: 'CROWN PLATINUM', description: '5,000/- Worth Silver',voucher: 'Gift Voucher 4',image:giftvocher4},






]
const gift2 = [
    { id: 1, name: 'CROWN DIAMOND', description: '10,000/- Worth Water Purifier', voucher: 'Gift Voucher 5',image:giftvocher5},
    { id: 2, name: 'CROWN ACE', description: '1,00,000/- Worth Home Appliance', voucher: 'Gift Voucher 6',image:giftvocher6 },
    { id: 3, name: 'CROWN CONQUEROR', description: '10,00,000/- Worth Plot', voucher: 'Gift Voucher 7' ,image:giftvocher7},
    { id: 4, name: 'CROWN AMBASSADOR', description: '100,00,000/- Worth Cash Bonus', voucher: 'Gift Voucher 8',image:giftvocher8 },
]
const gift3 = [
    { id: 1, name: 'ROYAL CROWN SILVER', description: '0-10% Discount for every purchase', voucher: 'Gift Voucher 9' ,image:giftvocher },
    { id: 2, name: 'ROYAL CROWN STAR', description: 'Greeting Momento', voucher: 'Gift Voucher 10',image:giftvocher2  },
    { id: 3, name: 'ROYAL CROWN GOLD', description: '1 Gram Gold Coin', voucher: 'Gift Voucher 11',image:giftvocher8  },
    { id: 4, name: 'ROYAL CROWN PLATINUM', description: '15,000/- Worth Smartphone', voucher: 'Gift Voucher 12',image:giftvocher9  },

]
const gift4 = [
    { id: 1, name: 'ROYAL CROWN DIAMOND', description: '1,00,000/- Worth Bikes', voucher: 'Gift Voucher 13',image:giftvocher10 },
    { id: 2, name: 'ROYAL CROWN ACE', description: '5,00,000/- Worth Car', voucher: 'Gift Voucher 14',image:giftvocher12},
    { id: 3, name: 'ROYAL CROWN CONQUEROR', description: '50,00,000/- Worth Individual Villa', voucher: 'Gift Voucher 15',image:giftvocher13 },
    { id: 4, name: 'ROYAL CROWN AMBASSADOR', description: '50,000,000/- Worth Cash Bonus', voucher: 'Gift Voucher 16',image:giftvocher8 },

]

function Giftvocher() {
    const handlestyle={
        fontSize:'18px',
        fontWeight:'bold',
        color:'#ffbe0b '
    }
    const handleColor={
        color:'#32582E'
    }
    const handleTitle={
        fontWeight:700,
        color:'#32582E',
        backgroundColor:'#fff27f',
        padding:'10px 0px'
    }
   
    
    return (
        <>
        <div className='giftVocher-mobile-top'>

            <div className='giftVocher-cart mt-4' >
                {
                    gift.map((item) => (
                        <div>
                            <div className="card mb-4" style={{ width: '18rem' }}>
                                <h5 className='text-center'style={handleTitle}>{item.voucher}</h5>
                                <div className='imageStyle'>
                                    <img src={item.image} className="card-img-top" alt="gift" width='100' />
                                </div>
                                <div className="card-body">
                                    <h5 className="card-title" style={handlestyle}>{item.name}</h5>
                                    <p className="card-text" style={handleColor}>{item.description}</p>

                                </div>
                            </div>

                        </div>
                    ))
                }
            </div>

            <div className='giftVocher-cart mt-4 ' >
                {
                    gift2.map((item) => (
                        <div>
                            <div className="card mb-4" style={{ width: '18rem' }}>
                                <h5 className='text-center  ' style={handleTitle}>{item.voucher}</h5>
                                <div  className='imageStyle'>
                                    <img src={item.image} className="card-img-top" alt="gift" width='100' />
                                </div>
                                <div className="card-body">
                                    <h5 className="card-title"style={handlestyle}>{item.name}</h5>
                                    <p className="card-text"  style={handleColor}>{item.description}</p>

                                </div>
                            </div>

                        </div>
                    ))
                }
            </div>
            <div className='giftVocher-cart mt-4' >
                {
                    gift3.map((item) => (
                        <div>
                            <div className="card mb-4" style={{ width: '18rem' }}>
                                <h5 className='text-center  ' style={handleTitle}>{item.voucher}</h5>
                                <div className='imageStyle'>
                                    <img src={item.image} className="card-img-top" alt="gift" width='100' />
                                </div>
                                <div className="card-body">
                                    <h5 className="card-title" style={handlestyle}>{item.name}</h5>
                                    <p className="card-text" style={handleColor} >{item.description}</p>

                                </div>
                            </div>

                        </div>
                    ))
                }
            </div>
            <div className='giftVocher-cart mt-4' >
                {
                    gift4.map((item) => (
                        <div>
                            <div className="card mb-4" style={{ width: '18rem' }}>
                                <h5 className='text-center ' style={handleTitle}>{item.voucher}</h5>
                                <div  className='imageStyle'>
                                    <img src={item.image} className="card-img-top" alt="gift" width='100' />
                                </div>
                                <div className="card-body">
                                    <h5 className="card-title" style={handlestyle}>{item.name}</h5>
                                    <p className="card-text"  style={handleColor}>{item.description}</p>

                                </div>
                            </div>

                        </div>
                    ))
                }
            </div>



            </div>
        </>

    )
}

export default Giftvocher