import React, { useState } from "react";
import Logo from "../assests/images/LogoDM.png";
import { toast } from "react-toastify";

import {
  Grid,
  Paper,
  Box,
  Typography,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  FormControl,
  TextField,
  IconButton,
  FormHelperText,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function LoginForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleStyle = {
    backgroundColor: "#ffbe0b",
    color: "white",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    border: "none",
    width: "40%",
    padding: "10px 10px",
    fontWeight: "bold",
  };
  const {
    handleBlur,
    handleChange,
    errors,
    values,
    handleSubmit,
    touched,
    setFieldError,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
      .required('Email / Mobile Number is Required')
      .test('emailOrMobile', 'Invalid email or mobile number', (value) => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        const mobileRegex = /^\d{10}$/;
        return emailRegex.test(value) || mobileRegex.test(value);
      }),

      password: Yup.string().required("Password is required"),
    }),

    onSubmit: (values) => {
      setLoading(true);
      axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/users/login`, values)
        .then((response) => {
          const token = response.data.data.token;
          const user_detail = response.data.data;

          console.log(user_detail,"user_detail")
    
          localStorage.setItem("token", token);
          localStorage.setItem("user_detail", JSON.stringify(user_detail));
    
          setLoading(false);
          toast.success("Login Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 600,
          });
          navigate("/", { state: { userDetails: user_detail } });
        })
        .catch((err) => {
          if (err.response && err.response.status === 404) {
            setFieldError("email", " User not Found");
         
          } else if (err.response && err.response.status === 401) {
            setFieldError("password", "Incorrect Password");
         
          } else {
            console.log(err);
          }
          setLoading(false);
        });
    },
    
  });

  if (loading) {
    return (
      <div className="loading">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    );
  }
  return (
    <Paper
      elevation={3}
      sx={{
        padding: "20px",
        width: {
          lg: "30%",
          height: "75vh",
        },
        margin: "50px auto",
      }}
    >
      <Grid alignItems="center">
        <Box sx={{ textAlign: "center" }}>
          <img src={Logo} width={250} style={{ marginTop: "20px" }} />
          <Typography
            variant="h5"
            sx={{ marginTop: "30px", fontWeight: "bold" }}
          >
            Welcome back!
          </Typography>
          {/* <Typography variant="body" sx={{ marginTop: "15px" }}>
            Log in to your existing account
          </Typography> */}
          <form onSubmit={handleSubmit} className="mt-4">
            <TextField
              id="outlined-basic"
              label="Email /Mobile Number"
              fullWidth
              sx={{ margin: "20px 0" }}
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email ? errors.email : null}
            />
            <FormControl
              label="fullWidth"
              fullWidth
              sx={{ margin: "10px 0" }}
              error={touched.password && Boolean(errors.password)}
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
              {touched.password && errors.password ? (
                <FormHelperText >
                  {errors.password}
                </FormHelperText>
              ) : null}
            </FormControl>
            <Typography variant="body1" sx={{ textAlign: "end" }}>
              <Link
                to="/account/register"
                style={{
                  color: "#ffbe0b",
                  fontWeight: "bold",
                  textDecoration: "none",
                }}
              >
                Forget Password
              </Link>
            </Typography>
            <Box sx={{ textAlign: "center", marginTop: "30px" }}>
              <button style={handleStyle}>Login</button>
              <Typography variant="body1" sx={{ marginTop: "30px" }}>
                Don't have an account?{" "}
                <Link to="/account/signup" style={{ color: "#ffbe0b" }}>
                  Sign Up
                </Link>
              </Typography>
            </Box>  
          </form>
        </Box>
      </Grid>
    </Paper>
  );
}

export default LoginForm;
