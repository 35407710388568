import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

function Checkout() {
  const user_detail = JSON.parse(localStorage.getItem("user_detail"));
  const Email = user_detail.email;
  const location = useLocation();

  const cart = location.state.cartData;
  const order_id = location.state.order_id;

  const price = location.state.price;
  const address = location.state.address;
  const date = location.state.Date;
  const Time = location.state.Time;
  const billing_address = location.state.billing_address;
  const shipping_address = location.state.shipping_address;
  const Token = localStorage.getItem("token");



  useEffect(()=>{
    console.log(billing_address,shipping_address,"shipping_addressshipping_address")
  })
  const config = {
    headers: {
      Authorization: `Bearer ${Token}`,
    },
  };
  // const [orderDetails, setOrderDetails] = useState({
  //   ordered_time: "",
  //   order_Id: "",
  //   total_amount: "",
  //   billing_address: "",
  //   shipping_address: "",
  //   ordered_date: "",
  // });

  // useEffect(() => {
    // async function fetchData(){
  //     try {
  //       const response = await axios.get(
  //         `${base_url}/api/productOrders/GetOrdersDetails`,
  //         config
  //       );
  //       console.log(response);
  //       const {
  //         billing_address,
  //         shipping_address,
  //         ordered_time,
  //         order_Id,
  //         productId,
  //         total_amount,
  //         ordered_date,
  //       } = response.data[0];
  //       setOrderDetails({
  //         ordered_time: ordered_time,
  //         order_Id: order_Id,
  //         productId: productId,
  //         total_amount: total_amount,
  //         ordered_date: ordered_date,
  //         billing_address: JSON.parse(billing_address),
  //         shipping_address: JSON.parse(shipping_address),
  //       });
  //     } catch (error) {
  //       console.log(error);
  //     }
// }
// fetchData();
  // }, []);
  return (
    <div className="container" style={{ marginTop: "130px" }}>
      <div className="row">
        <div className="col-lg-6 col-12 h-25 shadow position-sticky top-0 mt-1 pt-3">
          <div>
            <div className="">
              <h4> Order Details</h4>
            </div>
            <hr></hr>
            <div className="d-flex justify-content-around mt-4">
              {/* <div className=" mt-3 d-flex ju"> */}

              <div>
                <h4>Product</h4>
                {cart.map((item) => (
                  <p>
                    {item["productlist.name"]} × {item.quantity}
                  </p>
                ))}
                <hr></hr>
                <h5>Total</h5>
              </div>
              <div>
                <h4>Sub Total</h4>

                {cart.map((item) => (
                  <p>₹{item["productlist.price"]}.00</p>
                ))}
                <hr></hr>
                <h5>₹ {price}.00</h5>
              </div>

              {/* </div> */}
            </div>
            <hr></hr>
          </div>

          <div className="row">
            <div className=" col-lg-6 col-12 mt-3 px-5">
              <h4> Billing Address</h4>
              <p>
                No.{billing_address.flat},
                {billing_address.address}
                <br></br>
                {billing_address.landMark}
                <br></br>
                {billing_address.district} -{" "}
                {billing_address.pincode}
                <br></br>
                {billing_address.state}
                <br></br>
              </p>
            </div>
            <div className=" col-lg-6 col-12 mt-3 px-5">
              <h4> Shipping Address</h4>
              <p>
                No.{shipping_address.flat},
                {shipping_address.address}
                <br></br>
                {shipping_address.landMark}
                <br></br>
                {shipping_address.district} -{" "}
                {shipping_address.pincode}
                <br></br>
                {shipping_address.state}
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-1"></div>
        <div className="col-lg-5 col-12 h-25 shadow position-sticky top-0 mt-1 pt-3 bgcolor">
          <h4 className="text-center" style={{ color: "#2f7321" }}>
            Thank You{" "}
          </h4>
          <h6 className="text-center" style={{ color: "#2f7321" }}>
            Your order has been Placed
          </h6>

          <div>
            <ul className="ulist">
              <li>Order Number :{order_id}</li>
              <li>
                Date : {date} - {Time}
              </li>
              <li>Email : {Email}</li>
              <li>Toatal : ₹{price}.00</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Checkout;
