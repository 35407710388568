// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .mains{
    background: rgb(223,223,223);
    background: linear-gradient(90deg, rgba(223,223,223,1) 0%, rgba(223,223,223,1) 0%, rgba(218,218,218,1) 0%, rgba(223,222,222,1) 100%, rgba(225,209,215,1) 100%, rgba(250,53,120,1) 100%, rgba(249,84,100,1) 100%, rgba(237,237,237,1) 100%, rgba(221,215,23,1) 200%);
}

#copy{
    background: rgb(176,176,176);
    background: linear-gradient(90deg, rgba(176,176,176,1) 0%, rgba(177,177,172,1) 100%, rgba(178,178,168,1) 100%, rgba(221,215,23,1) 200%);
}
.text{
   font-weight: bold;
   font-size: small;
} */

/* .main{
    width: 100%;
    
}
.icons{
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: white;
    margin: 20px;
    text-align: center;
    font-size: 29px;
    line-height: 50px ;
    font-family: sans-serif;
    overflow: hidden;
    box-shadow: 5px 10px 20px rgba(150, 150, 150, .3);
    transition: all .3s ease-out;
}
.icons:hover{
    width: 200px;
    cursor: pointer;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: white;
   
}
.icons .fa-whatsapp{
    color: #119607;
}
.icons:hover i{
    color: #fff;
   
}
.whatsapp:hover{
    background-color: #119607;
   
}
.phone:hover{
    background-color: #FEC900;
} */

#copy{
    background: rgb(176,176,176);
}
@media screen and (min-device-width: 320px) and (max-device-width: 800px){
    #foot{
        display: none !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/component/Footer/Footer.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;GAYG;;AAEH;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;GAwCG;;AAEH;IACI,4BAA4B;AAChC;AACA;IACI;QACI,wBAAwB;IAC5B;AACJ","sourcesContent":["/* .mains{\r\n    background: rgb(223,223,223);\r\n    background: linear-gradient(90deg, rgba(223,223,223,1) 0%, rgba(223,223,223,1) 0%, rgba(218,218,218,1) 0%, rgba(223,222,222,1) 100%, rgba(225,209,215,1) 100%, rgba(250,53,120,1) 100%, rgba(249,84,100,1) 100%, rgba(237,237,237,1) 100%, rgba(221,215,23,1) 200%);\r\n}\r\n\r\n#copy{\r\n    background: rgb(176,176,176);\r\n    background: linear-gradient(90deg, rgba(176,176,176,1) 0%, rgba(177,177,172,1) 100%, rgba(178,178,168,1) 100%, rgba(221,215,23,1) 200%);\r\n}\r\n.text{\r\n   font-weight: bold;\r\n   font-size: small;\r\n} */\r\n\r\n/* .main{\r\n    width: 100%;\r\n    \r\n}\r\n.icons{\r\n    width: 50px;\r\n    height: 50px;\r\n    border-radius: 50px;\r\n    background-color: white;\r\n    margin: 20px;\r\n    text-align: center;\r\n    font-size: 29px;\r\n    line-height: 50px ;\r\n    font-family: sans-serif;\r\n    overflow: hidden;\r\n    box-shadow: 5px 10px 20px rgba(150, 150, 150, .3);\r\n    transition: all .3s ease-out;\r\n}\r\n.icons:hover{\r\n    width: 200px;\r\n    cursor: pointer;\r\n    display: flex;\r\n    justify-content: space-evenly;\r\n    align-items: center;\r\n    color: white;\r\n   \r\n}\r\n.icons .fa-whatsapp{\r\n    color: #119607;\r\n}\r\n.icons:hover i{\r\n    color: #fff;\r\n   \r\n}\r\n.whatsapp:hover{\r\n    background-color: #119607;\r\n   \r\n}\r\n.phone:hover{\r\n    background-color: #FEC900;\r\n} */\r\n\r\n#copy{\r\n    background: rgb(176,176,176);\r\n}\r\n@media screen and (min-device-width: 320px) and (max-device-width: 800px){\r\n    #foot{\r\n        display: none !important;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
