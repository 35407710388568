import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import {
  Navigate,
  json,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import { base_url } from "../../utility/Url";

import Typography from "@mui/material/Typography";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItemIcon from "@mui/material/ListItemIcon";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import Pagination from "react-js-pagination"; // Import Pagination component
import Logo from "../../assests/images/LogoDM.png"; // Import Pagination component
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
const drawerWidth = 240;
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

function Transaction(props) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  useEffect(() => {
    const token = localStorage.getItem("auth_token");
    if (!token) {
      return navigate("/");
    }
  }, []);
  const container =
    window !== undefined ? () => window().document.body : undefined;
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#305931",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    async function fetchData() {
      try {
        const token = localStorage.getItem("auth_token");
        if (!token) {
          return navigate("/");
        }
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/admin/payment`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        console.log(response.data, "data");
        if (response.status === 200) {
          const jsonData = response.data;
          const pendingData = jsonData.filter(
            (product) => product.payment_status === "pending"
          );
          if (pendingData.length > 0) {
            setData([
              ...pendingData,
              ...jsonData.filter(
                (product) => product.payment_status !== "pending"
              ),
            ]);
            setFilteredData(pendingData);
          } else {
            setData(jsonData);
            setFilteredData(jsonData);
          }
        }
      } catch (err) {
        console.error("Error:", err);
      }
    }

    fetchData();
  }, []);

  const theme = useTheme();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const myprofile = () => {
    navigate("/myprofile");
  };

  const logout = () => {
    localStorage.clear();
    navigate("/");
  };

  const [currentpage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;
  const firstIndex = (currentpage - 1) * recordsPerPage;
  const lastIndex = currentpage * recordsPerPage;

  const handleFilter = (event) => {
    const query = event.target.value.toLowerCase();
    const filtered = data.filter((item) => {
      return (
        item.user_address?.toLowerCase()?.includes(query) ||
        item.name?.toLowerCasapproveUsere()?.includes(query) ||
        item.amount?.toLowerCase()?.includes(query) ||
        item.payment_method?.toLowerCase()?.includes(query) ||
        item.transaction_id?.toLowerCase()?.includes(query) ||
        item.payment_status?.toLowerCase()?.includes(query)
      );
    });
    setFilteredData(filtered);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const approveUser = async (
    id,
    user_id,
    ref_id,
    membership_package,
    upgrade_package
  ) => {
    try {
      if (membership_package === "null") {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/membership/membershipadd`,
          {
            id: user_id,
            ref_id: ref_id,
            transaction_id: id,
          }
        );
        window.location.reload();
      } else {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/membership/membershipupgrade`,
          {
            id: user_id,
            ref_id: ref_id,
            membership_package: membership_package,
            upgrade_package: upgrade_package,
            transaction_id: id,
          }
        );
        console.log(response, "response");
        window.location.reload();
      }
    } catch (error) {
      console.error("Error approving the status", error);
    }
  };

  const rejectUser = async (id, user_id) => {
    try {
      axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/membership/rejectuser`, {
          id: user_id,
          transaction_id: id,
        })
        .then((response) => {
          window.location.reload();
        })
        .catch((error) => {
          console.error("error", error);
        });
    } catch (err) {
      return console.log(err);
    }
  };

  const handleEdit = (
    id,
    user_id,
    ref_id,
    membership_package,
    upgrade_package
  ) => {
    Swal.fire({
      title: "Edit User",
      text: "Do you want to approve or reject this user?",
      showCancelButton: true,
      confirmButtonText: "Approve",
      cancelButtonText: "Reject",
    }).then((result) => {
      if (result.isConfirmed) {
        approveUser(id, user_id, ref_id, membership_package, upgrade_package);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        rejectUser(id, user_id);
      }
    });
  };

  const [modalimage, setModalimage] = useState(null);
  const handleImage = (payment_screenshot) => {
    setModalimage(payment_screenshot);
  };

  const handleFilters = (event) => {
    const query = event.target.value.toLowerCase();
    const filtered = data.filter((item) => {
      return item.payment_status.toLowerCase().includes(query);
    });
    setFilteredData(filtered);
  };
  const dynamic = "AdminPanel";

  const handeldelete = async (id) => {
    console.log(id, "adasd");
    try {
      const token = localStorage.getItem("auth_token");
      if (!token) {
        return navigate("/");
      }

      const shouldDelete = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (shouldDelete.isConfirmed) {
        const response = await axios.delete(
          `${process.env.REACT_APP_API_BASE_URL}/membership/deleteTransation/`, // Include id in the URL
          {
            headers: {
              Authorization: token,
            },
            data: { id },
          }
        );

        if (response.status === 200) {
          console.log(response);
          Swal.fire("Deleted!", "The transaction has been deleted.", "success");
          window.location.reload();
        }
      }
    } catch (error) {
      console.error("Error deleting transaction:", error);
      Swal.fire("Error!", "Failed to delete the transaction.", "error");
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Helmet>
        <title> DCrown Mart | {dynamic}</title>
      </Helmet>
      <CssBaseline />
      <AppBar
        position="fixed"
        className="dashboard-bar"
        open={open}
        style={{ backgroundColor: "#FFD700" }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            DCrownMart
          </Typography>

          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
            className="logout-icon"
            style={{ position: "absolute", right: "0" }}
          >
            <AccountCircle sx={{ width: 40, height: 40 }} />
          </IconButton>

          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={logout}>Log Out</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <img src={Logo} alt="Logo" width="75%" />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem>
            <ListItemButton
              onClick={() => {
                navigate("/admin/Dashboard");
              }}
            >
              <ListItemIcon>
                <h5 className="">
                  <span className="p-1">
                    <i class="fa-solid fa-box"></i> Dashboard
                  </span>
                </h5>
              </ListItemIcon>
              <ListItemText />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              onClick={() => {
                navigate("/admin/adminpanel");
              }}
            >
              <ListItemIcon>
                <h5 className="">
                  <span className="p-1">
                    <i class="fa-solid fa-box"></i> Users
                  </span>
                </h5>
              </ListItemIcon>
              <ListItemText />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              onClick={() => {
                navigate("/admin/level");
              }}
            >
              <ListItemIcon>
                <h5 className="">
                  <span className="p-1 ">
                    <i class="fa-solid fa-chart-simple"></i> Level Amount
                  </span>
                </h5>
              </ListItemIcon>
              <ListItemText />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              onClick={() => {
                navigate("/admin/adminmembership");
              }}
            >
              <ListItemIcon>
                <h5 className="">
                  <span className="p-1 ">
                    <i class="fa-solid fa-user-plus"></i> Membership
                  </span>
                </h5>
              </ListItemIcon>
              <ListItemText />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              className="bg-warning"
              onClick={() => {
                navigate("/admin/transaction");
              }}
            >
              <ListItemIcon>
                <h5 className="">
                  <span className="p-1 text-white">
                    <i class="fa-regular fa-credit-card"></i> Transaction
                  </span>
                </h5>
              </ListItemIcon>
              <ListItemText />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              onClick={() => {
                navigate("/admin/Product");
              }}
            >
              <ListItemIcon>
                <h5 className="">
                  <span className="p-1">
                    <i class="fa-solid fa-cart-shopping"></i> Product
                  </span>
                </h5>
              </ListItemIcon>
              <ListItemText />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              onClick={() => {
                navigate("/admin/tree");
              }}
            >
              <ListItemIcon>
                <h5 className="">
                  <span className="p-1">
                    <i class="fa-solid fa-chart-bar"></i> Tree
                  </span>
                </h5>
              </ListItemIcon>
              <ListItemText />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              onClick={() => {
                navigate("/admin/Bank_details");
              }}
            >
              <ListItemIcon>
                <h5 className="">
                  <p>
                    <i class="fa-brands fa-squarespace"></i> Account
                  </p>
                </h5>
              </ListItemIcon>
              <ListItemText />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              onClick={() => {
                navigate("/admin/Manual_entry");
              }}
            >
              <ListItemIcon>
                <h5 className="">
                  <span>
                    <i class="fa-solid fa-magnifying-glass"></i> Manual_entry
                  </span>
                </h5>
              </ListItemIcon>
              <ListItemText />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              onClick={() => {
                navigate("/admin/Orderlist");
              }}
            >
              <ListItemIcon>
                <h5 className="">
                  <span>
                    <i class="fa-solid fa-cart-shopping"></i> Order List
                  </span>
                </h5>
              </ListItemIcon>
              <ListItemText />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              onClick={() => {
                navigate("/admin/withdrawlTransaction");
              }}
            >
              <ListItemIcon>
                <h5 className="">
                  <span>
                    <i class="fa-solid fa-money-bill-transfer"></i>Withdrawl{" "}
                    <br></br>
                    <p className="text-center" style={{ fontSize: "16px" }}>
                      Transaction
                    </p>
                  </span>
                </h5>
              </ListItemIcon>
              <ListItemText />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              onClick={() => {
                navigate("/admin/withdrawl");
              }}
            >
              <ListItemIcon>
                <h5 className="">
                  <span>
                    <i class="fa-solid fa-money-bill-transfer"></i> Withdrawl
                    <br></br>
                    <p className="text-center" style={{ fontSize: "16px" }}>
                      User Bank Accounts
                    </p>
                  </span>
                </h5>
              </ListItemIcon>
              <ListItemText />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <h2 className="mb-4 mt-3">Upgrade Membership Transaction</h2>
        <TableContainer component={Paper} className="p-3">
          <input
            type="text"
            className="float-end mb-3 searchbar"
            onChange={handleFilter}
            placeholder="Search"
          />
          <select
            class="form-select-main w-25 mt-1"
            aria-label="Default select example"
            onChange={handleFilters}
          >
            <option value="pending" defaultChecked>
              Pending
            </option>
            <option value="rejected">Rejected</option>
            <option value="approved">Approve</option>
          </select>
          <br></br>
          <Table
            sx={{ minWidth: 700, marginTop: 6 }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">user Id</StyledTableCell>
                <StyledTableCell align="center">user Address</StyledTableCell>

                <StyledTableCell align="center">Name</StyledTableCell>
                <StyledTableCell align="center">Referal Id</StyledTableCell>
                <StyledTableCell align="center">
                  upgrade_package
                </StyledTableCell>

                <StyledTableCell align="center">Amount</StyledTableCell>
                <StyledTableCell align="center">Payment Method</StyledTableCell>
                <StyledTableCell align="center">Transaction Id</StyledTableCell>
                <StyledTableCell align="center">Status</StyledTableCell>
                <StyledTableCell align="center">
                  Payment Screenshot
                </StyledTableCell>
                <StyledTableCell align="center">Action</StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {filteredData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    No data available in this table
                  </TableCell>
                </TableRow>
              ) : (
                filteredData.slice(firstIndex, lastIndex).map((items) => (
                  <StyledTableRow key={items.id}>
                    <StyledTableCell component="th" scope="row" align="center">
                      <p>{items.id}</p>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" align="center">
                      <p>{items.user_address}</p>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <p>{items.name}</p>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <p>{items.ref_id}</p>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <p>{items.upgrade_package}</p>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <p>{items.amount}</p>
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      <p>{items.payment_method}</p>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <p>{items.transaction_id}</p>
                    </StyledTableCell>
                    <StyledTableCell align="center" use>
                      <b
                        className=""
                        style={{
                          color:
                            items.payment_status === "approved"
                              ? "green"
                              : "red",
                        }}
                      >
                        {items.payment_status === "approved"
                          ? "Approved"
                          : "Pending "}
                      </b>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <div className="image-tran">
                        <img
                          src={
                            `${base_url}/uploads/` + items.payment_screenshot
                          }
                          alt={items.name}
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => handleImage(items.payment_screenshot)}
                        />
                      </div>
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      <button
                        className="btn btn-primary"
                        onClick={() =>
                          handleEdit(
                            items.id,
                            items.user_id,
                            items.ref_id,
                            items.membership_package,
                            items.upgrade_package
                          )
                        }
                      >
                        <i className="fas fa-pencil-alt me-2"></i>Edit
                      </button>
                    </StyledTableCell>
                    {/* <StyledTableCell>
                      <button
                        className="btn btn-danger btn-xs ms-3"
                        data-title="Delete"
                        data-toggle="modal"
                        data-target="#delete"
                        onClick={() => handeldelete(items.id)}
                      >
                        <i className="fas fa-trash"></i>
                      </button>
                    </StyledTableCell> */}
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>

          <div className="pagination-container">
            <Pagination
              activePage={currentpage}
              itemsCountPerPage={recordsPerPage}
              totalItemsCount={filteredData.length}
              pageRangeDisplayed={2}
              className="mt-3"
              onChange={handlePageChange}
              itemclassName="page-item"
              linkclassName="page-link"
              innerclassName="pagination"
            />
          </div>
        </TableContainer>
      </Main>

      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h2></h2>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-center">
              <img
                src={`${base_url}/uploads/` + modalimage}
                width={"80%"}
                height={"80%"}
              />
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
}

export default Transaction;
