import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useState } from "react";
import Swal from "sweetalert2";
import err_img from "../../assests/images/error_img.png";
import axios from "axios";
import {base_url} from "../../utility/Url"

import { toast } from 'react-toastify';
const Searchproducts = ({ products }) => {
    const [newProduct, setProduct] = useState([]);
    const [userToken,setUserToken]=useState()
    const navigate = useNavigate()
    useEffect(()=>{
        const Token=localStorage.getItem("token")
        setUserToken(Token)
    })
    const Addtocart = async (item, e) => {
        e.preventDefault();
        const productId  = item.id
        if (!userToken) {
            Swal.fire({
              title: "Please Login",
              imageUrl: err_img,
      confirmButtonColor: "#ffbe0b",
              confirmButtonText: "Login",
              html: "Please login and continue your shopping",
              showCancelButton: false,
              imageWidth: 200,
              imageHeight: 200,
              customClass: {
                image: "swal2-image",
                popup: "swal2-popup",
              },
            }).then(() => {
              navigate("/account");
            });
          }
             else{       
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/addToCart/addProductToCard`,  { productId }, {
            headers: {
              Authorization: `Bearer ${userToken}`,     
            },
          });
      
          toast.success("Added to Cart", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
      
          console.log(response);
        } catch (error) {
          console.error(error);
        }
    }
      };
    return (
        <>
            {
                products.length === 0 ? (
                    <div className='notfoundmain mt-5'>

                        <div className='contain notfound'>
                        </div>
                        <div className='text-center p-3'>
                            <h2>Products Not Found</h2>
                            <div className=" col-2 mx-auto sign-up  mt-5">
                                <button className="btn " type="submit" onClick={() => navigate('/')}>Go Back Home</button>

                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='john text-center'>
                        <div className='product-details'>
                            {products.map((item) => (
                                <form key={item.id}>
                                    <div className="card">
                                        <div>
                                            <img
                                                className="product2--image"
                                                src={`${base_url}/${item.image}`}
                                                alt="product image"
                                            onClick={() => navigate(`/cartdetails/${item.id}`)}
                                            />
                                            <br />
                                            <h5 className='mt-3 fs-5'>{item.name}</h5>
                                            <p className="price">
                                                <span className='text-danger fs-5 fw-bold me-1'>₹</span>
                                                <span className='fw-bold fs-5'>{item.price}</span>
                                            </p>
                                            <div className="addtocart">
                                                <div className="product2-add">
                                                    <button
                                                        type="button"
                                                        onClick= {(e)=> {Addtocart(item,e)} }
                                                    >
                                                        Add to Cart
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            ))}
                        </div>
                    </div>
                )
            }




        </>
    )
}

export default Searchproducts