import { useEffect } from "react";
import React  from 'react'

function ShippingPolicy() {

    useEffect(() => {
        // Scroll to the top of the page when component mounts
        window.scrollTo(0, 0);
      }, []);
  return (
    <div style={{ marginTop: "150px" }}>
      <h3 className="text-center">SHIPPING POLICY</h3>
      <div className="p-5 text-justify">

      <h5>1. SHIPPING POLICY</h5>

        <p>
         <a href='www.dcrownmart.com'> www.dcrownmart.com</a>  is committed to excellence, and the full satisfaction of our customers.
<a href='www.dcrownmart.com'> www.dcrownmart.com</a>  proudly offers shipping services. Be assured we are doing everything in our
power to get your order to you as soon as possible. Please consider any holidays that might impact
delivery times. <a href='www.dcrownmart.com'> www.dcrownmart.com</a>  also offers same day dispatch.
        </p>
       
        <h5>2. SHIPPING</h5>
        <p>
        All orders for our products are processed and shipped out in 4-10 business days. Orders are not
shipped or delivered on weekends or holidays. If we are experiencing a high volume of orders,
shipments may be delayed by a few days. Please allow additional days in transit for delivery. If there
will be a significant delay in the shipment of your order, we will contact you via email.
        </p>
        <h5>3.WRONG ADDRESS DISCLAIMER</h5>
        <p>
        It is the responsibility of the customers to make sure that the shipping address entered is correct.
We do our best to speed up processing and shipping time, so there is always a small window to
correct an incorrect shipping address. Please contact us immediately if you believe you have
provided an incorrect shipping address.
        </p>
      
        <h5>4. UNDELIVERABLE ORDERS</h5>
        <p>
        Orders that are returned to us as undeliverable because of incorrect shipping information are
subject to a restocking fee to be determined by us.
        </p>
        <h5> 5. LOST/STOLEN PACKAGES</h5>
        <p><a href='www.dcrownmart.com'> www.dcrownmart.com</a>  is not responsible for lost or stolen packages. If your tracking information
states that your package was delivered to your address and you have not received it, please report to
the local authorities.</p>
        <h5> 6. RETURN REQUEST DAYS</h5>
        <p>
        <a href='www.dcrownmart.com'> www.dcrownmart.com</a>  allows you to return its item (s) within a period of 14 days. Kindly be advised
that the item (s) should be returned unopened and unused.
        </p>
        <h5>7. OUT OF STOCK ITEM PROCESS</h5>
        <p>
        <a href='www.dcrownmart.com'> www.dcrownmart.com</a>  has the following options in the event there are items which are out of stock
<a href='www.dcrownmart.com'> www.dcrownmart.com</a>  Wait for all items to be in stock before dispatching.
        </p>
        <h5>8. IMPORT DUTY AND TAXES</h5>
        <p>
        When dealing with <a href='www.dcrownmart.com'> www.dcrownmart.com</a>  you have the following options when it comes to taxes as
well as import duties:You will be required to settle the requisite fees when the items are arriving in
the destination country
        </p>
        <h5>9. ACCEPTANCE</h5>
        <p>
        By accessing our site and placing an order you have willingly accepted the terms of this Shipping
Policy.
        </p>
        <h5>10. CONTACT INFORMATION</h5>
        <p>
        In the event you have any questions or comments please reach us via the following contacts:
Email - <a href="mailto:dcrownmart@gmail.com">dcrownmart@gmail.com</a>
        </p>
       
      </div>
    </div>
  )
}

export default ShippingPolicy