import React, { useEffect, useState } from 'react'
import { BsCloudArrowUp } from 'react-icons/bs';
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Typography from "@mui/material/Typography";
import Logo from "../../assests/images/LogoDM.png";// Import Pagination component
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import MuiAppBar from "@mui/material/AppBar";
import { ImageUploader } from '../../utility/ImageUploader'
import { Navigate, json, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { styled, useTheme } from "@mui/material/styles";
import { toast } from "react-toastify";


import Toolbar from "@mui/material/Toolbar";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItemIcon from "@mui/material/ListItemIcon";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
function NewProductAdd() {
    const dynamic = "AdminPanel";
    const navigate = useNavigate();
   useEffect(()=>{
        const token = localStorage.getItem('auth_token')
        if (!token) {
            return navigate('/')
        }
    },[])
    const [open, setOpen] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [data, setData] = useState({
        name: "",
        categories: "",
        price: "",
        image: "",
        weight: "",
        imageData: ""
    })
    const [newProduct, SetProduct] = useState([])
    const uploadImage = async (e) => {
        const file = e.target.files[0];
        try {
            const imageData = await ImageUploader(file);
            const formData = new FormData();
            formData.append('image', file);
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/product/uploadImage`, formData);
            setData((prev) => {
                return {
                    ...prev,
                    image: response.data.imagePath, // Update with the actual response key for the image path
                    imageData: imageData, // Add this line to store the data URL in your state if needed
                };
            });
        } catch (error) {
            console.error('Error uploading image: ', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target
        setData((pre) => {
            return {
                ...pre, [name]: value
            }
        })
    }

    React.useEffect(() => {
        async function fetchData() {
            const token = localStorage.getItem('auth_token')
            if (!token) {

                toast.error("Authentication Failed", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000,
                });

                navigate('/')
            }
        }

        fetchData();
    }, []);

    const drawerWidth = 240;
    const DrawerHeader = styled("div")(({ theme }) => ({
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: "flex-end",
    }));

    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== "open",
    })(({ theme, open }) => ({
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: `${drawerWidth}px`,
            transition: theme.transitions.create(["margin", "width"], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }));
    const theme = useTheme();
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const myprofile = () => {
        navigate("/myprofile");
    };

    const logout = () => {
        localStorage.clear();
        navigate("/");
    };
    const handleSubmit = (e) => {
        e.preventDefault()
        try {
            const token = localStorage.getItem('auth_token')
            if (!token) {
                toast.error("Authentication Failed", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000,
                });

                navigate('/')
            }

            axios.post(`${process.env.REACT_APP_API_BASE_URL}/product/newProductAdd`, data,
                {
                    headers: {
                        Authorization: token,
                    }
                })
                .then(response => {
                    toast.success("Product Added Successfully", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 1000,
                    });

                    setData(() => {
                        return {
                            name: "",
                            categories: "",
                            price: "",
                            image: "",
                            weight: ""
                        }

                    })
                })
                .catch(error => {
                    console.error('Error making POST request: ', error);
                });
        }
        catch (err) {
            console.log(err)
        }
    }


    const [value, setValue] = useState(false)
    const [data2, setData2] = useState('')
    const uploadImage2 = async (e) => {
        const file = e.target.files[0];
        try {
            const imageData = await ImageUploader(file);
            const formData = new FormData();
            formData.append('image2', file);
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/product/uploadImage2/`, formData);
            setData2((prev) => {
                return {
                    ...prev,
                    image: response.data.imagePath, // Update with the actual response key for the image path
                    imageData: imageData, // Add this line to store the data URL in your state if needed
                };
            });
        } catch (error) {
            console.error('Error uploading image: ', error);
        }
    };
    const handleChange2 = (e) => {
        const { name, value } = e.target
        setData2((pre) => {
            return {
                ...pre, [name]: value
            }
        })
    }
    const handleButtonClick = () => {
        setValue(!value);
    }

    const handleSubmit2 = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/product/newProductAdd2`, data2);
            if (response.status === 200) {
                console.log(response.data)
            }
        } catch (error) {
            console.error(error);
        }

    }
    return (
        <Box sx={{ display: "flex" }}>
            <Helmet>
                <title> DCrown Mart | {dynamic}</title>
            </Helmet>
            <CssBaseline />
            <AppBar
                position="fixed"
                className="dashboard-bar"
                open={open}
                style={{ backgroundColor: "#FFD700" }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: "none" }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        DCrownMart
                    </Typography>



                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit"
                        className="logout-icon"
                        style={{ position: "absolute", right: "0" }}
                    >
                        <AccountCircle sx={{ width: 40, height: 40 }} />
                    </IconButton>

                    <Menu
                        sx={{ mt: "45px" }}
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >

                        <MenuItem onClick={logout}>Log Out</MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                        width: drawerWidth,
                        boxSizing: "border-box",
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}>
                <DrawerHeader>
                    <img src={Logo} alt="Logo" width="75%" />
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === "ltr" ? (
                            <ChevronLeftIcon />
                        ) : (
                            <ChevronRightIcon />
                        )}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    <ListItem >
                        <ListItemButton onClick={() => { navigate("/admin/Dashboard") }}>
                            <ListItemIcon>
                                <h5 className="">
                                    <span className="p-1" ><i class="fa-solid fa-box"></i> Dashboard</span>
                                </h5>
                            </ListItemIcon>
                            <ListItemText />
                        </ListItemButton>

                    </ListItem>
                    <ListItem >

                        <ListItemButton onClick={() => { navigate("/admin/adminpanel") }}>
                            <ListItemIcon>
                                <h5 className="">
                                    <span className="p-1" ><i class="fa-solid fa-box"></i> Admin</span>
                                </h5>
                            </ListItemIcon>
                            <ListItemText />
                        </ListItemButton>

                    </ListItem>
                    <ListItem >
                        <ListItemButton onClick={() => { navigate("/admin/level") }}>
                            <ListItemIcon>
                                <h5 className="">
                                    <span className="p-1 " ><i class="fa-solid fa-chart-simple"></i> Level</span>
                                </h5>
                            </ListItemIcon>
                            <ListItemText />
                        </ListItemButton>

                    </ListItem>
                    <ListItem >
                        <ListItemButton onClick={() => { navigate("/admin/adminmembership") }}>
                            <ListItemIcon>
                                <h5 className="">
                                    <span className="p-1 " ><i class="fa-solid fa-user-plus"></i> Membership</span>
                                </h5>
                            </ListItemIcon>
                            <ListItemText />
                        </ListItemButton>

                    </ListItem>
                    <ListItem >
                        <ListItemButton onClick={() => { navigate("/admin/transaction") }}>
                            <ListItemIcon>
                                <h5 className="">
                                    <span className="p-1 " ><i class="fa-regular fa-credit-card"></i> Transaction</span>
                                </h5>
                            </ListItemIcon>
                            <ListItemText />
                        </ListItemButton>

                    </ListItem>
                    <ListItem >
                        <ListItemButton className="bg-warning" onClick={() => { navigate("/admin/Product") }}>
                            <ListItemIcon>
                                <h5 className="">
                                    <span className="p-1 text-white"><i class="fa-solid fa-cart-shopping"></i> Product</span>
                                </h5>
                            </ListItemIcon>
                            <ListItemText />
                        </ListItemButton>

                    </ListItem>
                    <ListItem >
                        <ListItemButton onClick={() => { navigate("/admin/tree") }}>
                            <ListItemIcon>
                                <h5 className="">
                                    <span className="p-1" ><i class="fa-solid fa-chart-bar"></i> Tree</span>
                                </h5>
                            </ListItemIcon>
                            <ListItemText />
                        </ListItemButton>

                    </ListItem>
                    <ListItem >
                        <ListItemButton onClick={() => { navigate("/admin/Bank_details") }}>
                            <ListItemIcon>
                                <h5 className="">
                                    <p><i class="fa-brands fa-squarespace"></i> Account</p>
                                </h5>
                            </ListItemIcon>
                            <ListItemText />
                        </ListItemButton>

                    </ListItem>
                    <ListItem >
                        <ListItemButton onClick={() => { navigate("/admin/Manual_entry") }}>
                            <ListItemIcon>
                                <h5 className="">
                                    <span><i class="fa-solid fa-magnifying-glass"></i> Manual_entry</span>
                                </h5>
                            </ListItemIcon>
                            <ListItemText />
                        </ListItemButton>

                    </ListItem>
                </List>
                <Divider />

            </Drawer>
            <div className='d-flex  mt-5 pt-5 w-75 justify-content-center align-item-center '>
                {!value ? (
                    <form className='m-auto w-50 shadow-lg p-4  mb-5 bg-white rounded  ' onSubmit={handleSubmit} encType="multipart/form-data">
                        <div className='d-flex justify-content-evenly text-center product-button-add'>
                            <button className={!value ? 'active-button' : ''} onClick={handleButtonClick}><b>Add Product</b></button>
                            <button className={value ? 'active-button' : ''} onClick={handleButtonClick}><b>Discount Product</b></button>
                        </div>
                        <h2 className='text-center mt-3' style={{ color: "#198754" }}>
                            <b>Add Product</b></h2>
                        <div class="form-group  mt-4 px-5 ">
                            <input type="text" name='name' class="form-control p-3 mt-2 " value={data.name} onChange={handleChange} id="inputEmail4" placeholder="Name" required />
                            <select class="form-control p-3 mt-4" name='categories' id="exampleFormControlSelect1 " value={data.categories} onChange={handleChange} required>
                                <option value={"categories"}>Select categories</option>
                                <option value={"Tea,Coffee &Health drink"}>Tea,Coffee & Health drink</option>
                                <option value={"Masala,oil & More"}>Masala,oil & More</option>
                                <option value={"Dairy ,bread & eggs"}>Dairy ,bread & eggs</option>
                                <option value={"Fruit & vegitables"}>Fruit & vegitables</option>
                                <option value={"Cold Drinks & juices"}>Cold Drinks & juices</option>
                                <option value={"Snacks & Munchies"}>Snacks & Munchies</option>
                                <option value={"Bakery & Biscutes"}>Bakery & Biscutes</option>
                                <option value={"Atta,Rice & Dal"}>Atta,Rice & Dal</option>
                                <option value={"Cleaning & Essentials"}>Cleaning & Essentials</option>
                            </select>
                            <label className='p-3  d-flex  justify-content-center mt-4 border' for="image" >
                                <div>
                                    <b>Image Upload</b>
                                    <div className='fs-1  w-full bg-white d-flex text-dark justify-content-center align-item-center'>
                                        {data.imageData ? <img src={data.imageData} width={"25%"} /> : <span className=' '>
                                            <BsCloudArrowUp />
                                        </span>}
                                        <input type={"file"} id="image" accept='image/*' hidden onChange={uploadImage} name="image" required />
                                    </div>
                                </div>
                            </label>
                            <div>
                                <input type="number" value={data.price} name='price' onChange={handleChange} class="form-control mt-4 mb-2 p-3" placeholder="Price" required />
                            </div>

                            <div className="">
                            <div>
                                <input type="text" value={data.weight} name='weight' onChange={handleChange} class="form-control mt-4 mb-2 p-3" placeholder="weight" required />
                            </div>

                            </div>
                            <div className='text-center mt-4 '>
                                <button className='btn  w-100 p-3'
                                    type='submit ' style={{ backgroundColor: "#ffc107", color: "white", fontSize: 15 }}>Save</button>
                            </div>
                        </div>
                    </form>
                ) : (
                    <form className='m-auto w-50 shadow-lg p-4  mb-5 bg-white rounded  ' onSubmit={handleSubmit2} encType="multipart/form-data">
                        <div className='d-flex justify-content-evenly text-center product-button-add'>
                            <button className={!value ? 'active-button' : ''} onClick={handleButtonClick}><b>Add Product</b></button>
                            <button className={value ? 'active-button' : ''} onClick={handleButtonClick}><b>Discount Product</b></button>
                        </div>
                        <h2 className='text-center mt-3' style={{ color: "#198754" }}>
                            <b>Discount Product</b>
                        </h2>
                        <div class="form-group  mt-4 px-5 ">
                            <input type="text" name='name' class="form-control p-3 mt-2 " value={data2.name} onChange={handleChange2} id="inputEmail4" placeholder="Name" required />
                            <select class="form-control p-3 mt-4" name='categories' id="exampleFormControlSelect1 " value={data2.categories} onChange={handleChange2} required>
                                <option value={"categories"}>Select categories</option>
                                <option value={"Tea,Coffee &Health drink"}>Tea,Coffee & Health drink</option>
                                <option value={"Masala,oil & More"}>Masala,oil & More</option>
                                <option value={"Dairy ,bread & eggs"}>Dairy ,bread & eggs</option>
                                <option value={"Fruit & vegitables"}>Fruit & vegitables</option>
                                <option value={"Cold Drinks & juices"}>Cold Drinks & juices</option>
                                <option value={"Snacks & Munchies"}>Snacks & Munchies</option>
                                <option value={"Bakery & Biscutes"}>Bakery & Biscutes</option>
                                <option value={"Atta,Rice & Dal"}>Atta,Rice & Dal</option>
                                <option value={"Cleaning & Essentials"}>Cleaning & Essentials</option>


                            </select>
                            <label className='p-3  d-flex  justify-content-center mt-4 border' for="image" >
                                <div>
                                    <b>Image Upload</b>
                                    <div className=' fs-1  w-full bg-white d-flex text-dark justify-content-center align-item-center'>
                                        {data2.imageData ? <img src={data2.imageData} width={"25%"} /> : <span className=' '>
                                            <BsCloudArrowUp />
                                        </span>}
                                        <input type={"file"} id="image" accept='image/*' hidden onChange={uploadImage2} name="image" required />
                                    </div>
                                </div>
                            </label>
                            <div>
                                <input type="number" value={data2.price} name='price' onChange={handleChange2} class="form-control mt-4 mb-4 p-3" placeholder="Price" required />
                                <input type="number" value={data2.discount} name='discount' onChange={handleChange2} class="form-control mt-4 mb-2 p-3" placeholder=" Discount Price" required />
                            </div>

                            <div className="">
                            <div>
                                <input type="text" value={data.weight} name='weight' onChange={handleChange} class="form-control mt-4 mb-2 p-3" placeholder="weight" required />
                            </div>
                            </div>
                            <div className='text-center mt-4 '>
                                <button className='btn  w-100 p-3'
                                    type='submit ' style={{ backgroundColor: "#ffc107", color: "white", fontSize: 15 }}>Save</button>
                            </div>
                        </div>
                    </form>
                )}


                {/* <div>
                    {newProduct.map((items) => (
                        <div key={items.id}>
                            <h1>
                                name:{items.name}
                                <img src={`${base_url}/${items.image}`} />
                            </h1>
                        </div>
                    ))
                    }
                </div> */}
            </div>
        </Box>

    )
}

export default NewProductAdd