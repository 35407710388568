


import React from 'react'
import axios from "axios";
import { useState } from "react";
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Fix the import statement
import Swal from 'sweetalert2';
import err_img from "../../assests/images/error_img.png";
import { Helmet } from 'react-helmet';
import {base_url} from "../../utility/Url"

function Coffee({ products }) {
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const fruit = location.state?.fruit;
    const navigate = useNavigate();
    const [newProduct, setProduct] = useState([]);
    const [userToken, setUserToken] = useState()
    useEffect(() => {
        setLoading(true)
        async function fetchData() {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/product/getProduct`);
                setProduct(response.data);
                setLoading(false)
                const Masala = response.data.filter(product => product.categories === fruit);
                setProduct(Masala);
            } catch (error) {
                setLoading(false)
                console.log(error, "axios");
            } 
        }
        fetchData();
    }, []);
 
    useEffect(() => {
        const Token = localStorage.getItem("token")
        setUserToken(Token)
    })
    const Addtocart = async (item, e) => {
        e.preventDefault();
        if (!userToken) {
            Swal.fire({
              title: "Please Login",
              imageUrl: err_img,
      confirmButtonColor: "#ffbe0b",
              confirmButtonText: "Login",
              html: "Please login and continue your shopping",
              showCancelButton: false,
              imageWidth: 200,
              imageHeight: 200,
              customClass: {
                image: "swal2-image",
                popup: "swal2-popup",
              },
            }).then(() => {
              navigate("/account");
            });
          }
          else{

        const productId = item.id

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/addToCart/addProductToCard`, { productId }, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });
            toast.success("Added to Cart", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
              })
            console.log(response);
        } catch (error) {
            console.error(error);
        }
    }
    };
    const [error, setError] = useState("");
    if (products && products.length === 0) {
        setError("No products available");
        console.log(error)
    }

    const dynamic = "Productdetails";

    if (loading) {
        return (
            <div class="loading">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        )
    }
    return (
        <>
            <Helmet>
                <title> DCrown Mart | {dynamic}</title>
            </Helmet>
            <p>{error}</p>
            <div className='john text-center'>
                <h3 className='mt-5'>{error}</h3>
                <div className='product-details'>
                    {newProduct.map((item) => (
                        <div className="card">
                            <div key={item.id}>
                                <img
                                    className="product2--image"
                                    src={`${base_url}/${item.image}`}
                                    alt="product image" // Include the item's ID in the UR
                                    onClick={() => navigate(`/cartdetails/${item.id}`)}
                                />
                                <br />
                                <h5 className='mt-3 fs-5 '>{item.name}</h5>
                                <p className="price"><span className='text-danger fs-5  fw-bold me-1'>₹</span><span className='fw-bold fs-5'>{item.price}</span></p>
                                <div className="addtocart">
                                    <div className="product2-add" >
                                        <button type="submit" className="" onClick={(e) => { Addtocart(item, e) }}> Add to Cart</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}
export default Coffee;



