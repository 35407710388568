  import React from 'react'
  import { useFormik } from 'formik'
  import * as Yup from 'yup'
  import { useState } from 'react';
  import axios from "axios";
  import address from "../assests/images/address-alter.jpg";
  import { useNavigate } from "react-router-dom";
  import { Box, Container, Grid, TextField, helperText } from "@mui/material";

  function Anotheraddress() {

  const Token = localStorage.getItem("token")

    const config = {
      headers: {
        Authorization: `Bearer ${Token}`
      }
    }
    const { values, handleChange, handleBlur, handleSubmit, touched, errors } =
      useFormik({
        initialValues: {
          flat: "",
          address: "",
          district: "",
          pincode: "",
          landMark:"",
          state: "",
        },
        validationSchema: Yup.object().shape({
          flat: Yup.string()
            .required("Flat number is required")
            .matches(
              /^[A-Za-z0-9\s]*$/,
              "Invalid characters in the house/flat number"
            )
            .min(1, "House/Flat number must not be empty")
            .max(50, "House/Flat number must be at most 50 characters"),
          address: Yup.string()
            .required("Address is required")
            .max(100, "Address must be at most 100 characters"),
            landMark: Yup.string()
            .required("landMark is required")
            .max(100, "landMark must be at most 100 characters"),

          pincode: Yup.string()
            .required("PIN code is required")
            .matches(/^\d{6}$/, "PIN code must be exactly 6 digits"),
          district: Yup.string().required("District is Required"),
          state: Yup.string().required("State is Required"),
        }),
        onSubmit: (value) => {
          axios
            .post(`${process.env.REACT_APP_API_BASE_URL}/adress/add`, value,config)
            .then((response) => {
              console.log(response,"response")
              navigate("/address");
            })
            .catch((error) => {
              console.error(error);
            });
        },
      });
    const handleStyle = {
      backgroundColor: "#ffbe0b",
      color: "white",
      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
      border: "none",
      width: "40%",
      padding: "10px 10px",
      fontWeight: "bold",
    };

    const navigate = useNavigate();
    const options = ["Cuddalore", "Pondicherry", "Madurai", "Salem"];
    return (
      <Box sx={{ marginTop: "200px", margin: "auto" }}>
        <Grid container spacing={2} sx={{ marginTop: "90px" }}>
          <Grid item xs={1}></Grid>
          <Grid item lg={5} sm={6} sx={{textAlign:'center'}}>
            <img
              src={address}
              alt="address.img"
              style={{ width: "80%", height: "90%" }}

            />
          </Grid>

          <Grid item lg={4} sm={6}>
            <form onSubmit={handleSubmit}>
              <TextField
                id="outlined-basic"
                label="Flat.no"
                variant="outlined"
                fullWidth
                name="flat"
                value={values.flat}
                sx={{ margin: "10px 0px" }}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.flat && Boolean(errors.flat)}
                helperText={touched.flat && errors.flat ? errors.flat : null}
              />
              <TextField
                id="outlined-basic"
                label="Street / Area"
                variant="outlined"
                fullWidth
                name="address"
                value={values.address}
                onChange={handleChange}
                onBlur={handleBlur}
                sx={{ margin: "10px 0px" }}
                error={touched.address && Boolean(errors.address)}
                helperText={
                  touched.address && errors.address ? errors.address : null
                }
              />
              <TextField
                id="outlined-basic"
                label="District"
                variant="outlined"
                fullWidth
                sx={{ margin: "10px 0px" }}
                name="district"
                value={values.district}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.district && Boolean(errors.district)}
                helperText={
                  touched.district && errors.district ? errors.district : null
                }
              />
              <TextField
                id="outlined-basic"
                label="State"
                variant="outlined"
                fullWidth
                sx={{ margin: "10px 0px" }}
                name="state"
                value={values.state}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.state && Boolean(errors.state)}
                helperText={touched.state && errors.state ? errors.state : null}
              />
              <TextField
                id="outlined-basic"
                label="LandMark"
                variant="outlined"
                fullWidth
                sx={{ margin: "10px 0px" }}
                name="landMark"
                value={values.landMark}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.landMark && Boolean(errors.landMark)}
                helperText={
                  touched.landMark && errors.landMark ? errors.landMark : null
                }
              />
              <TextField
                id="outlined-basic"
                label="PIN Code"
                variant="outlined"
                fullWidth
                sx={{ margin: "10px 0px" }}
                name="pincode"
                value={values.pincode}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.pincode && Boolean(errors.pincode)}
                helperText={
                  touched.pincode && errors.pincode ? errors.pincode : null
                }
              />
              <Box sx={{ textAlign: "center", marginTop: "20px" }}>
                <button type="submit" style={handleStyle}>
                  Save
                </button>
              </Box>
            </form>
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
      </Box>
    );
  }

  export default Anotheraddress;
