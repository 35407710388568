import React from "react";
import Shopping from "../assests/images/shopping.gif";
import { Helmet } from "react-helmet";
import { FaDotCircle } from "react-icons/fa";

function Order() {
  const dynamic = "Orders";
  const handleStyle={
    
     fontWeight:'bold',
    }
    const handleBox={
      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"
    }
  
  return (
    <div>
      <Helmet>
        <title> DCrown Mart | {dynamic}</title>
      </Helmet>
      <div className="container-fluid ">
        <div className="order-page">
          <img src={Shopping} width="100%" />
        </div>
        <div className="d-flex justify-content-center ">
          <h4 className="fs-2 fw-bold" style={{ color: "#545557" }}>
            {" "}
            Order not found !!!
          </h4>
        </div>

        {/* <div className="container" style={handleBox}>
          <div className="row">
            <div className="col-lg-2">

            <img src={Shopping} width="100%" />
            </div>
            <div className="col-lg-3 mt-5 ">
               <p className="">boAt Airdopes 161 with 40 Hours Playback...</p>
            </div>
            <div className="col-lg-3 mt-5">
              <p className="text-center">₹800</p>
            </div>
            <div className="col-lg-4 mt-5">
               <div className="delivery-status" style={handleStyle}>
               <FaDotCircle color="green"/> Delivered on Oct 14
               </div>
               <div className="mt-1 fs-10">
               Your item has been delivered
               </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Order;
