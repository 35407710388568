import React, { useEffect, useState } from 'react'
import { BsCloudArrowUp } from 'react-icons/bs';
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { ImageUploader } from '../../utility/ImageUploader'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Navigate, json, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {base_url} from "../../utility/Url"

import { Helmet } from 'react-helmet';
function Productedit() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState('')
    const handleChange = (e) => {
        const { name, value } = e.target
        setData((pre) => {
            return {
                ...pre, [name]: value
            }
        })
    }
    useEffect(() => {
        const token = localStorage.getItem('auth_token')
        if (!token) {
            return navigate('/')
        }
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/product/productview/${id}`)
            .then((response) => {
                const datas = response.data[0];
                console.log(datas,"dtaa")
                setData(datas);
            }).catch((err) => {
                console.log(err)
            })
    }, [])
    const uploadImage = async (e) => {
        const file = e.target.files[0];
        try {
            const imageData = await ImageUploader(file);
            const formData = new FormData();
            formData.append('newimage', file);
            const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/product/productputimage/`, formData);
            setData((prev) => {
                return {
                    ...prev,
                    image: response.data.imagePath,
                    imageData: imageData,
                };
            });
        } catch (error) {
            console.error('Error uploading image: ', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/product/productput/${id}`, data);
            if (response.status === 200) {
                toast.success("updated successful ", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 500,
                  });
                navigate("/admin/Product")
            }
        } catch (error) {
            console.error(error);
        }
    };
    const dynamic = "AdminPanel";
    return (
        <>
            <Helmet>
                <title> DCrown Mart | {dynamic}</title>
            </Helmet>
            <div className='d-flex  mt-5 pt-5 w-100 justify-content-center align-item-center '>
                <form className='m-auto w-50 shadow-lg p-4  mb-5 bg-white rounded  ' onSubmit={handleSubmit} encType="multipart/form-data">
                    <h2 className='text-center mt-3' style={{ color: "#198754" }}>
                        <b>Edit Product</b>
                    </h2>
                    <div className="form-group  mt-4 px-5 ">
                        <input type="text" name='name' className="form-control p-3 mt-2 " value={data.name} onChange={handleChange} id="inputEmail4" placeholder="Name" />
                        <select className="form-control p-3 mt-4" name='categories' id="exampleFormControlSelect1 " value={data.categories} onChange={handleChange}>
                            <option value={"categories"}>Select Catagories</option>
                            <option value={"Tea,Coffee &Health drink"}>Tea,Coffee & Health drink</option>
                            <option value={"Masala,oil & More"}>Masala,oil & More</option>
                            <option value={"Dairy ,bread & eggs"}>Dairy ,bread & eggs</option>
                            <option value={"Fruit & vegitables"}>Fruit & vegitables</option>
                            <option value={"Cold Drinks & juices"}>Cold Drinks & juices</option>
                            <option value={"Snacks & Munchies"}>Snacks & Munchies</option>
                            <option value={"Bakery & Biscutes"}>Bakery & Biscutes</option>
                            <option value={"Atta,Rice & Dal"}>Atta,Rice & Dal</option>
                                <option value={"Cleaning & Essentials"}>Cleaning & Essentials</option>
                        </select>
                        <label className='p-3  d-flex  justify-content-center mt-4 border' for="image" >
                            <div>
                                <b>Image Upload</b>
                                <div className=' fs-1  w-full bg-white d-flex text-dark justify-content-center align-item-center'>
                                    {data.image ? (

                                        <img src={`${base_url}/${data.image}`} width={"25%"} />

                                    ) : (
                                        <span className=' '>
                                            <BsCloudArrowUp />
                                        </span>
                                    )}
                                    <input type={"file"} id="image" accept='image/*' hidden onChange={uploadImage} name="image" />

                                </div>
                            </div>
                        </label>
                        <div>
                            <input type="number" value={data.price} name='price' onChange={handleChange} className="form-control mt-4 mb-2 p-3" placeholder="Price" />
                        </div>
                        <div className="">
                        <div>
                            <input type="text" value={data.weight} name='weight' onChange={handleChange} className="form-control mt-4 mb-2 p-3" placeholder="weight" />
                        </div>
                        </div>
                        <div className='text-center mt-4 '>
                            <button className='btn  w-100 p-3'
                                type='submit ' style={{ backgroundColor: "#ffc107", color: "white", fontSize: 15 }}>Save</button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default Productedit