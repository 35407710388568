import React from 'react';
import { Route, Routes } from 'react-router-dom'

import LoginForm from '../pages/LoginForm';
import Signup from '../pages/Signup';
import SignupWithOutShareLink from '../pages/Signup_WOSL';


import Enternewpassword from '../pages/Enternewpassword';
import Enterotp from '../pages/Enterotp';
import Registered_email from '../pages/Registered_email';

function AccountRouter() {
    return (
        <>
        <Routes >
            <Route exact path='/' element={<LoginForm />} />
            <Route exact path='/signup' element={<SignupWithOutShareLink />} />
            <Route exact path='/signup/:id' element={<Signup />} />

            <Route exact path='/enternew/:token' element={<Enternewpassword />} />
            <Route exact path='/enterotp' element={<Enterotp />} />
            <Route exact path='/register' element={<Registered_email />} />
         
            </Routes>
        </>
    )
}

export default AccountRouter