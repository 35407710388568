import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import * as Yup from "yup";
import {
  Grid,
  Paper,
  Typography,
  TextField,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import signup from "../assests/images/signup.jpg";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

function Signup() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);



  
  const {
    setFieldError,
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      
      cpassword: "",
      contact: "",
      termsAndConditions: false,
      country: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .matches(/^[a-zA-Z\s]*$/, "Number is not allowed")
        .required("Name is required"),


      email: Yup.string()
        .matches(
          /^[a-zA-Z0-9][a-zA-Z0-9]*@[a-zA-Z0-9]+\.[a-zA-Z]{2,}$/,
          "Invalid email format"
        )

        .required("Email is required"),
      country: Yup.string().required("Country code is required"),
      contact: Yup.string()
        .matches(/^\d{10}$/, "Must be exactly 10 digits")
        .required("contact number is required"),
      password: Yup.string().required("Password is required"),

      cpassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),

      // country: Yup.string().required('Country is required'),
      termsAndConditions: Yup.boolean().oneOf(
        [true],
        "You agree to our Terms and Policy"
      ),
    }),

    onSubmit: async (values) => {
      
  

      try {


        
        setLoading(true);

      
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/users/register`,
          values
        );
        if (response.status == 201) {
          //Login api
          const loginresponse = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/users/login`,
            values
          );
          if (loginresponse.status == 200) {
            setLoading(false);
            const token = loginresponse.data.data.token;
            const user_detail = loginresponse.data.data;
            localStorage.setItem("token", token);
            localStorage.setItem("user_detail", JSON.stringify(user_detail));
            toast.success("Login Successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 600,
            });
            navigate("/", { state: { userDetails: user_detail } });
          }
        }
      
      } catch (error) {
        if (error.response) {
          setLoading(false);
          if (error.response.status === 402) {
            setFieldError("email", "Email already exists");
            
            toast.error("Email already exists", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 500,
            });
          }
           else if (error.response.status === 405) {
            setFieldError("contact", "Mobile Number already exists");
            
            toast.error("Mobile Number already exists", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 500,
            });
          } else {
            console.error("Error submitting form:", error);
          }
        }
      }
    },
  });

  const [showPassword, setShowPassword] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleShow = () => setShow((show) => !show);
  const handleMouse = (event) => {
    event.preventDefault();
  };

  const handleStyle = {
    backgroundColor: "#ffbe0b",
    color: "white",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    border: "none",
    width: "40%",
    padding: "10px 10px",
    fontWeight: "bold",
  };
  ///get Api
  const [get, setGet] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/country/country_code`
      );
      const countryCodes = setGet(response.data);
      setFieldValue(
        "country",
        countryCodes.length > 0 ? countryCodes[0].id : ""
      );
    } catch (error) {
      console.error("Error fetching", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handlePaste = (e) => {
    e.preventDefault();
  };
  const handleAgeChange = (event) => {
    const selectedCountry = event.target.value;
    setFieldValue("country", selectedCountry);
  };
  if (loading) {
    return (
      <div class="loading">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    );
  }
  return (
    <Paper
      elevation={2}
      sx={{
        padding: "20px",
        width: {
          lg: "30%",
        },

        margin: "20px auto",
      }}
    >
      <form onSubmit={handleSubmit}>
        <Grid>
          <Box sx={{ textAlign: "center", marginBottom: "10px" }}>
            <img src={signup} style={{ width: "35%" }} />
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Let's get Started
            </Typography>
            <Typography variant="body1">
              Create an account on to use all the features
            </Typography>
          </Box>

          <TextField
            id="outlined-basic"
            label="Name"
            fullWidth
            sx={{ margin: "5px 0" }}
            name="name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            error={touched.name && Boolean(errors.name)}
            helperText={touched.name && errors.email ? errors.name : null}
          />

          <TextField
            id="outlined-basic"
            label="Email"
            fullWidth
            sx={{ margin: "10px 0" }}
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email ? errors.email : null}
          />
        </Grid>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <FormControl sx={{ width: { lg: "25%", xs: "30%" } }}>
            <InputLabel id="demo-simple-select-label">Country</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={values.country}
              label="country"
              onChange={handleAgeChange}
              onBlur={handleBlur}
              name="country"
              error={touched.country && Boolean(errors.country)}
            >
              {get &&
                get.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.code}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <TextField
            id="outlined-basic"
            label="Contact"
            variant="outlined"
            name="contact"
            sx={{ width: { lg: "70%", sm: "50%" } }}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.contact}
            error={touched.contact && Boolean(errors.contact)}
            helperText={
              touched.contact && errors.contact ? errors.contact : null
            }
          />
        </Grid>

        <FormControl
          label="fullWidth"
          fullWidth
          sx={{ margin: "10px 0" }}
          error={touched.password && Boolean(errors.password)}
        >
          <InputLabel htmlFor="outlined-adornment-password">
            Password
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? "text" : "password"}
            name="password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            onPaste={handlePaste}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
          {touched.password && errors.password ? (
            <FormHelperText>{errors.password}</FormHelperText>
          ) : null}
        </FormControl>

        <FormControl
          sx={{ margin: "10px 0" }}
          label="fullWidth"
          fullWidth
          error={touched.cpassword && Boolean(errors.cpassword)}
        >
          <InputLabel htmlFor="outlined-adornment-password">
            Confirm Password
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={show ? "text" : "password"}
            name="cpassword"
            onPaste={handlePaste}
            value={values.cpassword}
            onChange={handleChange}
            onBlur={handleBlur}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleShow}
                  onMouseDown={handleMouse}
                  edge="end"
                >
                  {show ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
          {errors.cpassword && touched.cpassword ? (
            <FormHelperText>{errors.cpassword}</FormHelperText>
          ) : null}
        </FormControl>

        <Box>
          <label>
            <input
              type="checkbox"
              className="me-1"
              name="termsAndConditions"
              checked={values.termsAndConditions}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            By signing up, you agree to our Terms and Policy
          </label>
          {touched.termsAndConditions && errors.termsAndConditions && (
            <div style={{ color: "#d32f2f", fontSize: "13px" }}>
              {errors.termsAndConditions}
            </div>
          )}
        </Box>
        <Box sx={{ textAlign: "center", marginTop: "10px" }}>
          <button type="submit" style={handleStyle}>
            Sign Up
          </button>
        </Box>
        <Typography
          variant="body1"
          sx={{ textAlign: "center", marginTop: "20px" }}
        >
          If you have an account?{" "}
          <NavLink to="/account" style={{ color: "#ffbe0b" }}>
            Login
          </NavLink>
        </Typography>
      </form>
    </Paper>
  );
}

export default Signup;
