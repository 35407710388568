import React, { useState } from "react";
import { AiFillStar } from "react-icons/ai";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Helmet } from "react-helmet";
import axios from 'axios';
import {  toast } from 'react-toastify';

function Feedback() {
  

  const dynamic = "FeedBack";
  const [rating, setRating] = useState(null);
 
  const [hover, setHover] = useState(null);
  const [textarea,setTextarea]=useState("")
  const[err,setErr]=useState('')
 
  const handleSubmit=(e)=>{
    e.preventDefault()
   
    if (textarea.trim() === '') {
        setErr('Textarea cannot be empty');
      } else {
      
        setErr('');
      }
      const data={
        star:rating,
        feedbacks:textarea
      }
    
      axios.post(`${process.env.REACT_APP_API_BASE_URL}/feedback/feedbackers`,data)
      .then(res=>{
       
          console.log(res.data)
          
            toast.success("FeedBack Send Successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 600,
            })
           
      }
      
      )
      .catch(err=>{
        console.log(`Api is ${err}`)
        if(err.response && err.response.status===500){
          toast.error("Please Provide Rating",{
            position: toast.POSITION.TOP_RIGHT,
            autoClose:600
          })
        }
       
      })
      

  }
 
  return (
    <div>
      <Helmet>
        <title> DCrown Mart | {dynamic}</title>
      </Helmet>
      <div className="container">
        <div className="row justify-content-center">
          <div
            className="col-lg-5  col-11 shadow"
            style={{ marginTop: "120px", height: "80vh" }}
          >
            <div className="text-center mt-4">
              <h4 className="feedback-text">
                Please take a minute to write a review
              </h4>
            </div>
            <form onSubmit={handleSubmit} className="">
              <div
                className="d-flex justify-content-center"
                style={{
                  fontSize: "40px",
                  color: "#b5bdc4",
                  marginTop: "30px",
                }}
              >
                {[...Array(5)].map((star, index) => {
                  const currentRating = index + 1;
                  return (
                    <div>
                      <label>
                        <input
                          type="radio"
                          name="rating"
                          value={currentRating}
                          onClick={() => setRating(currentRating)}
                        />
                        <AiFillStar
                          className="star"
                          size={50}
                          color={
                            currentRating <= (hover || rating)
                              ? "#ffc107"
                              : "#e4e5e9"
                          }
                          onMouseEnter={() => setHover(currentRating)}
                          onMouseLeave={() => setHover(null)}
                        />
                      </label>
                    </div>
                  );
                })}
              </div>
              <div className="mb-3 ">
                <label className="form-label mt-5  feedback-label">
                  Type feedback
                </label>
                <textarea
                  className="form-control feedback-textarea"
                  rows="3"
                  name="textarea"
                  value={textarea}
                  onChange={e=>setTextarea(e.target.value)}
                  
                ></textarea>
                {err && <p style={{ color: 'red' ,paddingLeft:'25px'}}>{err}</p>}
              
                <div className="d-flex  justify-content-center mt-5">
                  <button type="submit" className="feedback-submit">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Feedback;
