import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { Navigate, json, useLocation, useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItemIcon from "@mui/material/ListItemIcon";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import Pagination from "react-js-pagination"; // Import Pagination component
import Logo from "../../assests/images/LogoDM.png";
import { Helmet } from 'react-helmet';
const drawerWidth = 240;
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    })
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
}));
const dynamic = "AdminPanel";
function Adminpanel(props) {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(true);
    const theme = useTheme();

    useEffect(()=>{
        const token = localStorage.getItem('auth_token')
        if (!token) {
            return navigate('/')
        }
    },[])
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    const container =
        window !== undefined ? () => window().document.body : undefined;
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#305931",
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        "&:last-child td, &:last-child th": {
            border: 0,
        },
    }));
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    useEffect(() => {

        async function fetchData(){
            try {
                const token = localStorage.getItem('auth_token')
                if (!token) {
                    return navigate('/')
                }
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users/users`,
                    {
                        headers: {
                            Authorization: token,
                        }
                    }); // Replace with your API endpoint
                if (response.status === 200) {
                    const jsonData = response.data;
                    setData(jsonData);
                    setFilteredData(jsonData)
                                    }
            } catch (err) {
                console.error('Error:', err);
            }
        }
        fetchData()
    }, []);


    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const myprofile = () => {
        navigate("/myprofile");
    };
    const logout = () => {
        localStorage.clear();
        navigate("/");
    };
    const [currentpage, setCurrentPage] = useState(1);
    const recordsPerPage = 10;
    const firstIndex = (currentpage - 1) * recordsPerPage;
    const lastIndex = currentpage * recordsPerPage;
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const location = window.location;
   

    const handeldelete = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You are about to delete this user data. This action cannot be undone.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .delete(`${process.env.REACT_APP_API_BASE_URL}/admin/delete/${id}`)
                    .then((response) => {
                        if (response.status === 200) {
                            window.location.reload();
                            toast.success('User Data Deleted Successfully', {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 600,
                            });
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        });
    };
    
    const handleFilter = (event) => {
        const query = event.target.value.toLowerCase();
        const queryUppercase = event.target.value.toUpperCase();
        const queryCamelCase = toCamelCase(event.target.value);
      
        const filtered = data.filter((item) => {
          return (
            (item.name && (item.name.toLowerCase().includes(query) || item.name.includes(queryUppercase) || item.name.includes(queryCamelCase))) ||
            (item.email && (item.email.toLowerCase().includes(query) || item.email.includes(queryUppercase) || item.email.includes(queryCamelCase))) ||
            (item.user_address && (item.user_address.toLowerCase().includes(query) || item.user_address.includes(queryUppercase) || item.user_address.includes(queryCamelCase))) ||
            (item.ref_id && (item.ref_id.toLowerCase().includes(query) || item.ref_id.includes(queryUppercase) || item.ref_id.includes(queryCamelCase))) ||
            (item.discount_point && (item.discount_point.toString().toLowerCase().includes(query) || item.discount_point.toString().includes(queryUppercase) || item.discount_point.toString().includes(queryCamelCase))) ||
            (typeof item.contact === 'string' && (item.contact.toLowerCase().includes(query) || item.contact.includes(queryUppercase) || item.contact.includes(queryCamelCase)))
          );
        });
      
        setFilteredData(filtered);
      };
      
      // Function to convert string to camelCase
      const toCamelCase = (str) => {
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
          return index === 0 ? word.toLowerCase() : word.toUpperCase();
        }).replace(/\s+/g, '');
      };
      


    return (
        <Box sx={{ display: "flex" }}>
            <Helmet>
                <title> DCrown Mart | {dynamic}</title>
            </Helmet>
            <CssBaseline />
            <AppBar
                position="fixed"
                className="dashboard-bar"
                open={open}
                style={{ backgroundColor: "#FFD700" }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: "none" }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        DCrownMart
                    </Typography>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit"
                        className="logout-icon"
                        style={{ position: "absolute", right: "0" }}
                    >
                        <AccountCircle sx={{ width: 40, height: 40 }} />
                    </IconButton>
                    <Menu
                        sx={{ mt: "45px" }}
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >

                        <MenuItem onClick={logout}>Log Out</MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>

            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                        width: drawerWidth,
                        boxSizing: "border-box",
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <img src={Logo} alt="Logo" width="75%" />
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === "ltr" ? (
                            <ChevronLeftIcon />
                        ) : (
                            <ChevronRightIcon />
                        )}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    <ListItem >
                        <ListItemButton onClick={() => { navigate("/admin/Dashboard") }}>
                            <ListItemIcon>
                                <h5 className="">
                                    <span className="p-1" ><i class="fa-solid fa-box"></i> Dashboard</span>
                                </h5>
                            </ListItemIcon>
                            <ListItemText />
                        </ListItemButton>

                    </ListItem>
                    <ListItem >

                        <ListItemButton className="bg-warning " onClick={() => { navigate("/admin/adminpanel") }}>
                            <ListItemIcon>
                                <h5 className="">
                                    <span className="  text-white" ><i class="fa-solid fa-box"></i > Users </span>
                                </h5>
                            </ListItemIcon>
                            <ListItemText />
                        </ListItemButton>

                    </ListItem>
                    <ListItem >
                        <ListItemButton onClick={() => { navigate("/admin/level") }}>
                            <ListItemIcon>
                                <h5 className="">
                                    <span className="p-1" ><i class="fa-solid fa-chart-simple"></i> Level Amount</span>
                                </h5>
                            </ListItemIcon>
                            <ListItemText />
                        </ListItemButton>

                    </ListItem>
                    <ListItem >
                        <ListItemButton onClick={() => { navigate("/admin/adminmembership") }}>
                            <ListItemIcon>
                                <h5 className="">
                                    <span className="p-1" ><i class="fa-solid fa-user-plus"></i> Membership</span>
                                </h5>
                            </ListItemIcon>
                            <ListItemText />
                        </ListItemButton>

                    </ListItem>
                    <ListItem >
                        <ListItemButton onClick={() => { navigate("/admin/transaction") }}>
                            <ListItemIcon>
                                <h5 className="">
                                    <span className="p-1" ><i class="fa-regular fa-credit-card"></i>    transaction</span>
                                </h5>
                            </ListItemIcon>
                            <ListItemText />
                        </ListItemButton>

                    </ListItem>
                    <ListItem >
                        <ListItemButton onClick={() => { navigate("/admin/Product") }}>
                            <ListItemIcon>
                                <h5 className="">
                                    <span className="p-1"><i class="fa-solid fa-cart-shopping"></i> Product</span>
                                </h5>
                            </ListItemIcon>
                            <ListItemText />
                        </ListItemButton>

                    </ListItem>
                    <ListItem >
                        <ListItemButton onClick={() => { navigate("/admin/tree") }}>
                            <ListItemIcon>
                                <h5 className="">
                                    <span className="p-1" ><i class="fa-solid fa-chart-bar"></i> Tree</span>
                                </h5>
                            </ListItemIcon>
                            <ListItemText />
                        </ListItemButton>

                    </ListItem>
                    <ListItem >
                        <ListItemButton onClick={() => { navigate("/admin/Bank_details") }}>
                            <ListItemIcon>
                                <h5 className="">
                                    <span><i class="fa-brands fa-squarespace"></i> Account</span>
                                </h5>
                            </ListItemIcon>
                            <ListItemText />
                        </ListItemButton>

                    </ListItem>
                    <ListItem >
                        <ListItemButton  onClick={() => { navigate("/admin/Manual_entry") }}>
                            <ListItemIcon>
                                <h5 className="">
                                    <span><i class="fa-solid fa-magnifying-glass"></i> Manual_entry</span>
                                </h5>
                            </ListItemIcon>
                            <ListItemText />
                        </ListItemButton>

                    </ListItem>
                    <ListItem >
                        <ListItemButton  onClick={() => { navigate("/admin/Orderlist") }}>
                            <ListItemIcon>
                                <h5 className="">
                                    <span><i class="fa-solid fa-cart-shopping"></i> Order List</span>
                                </h5>
                            </ListItemIcon>
                            <ListItemText />
                        </ListItemButton>

                    </ListItem>
                    <ListItem >
                        <ListItemButton  onClick={() => { navigate("/admin/withdrawlTransaction") }}>
                            <ListItemIcon>
                                <h5 className="">
                                    <span><i class="fa-solid fa-money-bill-transfer"></i>Withdrawl  <br></br><p className="text-center" style={{fontSize:"16px"}}>Transaction</p></span>
                                </h5>
                            </ListItemIcon>
                            <ListItemText />
                        </ListItemButton>

                    </ListItem>
                    <ListItem >
                        <ListItemButton  onClick={() => { navigate("/admin/withdrawl") }}>
                            <ListItemIcon>
                                <h5 className="">
                                <span><i class="fa-solid fa-money-bill-transfer"></i>  Withdrawl<br></br><p className="text-center" style={{fontSize:"16px"}}>User Bank Accounts</p></span>

                                </h5>
                            </ListItemIcon>
                            <ListItemText />
                        </ListItemButton>

                    </ListItem>
                </List>
                <Divider />
            </Drawer>
            <Main open={open}>
                <DrawerHeader />
                <h2 className="mb-4 mt-3">Admin</h2>
                <TableContainer component={Paper}>
                    <input
                        type="text"
                        className="float-end mb-3 searchbar"
                        onChange={handleFilter}
                        placeholder="Search "
                    />
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                            <StyledTableCell align="center"> Id</StyledTableCell>

                                <StyledTableCell align="center">User ID</StyledTableCell>
                                <StyledTableCell align="center">Name</StyledTableCell>
                                <StyledTableCell align="center">Email</StyledTableCell>
                                <StyledTableCell align="center">Number</StyledTableCell>
                                <StyledTableCell align="center">Referal id</StyledTableCell>
                                <StyledTableCell align="center"> Package</StyledTableCell>

                                
                                <StyledTableCell align="center">discount</StyledTableCell>
                                <StyledTableCell align="center">Wallet</StyledTableCell>
                                <StyledTableCell align="center">Action</StyledTableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {filteredData.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={8} align="center">
                                        No data available in this table
                                    </TableCell>
                                </TableRow>
                            ) : (
                                filteredData.slice(firstIndex, lastIndex).map((items) => (
                                    <StyledTableRow key={items.id}>
                                         <StyledTableCell component="th" scope="row" align="center">
                                            {items.id}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {items.user_address}
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row" align="center">
                                            {items.name}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {items.email}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {items.contact}
                                        </StyledTableCell>
                                    
                                        <StyledTableCell align="center">
                                            {items.ref_id}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {items.membership_package}
                                        </StyledTableCell>
                                        
                                        <StyledTableCell align="center">
                                            {items.discount_point}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {items.wallet}
                                        </StyledTableCell>

                                        <StyledTableCell align="center">
                                            <button
                                                className="btn btn-primary btn-xs"
                                                data-title="Edit"
                                                data-toggle="modal"
                                                data-target="#edit"
                                                onClick={() => navigate(`edit/${items.id}`)}

                                            >
                                                <i className="fas fa-pencil-alt"></i>
                                            </button>
                                            <button
                                                className="btn btn-danger btn-xs ms-3"
                                                data-title="Delete"
                                                data-toggle="modal"
                                                data-target="#delete"
                                                onClick={() =>
                                                    handeldelete(items.id)
                                                }

                                            >
                                                <i className="fas fa-trash"></i>
                                            </button>
                                            <button
                                                className="btn btn-info btn-xs ms-3"
                                                onClick={() => { navigate(`view/${items.id}`) }}
                                            >
                                                <i className="fas fa-eye"></i>
                                            </button>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>



                    <div className="pagination-container">
                        <Pagination
                            activePage={currentpage}
                            itemsCountPerPage={recordsPerPage}
                            totalItemsCount={filteredData.length}
                            pageRangeDisplayed={2}
                            className="mt-3"
                            onChange={handlePageChange}
                            itemclassName="page-item"
                            linkclassName="page-link"
                            innerclassName="pagination"
                        />
                    </div>
                </TableContainer>
            </Main>
        </Box>
    );
}

export default Adminpanel;