import { IoAddCircle } from "react-icons/io5";
import { HiMinusCircle } from "react-icons/hi2";
import { toast } from "react-toastify";
import { useState, useEffect,useContext } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import {base_url} from "../../utility/Url"


import { createData } from "../../routes/HomeRouter";
import err_img from "../../assests/images/error_img.png";
import Show from "../../assests/images/Your cart is empty.jpg";
import { Link, useNavigate } from "react-router-dom";
import { createContext } from "react";

export const allDetails = createContext(null);
function Addtocart() {
  const { setRender } = useContext(createData);
  const navigate = useNavigate();

  const [memberShip, setMemberShip] = useState();

  const [loading, setLoading] = useState(false);
  const [cart, setCart] = useState([]);
  const Token = localStorage.getItem("token");

  const [Price, setPrice] = useState(0);

  useEffect(() => {
    if (!Token) {
      Swal.fire({
        title: "Please Login",
        imageUrl: err_img,
confirmButtonColor: "#ffbe0b",
        confirmButtonText: "Login",
        html: "Please login and continue your shopping",
        showCancelButton: false,
        imageWidth: 200,
        imageHeight: 200,
        customClass: {
          image: "swal2-image",
          popup: "swal2-popup",
        },
      }).then(() => {
        navigate("/account");
      });
    }

    else{
      const response = axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/users/getMembershipData`, {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        const data = response["data"].userData[0]["membership_package"];
        setMemberShip(data);
        console.log(memberShip, "memberShip");
      })
      .catch((error) => {
        console.log(error.message, "getMembershipData");
      });

    }
   

  }, []);
  useEffect(() => {

    if (!Token) {
      Swal.fire({
        title: "Please Login",
        imageUrl: err_img,
confirmButtonColor: "#ffbe0b",
        confirmButtonText: "Login",
        html: "Please login and continue your shopping",
        showCancelButton: false,
        imageWidth: 200,
        imageHeight: 200,
        customClass: {
          image: "swal2-image",
          popup: "swal2-popup",
        },
      }).then(() => {
        navigate("/account");
      });
    }
    else{
      fetchData();
    }
   
  }, []);
  const config = {
    headers: {
      Authorization: `Bearer ${Token}`,
    },
  };
  async function fetchData() {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/addToCart/getAddToCardProduct`,
        config
      );
      const updatedCart = response.data.map((item) => ({
        ...item,
        quantity: 1,
      }));
      setCart(updatedCart);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }
  const handleIncrease = (id) => {
    const updatedCart = cart.map((item) => {
      if (item.id === id) {
        return { ...item, quantity: item.quantity + 1 };
      }
      return item;
    });
    setCart(updatedCart);
  };

  const handleRemove = async (id) => {
    setLoading(true);

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/addToCart/deleteProduct/${id}`,
        config
      );
      setLoading(false);

      if (response.status === 200) {
        const updatedCart = cart.filter((item) => item.id !== id);
        setCart(updatedCart);
        setRender((per) => per - 1);
        fetchData();
        toast.error("Product removed ", {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 500,
        });
      } else {
        console.error(
          "Failed to remove product. Server responded with status:",
          response.status
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error removing item:", error.message);
    }
  };

  const handleDecrease = async (id) => {
    const updatedCart = cart
      .map((item) => {
        if (item.id === id && item.quantity > 1) {
          return { ...item, quantity: item.quantity - 1 };
        }
        return item;
      })
      .filter(Boolean);
    setCart(updatedCart);
  };

  const handlePrice = () => {
    let ans = 0;
    cart.forEach((item) => {
      ans += item["productlist.price"] * item.quantity;
    });
    setPrice(ans);
  };

  useEffect(() => {
    handlePrice();
  });
  const handleStyle = {
    backgroundColor: "#ffbe0b",
    color: "white",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    border: "none",
    width: "40%",
    padding: "10px 10px",
    fontWeight: "bold",
    textDecoration: "none",
  };

  if (loading) {
    return (
      <div className="loading">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    );
  }
  const handlePayment = () => {
    if (!memberShip) {
      Swal.fire({
        title: "Upgrade Your Membership",
        imageUrl: err_img,
        confirmButtonColor: "black",
        confirmButtonText: "Upgrade",
        html: "Please Upgrade your Membership  and continue your shopping",
        showCancelButton: false,
        imageWidth: 200,
        imageHeight: 200,
        customClass: {
          image: "swal2-image",
          popup: "swal2-popup",
        },
      });
      navigate(`/payment`);
    } else(
      // navigate(`/checkOut`, {
      //   state: { cartData: cart, price: Price },
      // })
      Swal.fire({
      
        text: "Enhancements in Progress. Coming Soon!",
      }))
  };

  const marginStyle = {
    marginTop: "150px",
  };

  return (
    <>
      {cart.length ? (
        <div style={{ marginTop: "130px" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-7 ">
                {cart.map((item) => (
                  <div className="col-lg-12 add-cart-shadow data_all_details">
                    <div className="col-lg-3 data_image_details">
                      <img
                        src={`${base_url}/${item["productlist.image"]}`}
                        width="80%"
                      />
                    </div>
                    <div className="col-lg-3 mt-5 addtocart-rate text-center">
                      <h5> {item["productlist.name"]}</h5>
                      <b>
                        <p> ₹ {item["productlist.price"]}.00</p>
                      </b>
                    </div>

                    <div className="col-lg-6 d-flex  justify-content-end align-items-center ">
                      <div className="d-flex  flex-column">
                        <div className="pe-4 mt-0 me-4 d-flex">
                          <div className="">
                            <button
                              className=" btn addtocart-button"
                              onClick={() => handleDecrease(item.id)}
                            >
                              <i className="fa-solid fa-minus"></i>
                            </button>
                          </div>
                          <div className="ps-2 pe-2 pt-1">
                            <span
                              style={{ fontSize: "1.5rem", marginTop: "2px" }}
                            >
                              {item.quantity}
                            </span>
                          </div>
                          <div>
                            <button
                              className=" btn addtocart-button"
                              onClick={() => handleIncrease(item.id)}
                            >
                              <i className="fa-solid fa-plus"></i>
                            </button>
                          </div>
                          <div className="text-center ms-5 add-trash mt-1">
                            <button
                              className="mb-2 border"
                              onClick={() =>
                                handleRemove(item["productlist.id"])
                              }
                            >
                              {" "}
                              <i className="fa-regular fa-trash-can"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-lg-1"></div>

              {/* proceed to pay */}

              <div className="col-lg-3  h-25 add-cart-shadow  position-sticky top-0 mt-2 pt-3">
                <div>
                  <h4>Card Totals</h4>
                </div>
                <div className="d-flex justify-content-around mt-4">
                  <p>SubTotal ({cart?.length} items) </p>
                  <p>₹{Price}.00</p>
                </div>

                <div className="d-flex justify-content-around mt-3">
                  <p>Delivery Charges</p>
                  <p>Free</p>
                </div>

                <div className="d-flex justify-content-around mt-3">
                  <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                    {" "}
                    Total Amount
                  </p>
                  <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                    ₹{Price}.00
                  </p>
                </div>
                <div className="cart-button  mt-3 mb-2">
                  {/* <button
                  type="submit"
                  className=""
                  onClick={() =>
                    navigate(`/checkOut`, {
                      state: { cartData: cart, price: Price },
                    })
                  }
                >
                  Proceed to Checkout
                </button> */}
                  <button type="submit" className="" onClick={handlePayment}>
                    Proceed to Checkout
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={marginStyle}>
          <div className="row" style={{margin:"0"}}>
            <div className="col-lg-3"></div>
            <div className="col-lg-3">
              <div className="mt-4">
                <img src={Show} width="100%" />
              </div>
            </div>
            <div className="col-lg-3 mt-5">
              <div className="text-center">
                <h5 className="">Your cart is empty!</h5>
                <p>
                  Discover our extensive range and locate something that
                  captures your interest.
                </p>
                <div>
                  <Link to="/" style={handleStyle}>
                    Go Back Home
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3"></div>
          </div>
        </div>
      )}
    </>
  );
}

export default Addtocart;
