import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { Helmet } from "react-helmet";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { Navigate, json, useLocation, useNavigate } from "react-router-dom";
import TableRow from "@mui/material/TableRow";
import { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItemIcon from "@mui/material/ListItemIcon";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Logo from "../../assests/images/LogoDM.png"; // Import Pagination component
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { Container, TextField, Button, Grid, Paper } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

const drawerWidth = 240;
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const container =
  window !== undefined ? () => window().document.body : undefined;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#305931",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

function Manual_entry() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  useEffect(()=>{
    const token = localStorage.getItem('auth_token')
    if (!token) {
        return navigate('/')
    }
},[])
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const theme = useTheme();
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const logout = () => {
    localStorage.clear();
    navigate("/");
  };

  const [value, setValue] = useState([]);
  const [input, setInput] = useState(null);
  const [input2, setInput2] = useState(null);

  const URL = `${process.env.REACT_APP_API_BASE_URL}/users/users`;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("auth_token");

        if (!token) {
          return navigate("/");
        } else {
          const response = await fetch(URL);
          const json = await response.json();

          const formattedOptions = json.map((option) => ({
            id: option.id,
            label: `${option.name}-${option.user_address}`,
          }));

          setValue(json);
        }
      } catch (err) {
        console.log(err.message, "error");
      }
    };

    fetchData();
  }, [navigate]);

  const showToast = (message, isError = false) => {
    toast[isError ? "error" : "success"](message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input === input2) {
      toast.error("Please select different users", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    } else {
      const data = `${input.id} , ${input2.user_address}`;
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/membership/membershipadd`,
          {
            id: input.id,
            ref_id: input2.user_address,
          }
        )
        .then((res) => {
          const { status, message } = res.data;
          if (res.status === 201) {
            showToast("Membership upgraded successfully");
          window.location.reload()


          } else {
            showToast(message, true);
            console.error(message, "error");
          }
        })
        .catch((error) => {
          if (error.response) {
            const { status, data } = error.response;
            if (status === 404) {
              showToast("Error in checking referral ID", true);
            } else if (status === 403) {
              showToast("Cannot refer to your own referral ID", true);
            } else if (status === 400) {
              showToast("Sorry, you have already referred someone", true);
            } else {
              showToast(data.message || "Unknown error occurred", true);
            }
          } else if (error.request) {
            toast.error("No response received from the server", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          } else {
            toast.error("Error setting up the request", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }
        });
    }
  };

  const handleSubmit2 = (e) => {
    e.preventDefault();
    if (input) {
      const data = `${input.id}`;
    } else {
      console.log("Invalid input");
    }
  };

  const dynamic = "AdminPanel";
  return (
    <Box sx={{ display: "flex" }}>
      <Helmet>
        <title> DCrown Mart | {dynamic}</title>
      </Helmet>
      <CssBaseline />
      <AppBar
        position="fixed"
        className="dashboard-bar"
        open={open}
        style={{ backgroundColor: "#FFD700" }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            DCrownMart
          </Typography>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
            className="logout-icon"
            style={{ position: "absolute", right: "0" }}
          >
            <AccountCircle sx={{ width: 40, height: 40 }} />
          </IconButton>

          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={logout}>Log Out</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader className="red">
          <img src={Logo} alt="Logo" width="75%" />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem>
            <ListItemButton
              onClick={() => {
                navigate("/admin/Dashboard");
              }}
            >
              <ListItemIcon>
                <h5 className="">
                  <span className="p-1 ">
                    <i className="fa-solid fa-box"></i> Dashboard
                  </span>
                </h5>
              </ListItemIcon>
              <ListItemText />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              onClick={() => {
                navigate("/admin/adminpanel");
              }}
            >
              <ListItemIcon>
                <h5 className="">
                  <span className="p-1 ">
                    <i className="fa-solid fa-box"></i> Users
                  </span>
                </h5>
              </ListItemIcon>
              <ListItemText />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              onClick={() => {
                navigate("/admin/level");
              }}
            >
              <ListItemIcon>
                <h5 className="">
                  <span className="p-1 ">
                    <i className="fa-solid fa-chart-simple"></i> Level Amount
                  </span>
                </h5>
              </ListItemIcon>
              <ListItemText />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              onClick={() => {
                navigate("/admin/adminmembership");
              }}
            >
              <ListItemIcon>
                <h5 className="">
                  <span className="p-1 ">
                    <i className="fa-solid fa-user-plus"></i> Membership
                  </span>
                </h5>
              </ListItemIcon>
              <ListItemText />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              onClick={() => {
                navigate("/admin/transaction");
              }}
            >
              <ListItemIcon>
                <h5 className="">
                  <span className="p-1 ">
                    <i className="fa-regular fa-credit-card"></i> transaction
                  </span>
                </h5>
              </ListItemIcon>
              <ListItemText />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              onClick={() => {
                navigate("/admin/Product");
              }}
            >
              <ListItemIcon>
                <h5 className="">
                  <span className="p-1 ">
                    <i className="fa-solid fa-cart-shopping"></i> Product
                  </span>
                </h5>
              </ListItemIcon>
              <ListItemText />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              onClick={() => {
                navigate("/admin/tree");
              }}
            >
              <ListItemIcon>
                <h5 className="">
                  <span className="p-1 ">
                    <i className="fa-solid fa-chart-bar"></i> Tree
                  </span>
                </h5>
              </ListItemIcon>
              <ListItemText />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              onClick={() => {
                navigate("/admin/Bank_details");
              }}
            >
              <ListItemIcon>
                <h5 className="">
                  <span className="p-1 ">
                    <i className="fa-brands fa-squarespace"></i> Account
                  </span>
                </h5>
              </ListItemIcon>
              <ListItemText />
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton
              className="bg-warning "
              onClick={() => {
                navigate("/admin/Manual_entry");
              }}
            >
              <ListItemIcon>
                <h5 className="">
                  <span className="p-1 ">
                    <i class="fa-solid fa-magnifying-glass"></i> Manual_entry
                  </span>
                </h5>
              </ListItemIcon>
              <ListItemText />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              onClick={() => {
                navigate("/admin/Orderlist");
              }}
            >
              <ListItemIcon>
                <h5 className="">
                  <span className="p-1 ">
                    <i class="fa-solid fa-cart-shopping"></i> Order List
                  </span>
                </h5>
              </ListItemIcon>
              <ListItemText />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              onClick={() => {
                navigate("/admin/withdrawlTransaction");
              }}
            >
              <ListItemIcon>
                <h5 className="">
                <span className="p-1 "><i class="fa-solid fa-money-bill-transfer"></i>Withdrawl <br></br><p className="text-center" style={{fontSize:"16px"}}>Transaction</p></span>

                </h5>
              </ListItemIcon>
              <ListItemText />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              onClick={() => {
                navigate("/admin/withdrawl");
              }}
            >
              <ListItemIcon>
                <h5 className="">
                <span className="p-1 "><i class="fa-solid fa-money-bill-transfer"></i>  Withdrawl<br></br><p className="text-center" style={{fontSize:"16px"}}>User Bank Accounts</p></span>

                </h5>
              </ListItemIcon>
              <ListItemText />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <h2 className="mb-4 mt-3">
          Manual entry - <span className="fs-5">(Super_deals)</span>
        </h2>
        <div className="manual-entry-container">
          <div className="manual-entry">
            <form onSubmit={handleSubmit}>
              <Grid item xs={12} lg={12} className="mt-5">
                <Autocomplete
                  options={value}
                  getOptionLabel={(option) =>
                    `${option.name} - ${option.user_address}`
                  }
                  value={input2}
                  onChange={(event, newValue) => setInput2(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Referel"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} lg={12} className="mt-5">
                <Autocomplete
                  options={value}
                  getOptionLabel={(option) =>
                    `${option.name} - ${option.user_address}`
                  }
                  value={input}
                  onChange={(event, newValue) => setInput(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select User"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <button type="submit" className="mt-5 btn btn-primary">
                Set Downline
              </button>
            </form>
          </div>
        </div>

        {/* <div className="manual-entry-container mt-5">
          <div className="manual-entry">
            <h6 className="ps-2">Remove User</h6>
            <form onSubmit={handleSubmit2}>
              <Grid item xs={12} lg={12} className="mt-2">
                <Autocomplete
                  options={value}
                  getOptionLabel={(option) => `${option.name} - ${option.contact} - ${option.user_address}`}
                  value={input}
                  onChange={(event, newValue) => setInput(newValue)}
                  renderInput={(params) => (
                    <TextField {...params} label="Select User" variant="outlined" fullWidth />
                  )}
                />
              </Grid>
              <button type="submit" className="mt-2 btn btn-primary">
                Remove
              </button>
            </form>
          </div>

        </div> */}
      </Main>
    </Box>
  );
}

export default Manual_entry;
