import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Grid, Paper, Typography, TextField, Box } from "@mui/material";
import axios from "axios";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
const WithdrawalForm = () => {
  const navigate = useNavigate();
  const Token = localStorage.getItem("token");
  const [value, setValue] = useState({});
  const [accId, setAccId] = useState("");

  const user_detail = JSON.parse(localStorage.getItem("user_detail"));
  const UserID = user_detail.id;
  const ContactID = user_detail.contact;
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    setSubmitting,
    setFieldError,
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: {
      Account_Holder_Name: "",
      Account_No: "",
      Re_Enter_Ac: "",
      IFSC_code: "",
      Bank_Name: "",
      Branch_Name: "",
    },
    validationSchema: Yup.object().shape({
      Account_Holder_Name: Yup.string()
        .matches(
          /^[A-Z][a-zA-Z\s]*$/,
          "Account Holder Name must start with a capital letter and can contain only characters and spaces"
        )
        .required("Account Holder Name is required"),

      Account_No: Yup.string()
        .required("Account.No is required"),
      Re_Enter_Ac: Yup.string()
        .oneOf([Yup.ref("Account_No"), null], "Account.No Does not match")
        .required("Re Enter Ac.no is required"),
      IFSC_code: Yup.string().required("IFSC code is required"),
    }),

    onSubmit: async (values) => {
      //     setLoading(true)
      //     axios.post(`${process.env.REACT_APP_API_BASE_URL}/withdrawl/FundAccountCreate`,{ContactID,values,UserID} )
      //     .then(response => {
      //   setLoading(false)
      //   const fund_account_id = response.data.fund_account;
      //   axios.put(`${process.env.REACT_APP_API_BASE_URL}/withdrawl/updateFundAccountId`, {fund_account_id,UserID})
      //   .then((res)=>{
      //     setLoading(false)
      //     console.log(res)
      //   })
      //   toast.success("Fund Account created Successfully", {
      //     position: toast.POSITION.TOP_RIGHT,
      //     autoClose: 800,
      //   });
      //   navigate('/EnterWithdrawAmount')
      // })
      // .catch(error => {
      //   setLoading(false)
      //   if (error.response && error.response.status === 409) {
      //     setFieldError('email', 'Email already exists');
      //     toast.error("Email already exists", {
      //       position: toast.POSITION.TOP_RIGHT,
      //       autoClose: 500,
      //     });
      //   } else {
      //     console.error('Error submitting form:', error);
      //   }
      // });
      try {
        const response = await axios
          .post(
            `${process.env.REACT_APP_API_BASE_URL}/withdrawl/AccountCreate`,
            { values, UserID }
          )
          .then((response) => {
            console.log(response.data, "data");
            navigate("/EnterWithdrawAmount", {
              state: response.data,
            });
          });
      } catch {
        console.log(errors);
      }
    },
  });
  const config = {
    headers: {
      Authorization: `Bearer ${Token}`,
    },
  };
console.log(errors," comman errors in withdraw")
  useEffect(() => {
    const getData = async () => {
      const user_detail = JSON.parse(localStorage.getItem("user_detail"));
  const UserID = user_detail.id;
  console.log(UserID,"ddaadad")
      try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/withdrawl/getAccountDetials`,
           { params: {
              UserID: UserID,
            }},
            {
                
              headers: {
                Authorization: `Bearer ${Token}`,
              },
            }
          )
          .then((response) => {
            const data = response.data.AccountId[0];

            setAccId("AccountId");
            setValue(data);
            console.log(data);
          });
      } catch (error) {
        console.log(error.message);
      }
    };

    getData();
  }, []);

  const handleIFSCCodeChange = async (e) => {
    const ifscCode = e.target.value;
    setFieldValue("IFSC_code", ifscCode);

    try {
      const response = await axios.get(`https://ifsc.razorpay.com/${ifscCode}`);

      if (response && response.data) {
        const { BRANCH, BANK } = response.data;

        setFieldValue("Branch_Name", BRANCH);
        setFieldValue("Bank_Name", BANK);
      }
    } catch (error) {
      console.error("Error fetching branch details:", error);
    }
  };

  const handleStyle = {
    backgroundColor: "#ffbe0b",
    color: "white",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    border: "none",
    width: "40%",
    padding: "10px 10px",
    fontWeight: "bold",
  };
  const handleStyle_edit = {
    backgroundColor: "#ffbe0b",
    color: "white",
    // boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    border: "none",
    borderRadius: "5px",
    width: "100%",
    padding: "5px 5px",
    fontWeight: "bold",
  };
  // const handleFormSubmit = async (e) => {
  //   e.preventDefault();

  //   try {
  //      handleSubmit(e);
  //   } catch (error) {
  //     console.error("Error submitting form:", error);
  //   }
  // };

  if (loading) {
    return (
      <div className="loading">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    );
  }
  const handlepaste = (e) => {
    e.preventDefault();
  };
  const fields = [
    { field: "Account_Holder_Name", label: "Account Holder Name" },
    { field: "Account_No", label: "Account No " },
    { field: "IFSC_code", label: "IFSC code" },
    { field: "Bank_Name", label: "Bank Name" },

    { field: "Branch_Name", label: "Branch Name" },
  ];
  const handleCustomValidation = () => {
    if (!values.flexRadioDefault) {
      toast.error(" Select Account is required", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } else {
      navigate("/EnterWithdrawAmount");
    }
  };

  const handleInputChange = (field, value) => {
    setValue((prevRecord) => ({
      ...prevRecord,
      [field]: value,
    }));
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/withdrawl/updateWithdrawlAcc`,
        { value }
      );
      if (response.status === 200) {
        Swal.fire(
          "Updated!",
          "The Withdraw account  has been Updated.",
          "success"
        )
          .then(() => {
            setTimeout(() => {
              window.location.reload();
            }, 500);
          })
          .catch((error) => console.error(error));
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Paper
        elevation={2}
        sx={{
          padding: "20px",
          width: {
            lg: "100%",
          },

          margin: "100px auto",
        }}
      >
        {value ? (
          <div className="container">
          
             

              <div className="col-lg-5 mx-auto shadow bg-white rounded">
                <div class="form-check p-5">
                  <div className="d-flex justify-content-between">
                    <input
                      className="form-check-input chech_Account"
                      type="checkbox"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      checked={values.flexRadioDefault} // Add this line to reflect the checkbox state
                      onChange={handleChange} // Add this line to handle changes
                    />
                    <div
                      class="modal fade mt-5 pt-5"
                      id="exampleModal"
                      tabindex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">
                              Edit Withdraw Account Details
                            </h5>
                            <button
                              type="button"
                              class="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div class="modal-body">
                            <div className="">
                              <label>Ac.Holder Name :</label>
                              <input
                                type="text"
                                className=" w-100 mb-3 searchbar form-control"
                                value={value.Account_Holder_Name}
                                placeholder="Enter"
                                onChange={(e) =>
                                  handleInputChange(
                                    "Account_Holder_Name",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                            <div className="">
                              <label>Account No :</label>
                              <input
                                type="text"
                                className=" w-100 mb-3 searchbar form-control"
                                value={value.Account_No}
                                placeholder="Enter"
                                onChange={(e) =>
                                  handleInputChange(
                                    "Account_No",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                            <div className="">
                              <label>IFSC code</label>
                              <input
                                type="text"
                                className=" w-100 mb-3 searchbar form-control"
                                value={value.IFSC_code}
                                placeholder="Enter"
                                onChange={(e) =>
                                  handleInputChange("IFSC_code", e.target.value)
                                }
                              />
                            </div>
                            <div className="">
                              <label>Bank Name</label>
                              <input
                                type="text"
                                className=" w-100 mb-3 searchbar form-control"
                                value={value.Bank_Name}
                                placeholder="Enter"
                                onChange={(e) =>
                                  handleInputChange("Bank_Name", e.target.value)
                                }
                              />
                            </div>
                            <div className="">
                              <label>Branch Name</label>
                              <input
                                type="text"
                                className=" w-100 mb-3 searchbar form-control"
                                value={value.Branch_Name}
                                placeholder="Enter"
                                onChange={(e) =>
                                  handleInputChange(
                                    "Branch_Name",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div class="modal-footer">
                            <button
                              type="button"
                              class="btn btn-secondary"
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              class="btn btn-primary"
                              onClick={(e) => handleFormSubmit(e)}
                            >
                              Save changes
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <button
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        style={handleStyle_edit}
                      >
                        <i class="fa-solid fa-pen-to-square "> </i>Edit{" "}
                      </button>
                    </div>
                  </div>

                  <label class="form-check-label" for="flexRadioDefault2">
                    <div className="p-3">
                      <h2 className="mb-3 profile-head">Account Details</h2>
                      {fields.map((fieldObj, index) => (
                        <div className="row" key={index}>
                          <div className="col-lg-12 col-12 ">
                            <div className="row data_user">
                              <div className="col-lg-5 col-3">
                                <h5>{fieldObj.label}</h5>
                              </div>

                              <div className="col-lg-2 col-2">
                                <h5>:</h5>
                              </div>
                              <div className="col-lg-5 col-7">
                                <h5>{value[fieldObj.field]}</h5>
                              </div>
                              <hr></hr>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </label>

                  <button
                    type="submit"
                    onClick={handleCustomValidation}
                    style={handleStyle}
                  >
                    Next
                  </button>
                </div>
              </div>
          
          </div>
        ) : (
          <div className="col-lg-4  mx-auto shadow p-5 bg-white rounded">
            <form onSubmit={handleSubmit}>
              <Grid>
                <Box sx={{ textAlign: "center", marginBottom: "10px" }}>
                  <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                    Add Withdraw Account
                  </Typography>
                </Box>

                <TextField
                  id="outlined-basic"
                  label="Account Holder Name"
                  fullWidth
                  sx={{ margin: "5px 0" }}
                  name="Account_Holder_Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.Account_Holder_Name}
                  error={
                    touched.Account_Holder_Name &&
                    Boolean(errors.Account_Holder_Name)
                  }
                  helperText={
                    touched.Account_Holder_Name && errors.Account_Holder_Name
                      ? errors.Account_Holder_Name
                      : null
                  }
                />

                <TextField
                  id="outlined-basic"
                  label="Account No"
                  fullWidth
                  sx={{ margin: "10px 0" }}
                  name="Account_No"
                  onChange={handleChange}
                  onPaste={handlepaste}
                  onBlur={handleBlur}
                  value={values.Account_No}
                  error={touched.Account_No && Boolean(errors.Account_No)}
                  helperText={
                    touched.Account_No && errors.Account_No
                      ? errors.Account_No
                      : null
                  }
                />
                <TextField
                  id="outlined-basic"
                  label="Re Enter Ac.No"
                  fullWidth
                  sx={{ margin: "10px 0" }}
                  name="Re_Enter_Ac"
                  onChange={handleChange}
                  onPaste={handlepaste}
                  onBlur={handleBlur}
                  value={values.Re_Enter_Ac}
                  error={touched.Re_Enter_Ac && Boolean(errors.Re_Enter_Ac)}
                  helperText={
                    touched.Re_Enter_Ac && errors.Re_Enter_Ac
                      ? errors.Re_Enter_Ac
                      : null
                  }
                />
                <TextField
                  id="outlined-basic"
                  label="IFSC code"
                  fullWidth
                  sx={{ margin: "10px 0" }}
                  name="IFSC_code"
                  onChange={(e) => {
                    handleIFSCCodeChange(e);
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  value={values.IFSC_code}
                  error={touched.IFSC_code && Boolean(errors.IFSC_code)}
                  helperText={
                    touched.IFSC_code && errors.IFSC_code
                      ? errors.IFSC_code
                      : null
                  }
                />

                <TextField
                  id="outlined-basic"
                  label="Bank Name"
                  fullWidth
                  sx={{ margin: "10px 0" }}
                  name="Bank_Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.Bank_Name}
                  error={touched.Bank_Name && Boolean(errors.Bank_Name)}
                  helperText={
                    touched.Bank_Name && errors.Bank_Name
                      ? errors.Bank_Name
                      : null
                  }
                />
                <TextField
                  id="outlined-basic"
                  label="Branch Name"
                  fullWidth
                  sx={{ margin: "10px 0" }}
                  name="Branch_Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.Branch_Name}
                  error={touched.Branch_Name && Boolean(errors.Branch_Name)}
                  helperText={
                    touched.Branch_Name && errors.Branch_Name
                      ? errors.Branch_Name
                      : null
                  }
                />
              </Grid>

              <Box sx={{ textAlign: "center", marginTop: "10px" }}>
                <button type="submit" style={handleStyle}>
                  Submit
                </button>
              </Box>
            </form>
          </div>
        )}
      </Paper>
    </>
  );
};

export default WithdrawalForm;
