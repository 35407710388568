import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import address from '../assests/images/address-alter.jpg';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, TextField, Button, CircularProgress } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';

function Adressedit() {

  const Token = localStorage.getItem("token")

    const config = {
      headers: {
        Authorization: `Bearer ${Token}`
      }
    }
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    flat: Yup.string()
      .required('Flat number is required')
      .matches(/^[A-Za-z0-9\s]*$/, 'Invalid characters in the house/flat number')
      .min(1, 'House/Flat number must not be empty')
      .max(50, 'House/Flat number must be at most 50 characters'),
    address: Yup.string().required('Address is required').max(100, 'Address must be at most 100 characters'),
    landMark: Yup.string().required('Landmark is required').max(100, 'Landmark must be at most 100 characters'),
    pincode: Yup.string().required('PIN code is required').matches(/^\d{6}$/, 'PIN code must be exactly 6 digits'),
    district: Yup.string().required('District is Required'),
    state: Yup.string().required('State is Required'),
  });

  const formik = useFormik({
    initialValues: {
      flat: '', 
      address: '',
      district: '',
      pincode: '',
      landMark: '',
      state: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      console.log(values,"values")
      try {
        setLoading(true);
        await axios.put(`${process.env.REACT_APP_API_BASE_URL}/adress/put/${id}`, values);
        setLoading(false);
        // navigate('/address');
      } catch (error) {
        setLoading(false);
        console.error('Error updating data:', error);
      }
    }
    
  });

  useEffect(() => {
    setLoading(true);
    const userDetails = JSON.parse(localStorage.getItem("user_detail"));
    const UserId = userDetails.id;
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/adress/get/${UserId}`,)
      .then((res) => {
        const data = res.data[0];
        console.log(data)
        setLoading(false);
        formik.setValues(data);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '120px' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ marginTop: '120px', display: 'flex', justifyContent: 'center' }}>
      <Grid container spacing={3}>
        <Grid item lg={5}>
          <img src={address} alt="address.img" width={'100%'} />
        </Grid>
        <Grid item lg={5}>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              label="Flat.no"
              variant="outlined"
              fullWidth
              {...formik.getFieldProps('flat')}
              error={formik.touched.flat && Boolean(formik.errors.flat)}
              helperText={formik.touched.flat && formik.errors.flat}
              sx={{ margin: '10px 0px' }}
            />
            <TextField
              label="Street / Area"
              variant="outlined"
              fullWidth
              {...formik.getFieldProps('address')}
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address}
              sx={{ margin: '10px 0px' }}
            />
            <TextField
              label="District"
              variant="outlined"
              fullWidth
              {...formik.getFieldProps('district')}
              error={formik.touched.district && Boolean(formik.errors.district)}
              helperText={formik.touched.district && formik.errors.district}
              sx={{ margin: '10px 0px' }}
            />
            <TextField
              label="State"
              variant="outlined"
              fullWidth
              {...formik.getFieldProps('state')}
              error={formik.touched.state && Boolean(formik.errors.state)}
              helperText={formik.touched.state && formik.errors.state}
              sx={{ margin: '10px 0px' }}
            />
            <TextField
              label="LandMark"
              variant="outlined"
              fullWidth
              {...formik.getFieldProps('landMark')}
              error={formik.touched.landMark && Boolean(formik.errors.landMark)}
              helperText={formik.touched.landMark && formik.errors.landMark}
              sx={{ margin: '10px 0px' }}
            />
            <TextField
              label="PIN Code"
              variant="outlined"
              fullWidth
              {...formik.getFieldProps('pincode')}
              error={formik.touched.pincode && Boolean(formik.errors.pincode)}
              helperText={formik.touched.pincode && formik.errors.pincode}
              sx={{ margin: '10px 0px' }}
            />
            <Button type="submit" variant="contained" sx={{ mt: 2 }}>
              Save
            </Button>
          </form>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Adressedit;
