import React, { useEffect } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify';
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ReactImageMagnify from 'react-image-magnify';
import { useState } from 'react'
import offers from "../assests/images/offers.png"
import { useNavigate, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import Swal from 'sweetalert2';
import err_img from "../assests/images/error_img.png";
import {base_url} from "../utility/Url"


function Cartdetails({ handleClick }) {
  const [userToken, setUserToken] = useState()


  const navigate = useNavigate()
  const { id } = useParams();
  const [value, setValue] = useState([])


  useEffect(() => {

    async function fetchdata() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/product/productview/${id}`); // Correct the API endpoint URL
        if (response.status === 200) {
          const jsonData = response.data;
          setValue(jsonData);
        }
      } catch (err) {
        console.error(err); // Use console.error to log errors
      }
    }
    fetchdata();
  }, [])
  useEffect(() => {
    const Token = localStorage.getItem("token")
    setUserToken(Token)

  })
  const Addtocart = async (value, e) => {
    e.preventDefault();
    if (!userToken) {
      Swal.fire({
        title: "Please Login",
        imageUrl: err_img,
confirmButtonColor: "#ffbe0b",
        confirmButtonText: "Login",
        html: "Please login and continue your shopping",
        showCancelButton: false,
        imageWidth: 200,
        imageHeight: 200,
        customClass: {
          image: "swal2-image",
          popup: "swal2-popup",
        },
      }).then(() => {
        navigate("/account");
      });
    }
    else{
    const productId = value[0].id

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/addToCart/addProductToCard`, { productId }, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      toast.success("Added to Cart", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      })

    } catch (error) {
      console.error(error);
    }
  }
  };
  const dynamic ="Cart Details";
  return (
    <>
  <Helmet>
  <title> DCrown Mart | {dynamic}</title>
</Helmet>
      <div className='container cardetails mb-5'>
        <div className='row mt-5'>
          <div className='col-lg-5'>
            
            <div className='cart-image d-flex justify-content-center align-item-center'>
              <img src={`${base_url}/${value[0]?.image}`} className="img-responsive mt-3" height="80%" width="80%" />
            </div>
          </div>
          <div className='col-lg-7'>
            <div className='cart-content  mt-4'>
              <h4 className='mb-4'>{value[0]?.name}</h4>
              <p className='para-rate'><span className='me-3 rating'>4.1 <i className="fa-solid fa-star"></i></span>3,017 Ratings & 225 Reviews</p>
              <p className=''><b><span className='fs-4'>₹{value[0]?.price}</span></b>
                <span className='me-3 ms-2 gram'><del>₹122</del></span>
                <span className='me-3 off'>6% off</span>
                <span className='me-3 gram'>@35.625/250g</span>
              </p>
              <h5 className='fw-medium'>Coupons for you</h5>
              <small className='text-secondary'>Will be applied in the next step</small>
              <div className='description'>
                <div className='d-flex align-items-center'>
                  <h5 className='mt-2 mb-3'>Description</h5>
                  <img src={offers} className="img-responsive mt-0 rounded-circle" height="20%" width="20%" />
                </div>
                <p>{value[0]?.description}</p>
              </div>
            </div>

            <div className='cart-button-head'>
              <div className='cart-button mb-5' >

                <button type="submit" className="" onClick={() => { navigate('/addtocart') }}   >  <ShoppingCartIcon
                  sx={{ color: "#black", fontSize: "30px" }}
                /> Go To Basket</button>


              </div>


              <div className='cart-button basket  mb-5' >
                <button type="submit" className="" onClick={(e) => { Addtocart(value, e) }}><i className="fa-brands fa-shopify"></i> Add to Cart</button>
              </div>


            </div>
          </div>
        </div>
      </div>


    </>
  )
}

export default Cartdetails