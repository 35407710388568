import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { Navigate, json, useLocation, useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItemIcon from "@mui/material/ListItemIcon";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import Logo from "../../assests/images/LogoDM.png";// Import Pagination component
import { Helmet } from 'react-helmet';
const drawerWidth = 240;
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    })
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
}));


function Bank_details(props) {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(true);
    const container =
        window !== undefined ? () => window().document.body : undefined;
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#305931",
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        "&:last-child td, &:last-child th": {
            border: 0,
        },
    }));
    useEffect(()=>{
        const token = localStorage.getItem('auth_token')
        if (!token) {
            return navigate('/')
        }
    },[])

    const [data, setData] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const token = localStorage.getItem('auth_token')
                if (!token) {
                    return navigate('/')
                }
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/acccount`,{
                    headers: {
                        Authorization: token,
                    }
                })
                  
                if (response.status === 200) {
                    const jsonData = response.data;
                    setData(jsonData);
                }
            } catch (err) {
                console.error('Error:', err);
            }
        }

        fetchData();
    }, []);

    const theme = useTheme();
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const myprofile = () => {
        navigate("/myprofile");
    };

    const logout = () => {
        localStorage.clear();
        navigate("/");
    };

    const [record, setRecord] = useState('');
    const [sino, setSino] = useState('');
    const showdetail = async (id) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/acccountview/${id}`);
            const data = response.data[0];
            setRecord(data);
            setSino(id);
        } catch (error) {
            console.error("Error fetching data:", error.response ? error.response.data : error.message);
        }
    };
    
    const handleInputChange = (field, value) => {
        setRecord((prevRecord) => ({
            ...prevRecord,
            [field]: value,
        }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/admin/accountedit/${sino}`, record);
            if (response.status === 200) {
                window.location.reload();
            }
        } catch (error) {
            console.error(error);
        }
    };
    const dynamic ="AdminPanel";
    return (
        <Box sx={{ display: "flex" }}>
            <Helmet>
                <title> DCrown Mart | {dynamic}</title>
            </Helmet>
            <CssBaseline />
            <AppBar
                position="fixed"
                className="dashboard-bar"
                open={open}
                style={{ backgroundColor: "#FFD700" }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: "none" }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        DCrownMart
                    </Typography>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit"
                        className="logout-icon"
                        style={{ position: "absolute", right: "0" }}
                    >
                        <AccountCircle sx={{ width: 40, height: 40 }} />
                    </IconButton>

                    <Menu
                        sx={{ mt: "45px" }}
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                          
                        <MenuItem onClick={logout}>Log Out</MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                        width: drawerWidth,
                        boxSizing: "border-box",
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                        <img src={Logo} alt="Logo" width="75%" />
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === "ltr" ? (
                            <ChevronLeftIcon />
                        ) : (
                            <ChevronRightIcon />
                        )}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    <ListItem >
                        <ListItemButton onClick={() => { navigate("/admin/Dashboard") }}>
                            <ListItemIcon>
                                <h5 className="">
                                    <span className="p-1" ><i class="fa-solid fa-box"></i> Dashboard</span>
                                </h5>
                            </ListItemIcon>
                            <ListItemText />
                        </ListItemButton>

                    </ListItem>
                    <ListItem >

                        <ListItemButton onClick={() => { navigate("/admin/adminpanel") }}>
                            <ListItemIcon>
                                <h5 className="">
                                    <span className="p-1" ><i class="fa-solid fa-box"></i> Users</span>
                                </h5>
                            </ListItemIcon>
                            <ListItemText />
                        </ListItemButton>

                    </ListItem>
                    <ListItem >
                        <ListItemButton onClick={() => { navigate("/admin/level") }}>
                            <ListItemIcon>
                                <h5 className="">
                                    <span className="p-1 " ><i class="fa-solid fa-chart-simple"></i> Level Amount</span>
                                </h5>
                            </ListItemIcon>
                            <ListItemText />
                        </ListItemButton>

                    </ListItem>
                    <ListItem >
                        <ListItemButton onClick={() => { navigate("/admin/adminmembership") }}>
                            <ListItemIcon>
                                <h5 className="">
                                    <span className="p-1 " ><i class="fa-solid fa-user-plus"></i> Membership</span>
                                </h5>
                            </ListItemIcon>
                            <ListItemText />
                        </ListItemButton>

                    </ListItem>
                    <ListItem >
                        <ListItemButton onClick={() => { navigate("/admin/transaction") }}>
                            <ListItemIcon>
                                <h5 className="">
                                    <span className="p-1 " ><i class="fa-regular fa-credit-card"></i> Transaction</span>
                                </h5>
                            </ListItemIcon>
                            <ListItemText />
                        </ListItemButton>

                    </ListItem>
                    <ListItem >
                        <ListItemButton  onClick={() => { navigate("/admin/Product") }}>
                            <ListItemIcon>
                                <h5 className="">
                                    <span className="p-1 "><i class="fa-solid fa-cart-shopping"></i> Product</span>
                                </h5>
                            </ListItemIcon>
                            <ListItemText />
                        </ListItemButton>

                    </ListItem>
                    <ListItem >
                        <ListItemButton  onClick={() => { navigate("/admin/tree") }}>
                            <ListItemIcon>
                                <h5 className="">
                                    <span className="p-1" ><i class="fa-solid fa-chart-bar"></i> Tree</span>
                                </h5>
                            </ListItemIcon>
                            <ListItemText />
                        </ListItemButton>

                    </ListItem>
                    <ListItem >
                        <ListItemButton className="bg-warning" onClick={() => { navigate("/admin/Bank_details") }}>
                            <ListItemIcon>
                                <h5 className="">
                                <span className="p-1 text-white" ><i class="fa-brands fa-squarespace"></i> Account</span>
                                </h5>
                            </ListItemIcon>
                            <ListItemText />
                        </ListItemButton>

                    </ListItem>
                    <ListItem >
                        <ListItemButton  onClick={() => { navigate("/admin/Manual_entry") }}>
                            <ListItemIcon>
                                <h5 className="">
                                    <span><i class="fa-solid fa-magnifying-glass"></i> Manual_entry</span>
                                </h5>
                            </ListItemIcon>
                            <ListItemText />
                        </ListItemButton>

                    </ListItem>
                    <ListItem >
                        <ListItemButton  onClick={() => { navigate("/admin/Orderlist") }}>
                            <ListItemIcon>
                                <h5 className="">
                                    <span><i class="fa-solid fa-cart-shopping"></i> Order List</span>
                                </h5>
                            </ListItemIcon>
                            <ListItemText />
                        </ListItemButton>

                    </ListItem>
                    <ListItem >
                        <ListItemButton  onClick={() => { navigate("/admin/withdrawlTransaction") }}>
                            <ListItemIcon>
                                <h5 className="">
                                    <span><i class="fa-solid fa-money-bill-transfer"></i>Withdrawl <br></br><p className="text-center" style={{fontSize:"16px"}}>Transaction</p></span>
                                </h5>
                            </ListItemIcon>
                            <ListItemText />
                        </ListItemButton>

                    </ListItem>
                    <ListItem >
                        <ListItemButton  onClick={() => { navigate("/admin/withdrawl") }}>
                            <ListItemIcon>
                                <h5 className="">
                                <span><i class="fa-solid fa-money-bill-transfer"></i>  Withdrawl<br></br><p className="text-center" style={{fontSize:"16px"}}>User Bank Accounts</p></span>
                                </h5>
                            </ListItemIcon>
                            <ListItemText />
                        </ListItemButton>

                    </ListItem>
                    
                </List>
                <Divider />
            </Drawer>
            <Main open={open}>
                <DrawerHeader />
                <h2 className="mb-4 mt-3">Bank Details</h2>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">Name</StyledTableCell>
                                <StyledTableCell align="center">Bank</StyledTableCell>
                                <StyledTableCell align="center">Branch</StyledTableCell>
                                <StyledTableCell align="center">Account</StyledTableCell>
                                <StyledTableCell align="center">IFSC</StyledTableCell>
                                <StyledTableCell align="center">Phone</StyledTableCell>
                                <StyledTableCell align="center">Gpay</StyledTableCell>
                                <StyledTableCell align="center">Action</StyledTableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {data.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={8} align="center">
                                        No data available in this table
                                    </TableCell>
                                </TableRow>
                            ) : (
                                data.map((items) => (
                                    <StyledTableRow key={items.id}>
                                        <StyledTableCell
                                            component="th" scope="row"
                                            align="center">{items.name}</StyledTableCell>
                                        <StyledTableCell align="center">{items.bank}</StyledTableCell>
                                        <StyledTableCell align="center">{items.branch}</StyledTableCell>
                                        <StyledTableCell align="center">{items.acc_no}</StyledTableCell>
                                        <StyledTableCell align="center">{items.ifsc}</StyledTableCell>
                                        <StyledTableCell align="center">{items.phone}</StyledTableCell>
                                        <StyledTableCell align="center">
                                            {items.gpay}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <button
                                                className="btn btn-primary btn-xs"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModal"
                                                onClick={() => showdetail(items.id)}
                                            >
                                                <i className="fas fa-pencil-alt"></i>
                                            </button>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))
                            )}
                        </TableBody>

                    </Table>
                </TableContainer>
            </Main>
            <form
                onSubmit={handleSubmit}
            >
                <div class="modal fade mt-5"
                    id="exampleModal"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5 text-center" id="exampleModalLabel">Account Details</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="d-flex justify-content-around">
                                    <div className="">
                                        <label>
                                            Name
                                        </label>
                                        <input
                                            type="text"
                                            className=" w-100 mb-3 searchbar form-control"
                                            value={record.name}
                                            placeholder="Enter"
                                            onChange={(e) => handleInputChange('name', e.target.value)}
                                        />
                                    </div>
                                    <div className="">
                                        <label>
                                            Bank
                                        </label>
                                        <input
                                            type="text"
                                            className=" w-100 mb-3 searchbar form-control"
                                            value={record.bank}
                                            placeholder="Enter"
                                            onChange={(e) => handleInputChange('bank', e.target.value)}
                                        />
                                    </div>
                                </div>
                                <label>
                                    Branch
                                </label>
                                <input
                                    type="text"
                                    className=" w-100 mb-3 searchbar form-control"
                                    value={record.branch}
                                    placeholder="Enter"
                                    onChange={(e) => handleInputChange('branch', e.target.value)}
                                />
                                <label>
                                    Account
                                </label>
                                <input
                                    type="number"
                                    className=" w-100 mb-3 searchbar form-control"
                                    value={record.acc_no}
                                    placeholder="Enter"
                                    onChange={(e) => handleInputChange('acc_no', e.target.value)}
                                />
                                <label>
                                    IFSC
                                </label>
                                <input
                                    type="text"
                                    className=" w-100 mb-3 searchbar form-control"
                                    value={record.ifsc}
                                    placeholder="Enter"
                                    onChange={(e) => handleInputChange('ifsc', e.target.value)}
                                />

                                <label>
                                    Phone
                                </label>
                                <input
                                    type="number"
                                    className=" w-100 mb-3 searchbar form-control"
                                    value={record.phone}
                                    placeholder="Enter"
                                    onChange={(e) => handleInputChange('phone', e.target.value)}
                                />
                                <label>
                                    Google Pay Number
                                </label>
                                <input
                                    type="number"
                                    className=" w-100 mb-3 searchbar form-control"
                                    value={record.gpay}
                                    placeholder="Enter"
                                    onChange={(e) => handleInputChange('gpay', e.target.value)}
                                />
                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary" >Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Box>
    );
}

export default Bank_details;