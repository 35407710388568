import React, { useState } from "react";
import { Box, Grid, Paper, Typography, TextField } from "@mui/material";
import register from "../assests/images/enterpassword.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom'

import { toast } from 'react-toastify';
function Registered_email() {
  
const navigate = useNavigate();

  const { handleChange, handleBlur, touched, errors, handleSubmit, values, setFieldError } =
    useFormik({
      initialValues: {
        email: "",
      },
      validationSchema: Yup.object().shape({
        email: Yup.string()
          .matches(
            /^[a-zA-Z0-9][a-zA-Z0-9]*@[a-zA-Z0-9]+\.[a-zA-Z]{2,}$/,
            "Invalid email format"
          )

          .required("Email is required"),
      }),
      onSubmit: (values) => {
        const postDate = async () => {
          try {
            await axios.post(
             ` ${process.env.REACT_APP_API_BASE_URL}/users/forget`,
              values
              
              

            );
            Swal.fire("Link sended", " We've sent a Password Reset link to your email", "success");
          navigate("/account")
           
            

          } catch (err) {
            console.log(err, "Error");
            if(err.response && err.response.status === 404){
              setFieldError('email',"Invalid Email ")
            }
          
          }
        };
        postDate();
      },
    });
  const handleStyle = {
    backgroundColor: "#ffbe0b",
    color: "white",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    border: "none",
    width: "40%",
    padding: "10px 10px",
    fontWeight: "bold",
  };

  return (
    <Box>
      <Paper
        elevation={3}
        sx={{
          width: {
            lg: "30%",
          },
          height: "60vh",
          margin: "80px auto",
        }}
      >
        <Grid alignItems="center">
          <Box sx={{ textAlign: "center", padding: "15px" }}>
            <img src={register} alt="register" width="50%" />
            <Typography variant="h6" fontWeight="bold">
              Enter your Registered Email
            </Typography>
            <Typography variant="body1">
          
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                id="outlined-basic"
                label="Email"
                fullWidth
                sx={{ margin: "30px 0" }}
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email ? errors.email : null}
              />

               

              <Box sx={{ textAlign: "center", marginTop: "10px" }}>
                <button type="submit" style={handleStyle}>
                  Send Email
                </button>
              </Box>
            </form>
          </Box>
        </Grid>
      </Paper>
    </Box>
  );
}

export default Registered_email;