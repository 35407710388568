async function ImageUploader(file){
    const reader =new FileReader()    
    reader.readAsDataURL(file)
    const data =new Promise((resolve,reject)=>{
        reader.onload=()=>resolve(reader.result)
        reader.oneror=err=>reject(err)
    }
    )
    return data
}

export{ImageUploader}