import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { AppBar, Typography, IconButton } from "@mui/material";
import Logo from "../../assests/images/LogoDM.png";

import "./Navbar.css";

import { styled, alpha } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Badge from "@mui/material/Badge";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";

import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/material/styles/useTheme";
import Box from "@mui/material/Box";
import { Link, useNavigate } from "react-router-dom";
import { BiSolidUser } from "react-icons/bi";
import { FaLocationDot } from "react-icons/fa6";
import { FaShoppingCart, FaCoins } from "react-icons/fa";
import { RiFeedbackFill } from "react-icons/ri";
import { IoMdArrowDropdown } from "react-icons/io";
import Swal from "sweetalert2";
import err_img from "../../assests/images/error_img.png";
import { createData } from "../../routes/HomeRouter";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  // bottom:13,
  // marginTop:"10px",
  backgroundColor: "#f7f7f7",
  "&:hover": {
    backgroundColor: "#fbffd3",
  },

  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "black",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "black",
  borderRadius: "50px",

  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",

    [theme.breakpoints.up("md")]: {
      width: "70ch",
    },
  },
}));
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 3,
    top: 25,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    backgroundColor: "black",
  },
}));

function NavBar({ size, setShow, setSearchResults }, { item }) {
  const [value, setValue] = useState("");
  const [cart, setCart] = useState([]);
  const [badgeContent, setBadgeContent] = useState(0);
  const [membershipAndLevel, setMembershipAndLevel] = useState("");
  const [userDetails, setUserDetails] = useState("");
  const [profileLetter, setProfileLetter] = useState("");

  // const name = userDetails?.name?.[0] ?? "";
  // console.log(name);

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [openDrawn, setOpenDrawn] = useState(false);

  const detailAdd = userDetails?.name;
  const Token = localStorage.getItem("token");
  const { render } = useContext(createData);

  const config = {
    headers: {
      Authorization: `Bearer ${Token}`,
    },
  };
  

  async function fetchData() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/addToCart/getAddToCardProduct`,
        config
      );

      setCart(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    setUserDetails(JSON.parse(localStorage.getItem("user_detail")));
  
   
    if (!userDetails) {
      console.log("user Not Found ");
    } else {
      fetchData();
    }
  }, [render]);

  useEffect(() => {
    if (detailAdd && detailAdd.length > 0) {
      setProfileLetter(detailAdd[0]);
    }
  }, [detailAdd]);

  React.useEffect(() => {
    setMembershipAndLevel(
      userDetails?.membership_package + " " + userDetails?.package_level
    );
  }, [userDetails]);

  //theme
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  const navbar_list = {
    textDecoration: "none",
    fontSize: "18px",
    color: "black",
    fontWeight: 600,
  };

  const handleStyle = {
    padding: "13px 15px ",
    height: "50px",
    borderRadius: "30px",
    color: "black",
  };
  const navbarSign = {
    fontSize: "20px",
    fontWeight: "bold",
  };

  const navigate = useNavigate();

  const logout = () => {

   
      localStorage.clear()
      window.location.reload()

     
      navigate("/");
  
  };

  const handleStorage = () => {
    const keyValue = localStorage.getItem("user_detail");
    setValue(keyValue);
    if (!keyValue) {
      Swal.fire({
        title: "Please Login",
        imageUrl: err_img,
confirmButtonColor: "#ffbe0b",
        confirmButtonText: "Login",
        html: "Please login and continue your shopping",
        showCancelButton: false,
        imageWidth: 200,
        imageHeight: 200,
        customClass: {
          image: "swal2-image",
          popup: "swal2-popup",
        },
      }).then(() => {
        navigate("/account");
      });
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handlesearch = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/Search/searchresult`,
        { searchText: searchText }
      );
      setSearchResults(response.data);
      setSearchText("");
      navigate("/Searchproducts");
    } catch (err) {
      console.log(err, "error");
    }
  };

  return (
    <React.Fragment>
      <AppBar sx={{ background: "#FFD700" }}>
        {/* Mobile view  */}
        {isMatch ? (
          <>
            <Drawer open={openDrawn} onClose={() => setOpenDrawn(false)}>
              {/* <List> */}
              <Box sx={{ maxWidth: 280 }}>
                <List
                  style={{overflow:"hidden",
                    backgroundColor: "white",
                    margin: "0px",
                    padding: "0px"
                  }}
                >
                  <ListItemButton
                    onClick={() => setOpenDrawn(false)}
                    style={{ margin: "0px", padding: "0px", }}
                  >
                    <ListItemIcon
                      sx={{ display: "flex", flexDirection: "column" }}
                    >
                      <div
                        className="me-5"
                        style={{
                          padding: "30px 100px",
                          backgroundColor: "#ffce33",
                        }}
                      >
                        <div style={handleStyle}>
                          {profileLetter ? (
                            <h1 className="" style={{backgroundColor:'#32582e',padding:'0px 10px',    width: "47px",height:'35px',borderRadius:'6px',color:"white"}}>{profileLetter}</h1>
                          ) : (
                            <AccountCircleIcon sx={{ fontSize: "50px" }} />
                          )}
                        </div>
                        <div className="mt-3  ">
                          <h5 style={navbarSign}>
                            {" "}
                            {userDetails ? (
                              <span >{userDetails.name}</span>
                            ) : (
                              <button
                                className="btn btn-success ms-2"
                                onClick={() => {
                                  navigate("/account");
                                }}
                              >
                                Login
                              </button>
                            )}
                          </h5>
                        </div>
                      </div>
                      <li className="mt-2" style={{ padding: "10px" }}>
                        <Link to="/account/signup" style={navbar_list}>
                          {userDetails ? (
                            ""
                          ) : (
                            <>
                              {" "}
                              New Customer -{" "}
                              <span style={{ color: "blue" }}>Sign up</span>
                            </>
                          )}
                        </Link>
                      </li>
                      <li style={{ padding: "10px" }}>
                        <Link
                          to= "/myprofile" 
                          onClick={value ? undefined : handleStorage}
                          style={navbar_list}
                        >
                          <BiSolidUser /> Profile
                        </Link>
                      </li>
                      
                      <li style={{ padding: "10px" }}>
                        <Link
                          to= "/address" 
                          onClick={value ? undefined : handleStorage}
                          style={navbar_list}
                        >
                          <FaLocationDot /> My address
                        </Link>
                      </li>
                      <li style={{ padding: "10px" }}>
                        <Link
                          to= "/order" 
                          onClick={value ? undefined : handleStorage}
                          style={navbar_list}
                        >
                          <FaShoppingCart /> My Orders
                        </Link>
                      </li>

                      <li style={{ padding: "10px" }}>
                        <Link
                          to= "/feedback" 
                          onClick={value ? undefined : handleStorage}
                          style={navbar_list}
                        >
                          <RiFeedbackFill /> FeedBack
                        </Link>
                      </li>
                      {userDetails ? (
                        <a onClick={logout}>
                          <li style={{ padding: "10px" }}>
                            <Link to="/" style={navbar_list}>
                              {" "}
                              Logout
                            </Link>
                          </li>
                        </a>
                      ) : (
                        ""
                      )}
                    </ListItemIcon>
                  </ListItemButton>
                </List>
              </Box>
            </Drawer>

            <div className="row " style={{ height: "60px" }}>
              <div
                style={{
                  display: "flex",
                  marginTop: "5px",

                  justifyContent: "space-between",
                }}
              >
                <div className=" mx-auto col-8">
                  <a
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        fontFamily: "outfit ",
                        marginLeft: "20px",
                      }}
                    >
                      <img src={Logo} alt="Logo" width="75%" />
                    </Typography>
                  </a>
                </div>
                <div className="col-2">
                  <IconButton onClick={() => setOpenDrawn(!openDrawn)}>
                    {/* <AccountCircleIcon sx={{ fontSize: "30px" }} /> */}
                    {userDetails ? (
                      <div style={{backgroundColor:'#32582e',padding:'0px 10px',height:'31px',borderRadius:'6px'}}>
                        <p style={{color:"white",fontSize:"18px",marginTop:"3px"}}>
                          {userDetails.name && userDetails.name.length > 1
                            ? userDetails.name.slice(0, 1) 
                            : userDetails.name}
                        </p>
                      </div>
                    ) : (
                      <p>
                         <AccountCircleIcon sx={{ fontSize: "30px" }} /> 
                      </p>
                    )}
                  </IconButton>
                </div>

                <div className="col-2 mt-1">
                  <Link to="/addtocart" style={{ textDecoration: "none" }}>
                    <IconButton aria-label="cart">
                      <StyledBadge
                        badgeContent={cart.length}
                        sx={{ color: "white" }}
                      >
                        <ShoppingCartIcon
                          sx={{ color: "#32582e", fontSize: "30px" }}
                        />
                      </StyledBadge>
                    </IconButton>
                  </Link>
                </div>
               
              </div>
              <div className=" searchh   ">
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search…"
                    inputProps={{ "aria-label": "search" }}
                    sx={{ borderRadius: "20px" }}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyDown={(e) => e.key === "Enter" && handlesearch()}
                  />
                </Search>
              </div>
            </div>
          </>
        ) : (
          // web views  start
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexGrow: 2,
              }}
            >
              <a
                onClick={() => {
                  navigate("/");
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    // marginLeft: "30px",
                    paddingLeft: "60px",
                    paddingTop: "9px",
                    cursor: "pointer",
                  }}
                >
                  <img src={Logo} alt="Logo" width="65%" />
                </Typography>
              </a>

              <Box
                sx={{
                  paddingTop: "20px",
                  paddingBottom: "5px",
                  paddingRight: "10px",
                }}
              >
                <Search sx={{ borderRadius: "10px", marginTop: "2px" }}>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search…"
                    inputProps={{ "aria-label": "search" }}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyDown={(e) => e.key === "Enter" && handlesearch()}
                  />
                </Search>
              </Box>

              <div className="dropdown pro ">
                <button
                  className={`btn navbar-signup ${
                    isDropdownOpen ? "dropdown-open" : ""
                  }`}
                  type="button"
                  onClick={toggleDropdown}
                  id="dropdownMenuButton"
                  data-mdb-toggle="dropdown"
                  aria-expanded="false"
                >
                  {userDetails ? (
                    <span className="d-flex ">
                      <AccountCircleIcon sx={{ fontSize: "40px" }} />

                      <span className="ms-1 fw-bold">{userDetails.name}</span>
                      <span className="dropdown-icon">
                        <IoMdArrowDropdown />
                      </span>
                    </span>
                  ) : (
                    <Link to="/account" className="login d-flex">
                      <span className="me-1 fw-bold">Login</span>
                      <span className="dropdown-icon">
                        <IoMdArrowDropdown />
                      </span>
                    </Link>
                  )}
                </button>
                <ul
                  className="dropdown-menu mt-1"
                  aria-labelledby="dropdownMenuButton"
                  style={{ width: "250px", padding: "20px" }}
                >
                  <li style={{ padding: "3px" }}>
                    <Link to="/account/signup" style={navbar_list}>
                      {userDetails ? (
                        ""
                      ) : (
                        <>
                          New Customer -
                          <span style={{ color: "blue" }}>Sign up</span>
                        </>
                      )}
                    </Link>
                  </li>
                  <li style={{ padding: "10px" }}>
                    <Link
                      to="/myprofile"
                      onClick={value ? undefined : handleStorage}
                      style={navbar_list}
                    >
                      <BiSolidUser /> Profile
                    </Link>
                  </li>
                  <li style={{ padding: "10px" }}>
                    <Link
                      to="/address" 
                      onClick={value ? undefined : handleStorage}
                      style={navbar_list}
                    >
                      <FaLocationDot /> My address
                    </Link>
                  </li>
                  <li style={{ padding: "10px" }}>
                    <Link
                      to="/order" 
                      onClick={value ? undefined : handleStorage}
                      style={navbar_list}
                    >
                      <FaShoppingCart /> My Orders
                    </Link>
                  </li>

                  <li
                    onClick={value ? undefined : handleStorage}
                    style={{ padding: "10px" }}
                  >
                    <Link to="/feedback"  style={navbar_list}>
                      <RiFeedbackFill /> FeedBack
                    </Link>
                  </li>
                  {userDetails ? (
                    <a onClick={logout}>
                      <li style={{ padding: "10px" }}>
                        <Link to="/" style={navbar_list}>
                          <i class="fa-solid fa-right-from-bracket"></i> Logout
                        </Link>
                      </li>
                    </a>
                  ) : (
                    ""
                  )}
                </ul>
              </div>

              <Box
                sx={{
                  backgroundColor: "#32582E",
                  width: "13%",
                  borderRadius: "10px 0 0 40px",
                }}
              >
                <Box sx={{ padding: "10px" }}>
                  <Link to="/addtocart" style={{ textDecoration: "none" }}>
                    <Typography
                      sx={{
                        display: "flex",
                        marginTop: "15px",
                        cursor: "pointer",
                        marginLeft: "40px",
                      }}
                    >
                      <IconButton aria-label="cart">
                        <StyledBadge
                          badgeContent={cart.length}
                          sx={{ color: "white" }}
                        >
                          <ShoppingCartIcon
                            sx={{ color: "#FFD700", fontSize: "30px" }}
                          />
                        </StyledBadge>
                      </IconButton>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "18px",
                          color: "#FFD700",
                          fontWeight: "bold",
                          marginTop: "10px",
                          marginLeft: "5px",
                        }}
                      >
                        Cart
                      </Typography>
                    </Typography>
                  </Link>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </AppBar>
    </React.Fragment>
  );
}

export default NavBar;
