import React, { useState, useEffect } from "react";
import slice from "../../src/assests/images/Group 200.png";
import slice1 from "../../src/assests/images/Group 201.png";
import slice2 from "../../src/assests/images/Group 202.png";
import slice3 from "../../src/assests/images/Group 203.png";
import slice5 from "../../src/assests/images/Group 208.png";
import slice6 from "../../src/assests/images/slice1.png";
import slice7 from "../../src/assests/images/Group 209.png";
import slice8 from "../../src/assests/images/slice2.png";
import slice9 from "../../src/assests/images/Group 207.png";
import { useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import Swal from "sweetalert2";
import err_img from "../../src/assests/images/error_img.png";
import "react-multi-carousel/lib/styles.css";

function Producttype() {
  const [userDetails, setUserDetails] = useState("");

  useEffect(() => {
    setUserDetails(JSON.parse(localStorage.getItem("user_detail")));
  }, []);

  const navigate = useNavigate();

  const handleClick = (fruit) => {
    if (!userDetails) {
      Swal.fire({
        title: "Please Login",
        imageUrl: err_img,
confirmButtonColor: "#ffbe0b",
        confirmButtonText: "Login",
        html: "Please login and continue your shopping",
        showCancelButton: false,
        imageWidth: 200,
        imageHeight: 200,
        customClass: {
          image: "swal2-image",
          popup: "swal2-popup",
        },
      }).then(() => {
        navigate("/account");
      });
    } else navigate(`/Productdetails/`, { state: { fruit } });
  };

  const product = [
    {
      id: 1,
      img: slice,
    },
    {
      id: 2,
      img: slice1,
    },
    {
      id: 3,
      img: slice3,
    },
   
    {
      id: 5,
      img: slice5,
    },
    {
      id: 6,
      img: slice6,
    },
    {
      id: 7,
      img: slice7,
    },
    {
      id: 8,
      img: slice8,
    },
  ];

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  return (
    <>
      <div className="container producttype-allcarousel">
        
        <div className="row mt-3">
          <div className="col-3">
            <img
              src={slice}
              onClick={() => handleClick("Dairy ,bread & eggs")}
              className="product_details "
            />
            </div>
            <div className="col-3">
        
            <img
              src={slice1}
              onClick={() => handleClick("Fruit & vegitables")}
              className="product_details"
            />
            </div>
              <div className="col-3">
            <img
              src={slice2}
              onClick={() => handleClick("Cold Drinks & juices")}
              className="product_details"
            />
          </div>
          <div className="col-3">
          <img
              src={slice3}
              onClick={() => handleClick("Snacks & Munchies")}
              className="product_details"
            />
          </div>
          </div>
        <div className="row mt-3">

          <div className="col-3">
          <img src={slice6}
          onClick={() => handleClick("Atta,Rice & Dal")}
        
        className="product_details" alt="..." />
            </div>
          <div className="col-3">


            <img
              src={slice5}
              onClick={() => handleClick("Bakery & Biscutes")}
              className="product_details"
            />
            </div>
          <div className="col-3">
          <img
              src={slice7}
              onClick={() => handleClick("Tea,Coffee &Health drink")}
              className="product_details"
            />
          </div>
          <div className="col-3">
          <img
              src={slice9}
              onClick={() => handleClick("Masala,oil & More")}
              className="product_details"
            />
          
          </div>


        {/* <div className="row">

          <div className="col-4">
           
            <img src={slice8} className="product_details" />
          
          </div>
        </div> */}
      </div>
      </div>


      <div className="mt-5  productType-details ">
      <img
          src={slice9}
          onClick={() => handleClick("Masala,oil & More")}
          className="card-img-top p-2"
          alt="..."
        />
     
       
        <img
          src={slice2}
          onClick={() => handleClick("Cold Drinks & juices")}
          className="card-img-top p-2"
          alt="..."
        />
        <img
          src={slice3}
          onClick={() => handleClick("Snacks & Munchies")}
          className="card-img-top p-2"
          alt="..."
        />
        <img
          src={slice5}
          onClick={() => handleClick("Bakery & Biscutes")}
          className="card-img-top p-2"
          alt="..."
        />
        <img src={slice6}
          onClick={() => handleClick("Atta,Rice & Dal")}
        
        className="card-img-top p-2" alt="..." />
           <img src={slice} 
              onClick={() => handleClick("Dairy ,bread & eggs")}
              className="card-img-top p-2" alt="..." />
        <img
          src={slice7}
          onClick={() => handleClick("Tea,Coffee &Health drink")}
          className="card-img-top p-2"
          alt="..."
        />
         <img
          src={slice1}
          onClick={() => handleClick("Fruit & vegitables")}
          className="card-img-top p-2"
          alt="..."
        />
        <img src={slice8} className="card-img-top p-2"
          onClick={() => handleClick("Cleaning & Essentials")}

    alt="..." />
        
      </div>
    </>
  );
}

export default Producttype;
