import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Navbar from "../component/Navbar/NavBar";
import Footer from "../component/Footer/Footer";
import Home from "../pages/Home";
import axios from "axios";
import PageNotFound from "../component/PageNotFound";
import Cartdetails from "../pages/Cartdetails";

import Level from "../pages/admin/Level";
import Feedback from "../pages/Feedback";
import Order from "../pages/Order";
import Anotheraddress from "../pages/Anotheraddress";
import Address from "../pages/Address";
import My_profile from "../pages/My_profile";
import MemberShip from "../pages/MemberShip";
import CheckOut from "../pages/checkout";
import OrderPlaced from "../pages/orderPlaced";

import Addtocart from "../pages/slider/Addtocart";
import { createContext } from "react";
import Upgrade_membership from "../pages/Upgrade_membership";
import Payment from "../pages/Payment";

import Withdraw from "../pages/Withdrawl/WithdrawalForm ";
import WithdrawHistory from "../pages/Withdrawl/WithdrawHistory";


import EnterWithdrawAmount from "../pages/Withdrawl/EnterWithdrawAmount";
import Giftvocher from "../pages/Giftvocher";
import Productdetails from "../pages/slider/Productdetails";
import { useNavigate } from "react-router-dom";
import Adressedit from "../pages/adressedit";
import Placebooking from "../pages/Placebooking";
import Searchproducts from "../pages/slider/Searchproducts";
import Terms from "../pages/Terms";
import Privacypolicy from "../pages/Privacypolicy";
import About from "../pages/About";
import ShippingPolicy from "../pages/shippingPolicy";
import ReturnRefund from "../pages/returnRefundPolicy";





import Superdeal from "../pages/MembershipTree/superDeal";
import Kingdom from "../pages/MembershipTree/kingdom";

import CrownKingdom from "../pages/MembershipTree/crownKingdom";

import RoyalCrownKingdom from "../pages/MembershipTree/royalCrownKingdom";
import PaymentStatus from "../pages/paymentStatus";



export const createData = createContext(null);
export default function HomeRouter() {
  const [cart, setCart] = React.useState([]);
  const navigate = useNavigate();

  //Render
  const [render,setRender]=useState(0)
  //Loaders
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

 
  const [userDetails, setUserDetails] = useState("");
  const Token = localStorage.getItem("token");

  const config = {
    headers: {
      Authorization: `Bearer ${Token}`,
    },
  };

  async function fetchData() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/addToCart/getAddToCardProduct`,
        config
      );

      setCart(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  React.useEffect(() => {
    setUserDetails(JSON.parse(localStorage.getItem("user_detail")));
  
   
    if (!userDetails) {
      console.log("user Not Found ");
    } else {
      fetchData();
    }

  }, [cart]);

  const handleClick = (value) => {
    let present = false;
    cart.forEach((product) => {
      if (value.id === product.id) {
        present = true;
      }
    });

    if (present) {
      return;
    }
    setCart([...cart, value]);
  };
  const handleChange = (item, d) => {
    let ind = -1;
    cart.forEach((data, index) => {
      if (data.id === item.id) ind = index;
    });

    const tempArr = [...cart];
    tempArr[ind].amount += d;

    if (tempArr[ind].amount <= 0) {
      tempArr[ind].amount = 1;
    }
    setCart(tempArr);
  };

  const [searchResults, setSearchResults] = useState([]);

  return (
    <>
      <createData.Provider value={{ cart, setCart, handleChange,render,setRender }}>
      <Navbar setSearchResults={setSearchResults} size={cart.length} />
        <Routes>
          <Route path="/" element={<Home handleClick={handleClick} />} />
          
          <Route path="/addtocart" element={<Addtocart />} />
          <Route extact path="/feedback" element={<Feedback />} />
          <Route extact path="/order" element={<Order />} />
          <Route extact path="/address" element={<Address />} />
          <Route extact path="/adressedit/:id" element={<Adressedit />} />
          <Route extact path="/anotheraddress" element={<Anotheraddress />} />
          <Route exact path="/myprofile" element={<My_profile />} />
         {/* Membership tree */}

         <Route exact path="/superdealTree" element={<Superdeal />} />
         <Route exact path="/kingdomTree" element={< Kingdom/>} />

         <Route exact path="/crownKingdomTree" element={<CrownKingdom />} />

         <Route exact path="/royalCrownKingdomTree" element={< RoyalCrownKingdom/>} />



          <Route path="/referral" element={<MemberShip />} />
          <Route
            extact
            path="/cartdetails/:id"
            element={<Cartdetails handleClick={handleClick} />}
          />
          <Route extact path="/Level" element={<Level />} />
          {/* <Route extact path="/anotheraddress/*" element={<Anotheraddress />} /> */}
          <Route
            exact
            path="/upgrademembership"
            element={<Upgrade_membership />}
          />
          
          <Route extact path="/checkOut" element={<CheckOut />} />
          <Route extact path="/withdraw" element={<Withdraw />} />
          <Route extact path="/withdrawHistory" element={<WithdrawHistory />} />

          
          <Route extact path="/policy" element={<Privacypolicy />} />
          
    <Route path="/payment" element={<Payment />} />
          <Route extact path="/orderPlaced" element={<OrderPlaced />} />

          <Route
            extact
            path="/EnterWithdrawAmount"
            element={<EnterWithdrawAmount />}
          />
          <Route extact path="/term" element={<Terms />} />
          <Route extact path="/about" element={<About />} />
          <Route extact path="/return&refund" element={<ReturnRefund />} />
          <Route extact path="/shippingPolicy" element={<ShippingPolicy />} />



          <Route path="/giftvocher" element={<Giftvocher />} />
          <Route path="/Productdetails" element={<Productdetails />} />
          <Route path="/placebooking" element={<Placebooking />} />
          <Route
            path="/Searchproducts"
            element={<Searchproducts products={searchResults} />}
          />
           <Route
            exact
            path="/paystatus"
            element={<PaymentStatus />}
          />
          <Route path="/*" element={<PageNotFound />} />
        </Routes>
      </createData.Provider>
      <Footer />
    </>
  );
}
