// WithdrawalForm.js

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";

import Swal from "sweetalert2";

import { toast } from "react-toastify";
import * as Yup from "yup";
import { Grid, Paper, Typography, TextField, Box } from "@mui/material";

const EnterWithdrawAmount = () => {
  const [amount, setAmount] = useState("");
  const [accountDetails, setAccountDetails] = useState({});
  const navigate = useNavigate();

  // const [fundId, setFundId] = useState('')
  const [loading, setLoading] = useState(false);
  const Token = localStorage.getItem("token");
  const Data = JSON.parse(localStorage.getItem("user_detail"));
  const UserID = Data.id;


  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/withdrawl/getAccountDetials`,
        {
          params: { UserID },
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        const { AccountId, wallet } = res.data;
        const Wallet = wallet[0].wallet;
        const accountDetails = AccountId[0];
        console.log(accountDetails, "accountDetails");
        setAccountDetails(accountDetails);
        setAmount(Wallet);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching Account ID:", error);
      });
    console.log(amount, "amount");
  }, [])


  const {
    setSubmitting,
    setFieldError,
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: {
      Enter_the_Amount: "",
    },
    validationSchema: Yup.object().shape({
      Enter_the_Amount: Yup.number()
        .typeError("Enter a valid Amount")
        .required("Amount is required")
        .min(500, `please enter more than 500`),
    }),

    // onSubmit: (values) => {
    //   setLoading(true)
    //   axios.post(`${process.env.REACT_APP_API_BASE_URL}/withdrawl/payoutsCreate`, { fundId, values })
    //     .then(response => {
    //       setLoading(false)
    //       toast.success("Account created Successfully", {
    //         position: toast.POSITION.TOP_RIGHT,
    //         autoClose: 600,
    //       });

    //     })
    //     .catch(error => {
    //       setLoading(false)
    //       if (error.response && error.response.status === 409) {
    //         setFieldError('email', 'Email already exists');
    //         toast.error("Email already exists", {
    //           position: toast.POSITION.TOP_RIGHT,
    //           autoClose: 500,
    //         });
    //       } else {
    //         console.error('Error submitting form:', error);
    //       }
    //     });
    // }

    onSubmit: (values) => {
      const enteredAmount = parseInt(values.Enter_the_Amount, 10)


      if (enteredAmount > amount) {
     
        setFieldError("Enter_the_Amount","Please enter an amount within your available wallet.")

        return;
      }
      // if (amount && enteredAmount > amount - 500) {
      
      //   setFieldError("Enter_the_Amount","Cannot withdraw. Maintaining a minimum balance of ₹500 is required. You can withdraw   " +
      //      `₹${amount - 500} only` +
      //      ".",)
      //   return;
      // }

      console.log(accountDetails, "accountDetails")
      try {
        axios
          .post(
            `${process.env.REACT_APP_API_BASE_URL}/withdrawl/withdrawlTransaction`,
            { values, UserID, accountDetails }
          )

          .then((response) => {
            Swal.fire(
              "Success!",
              "Thank you for submitting your withdrawl details and Amount. We're in the process of verifying the information you provided. Approval might take a little time.",
              "success"
            ).then(() => {
              setTimeout(() => {
                setLoading(false);

                navigate("/myprofile");
              }, 1000);
            });
            console.log(response, "response");
          })
          .catch((err) => {
            console.log(err.message);
          });
      } catch (error) {
        console.log(error.message);
      }
    },
  });
  
  const acutalAmountRecieved = parseFloat(values.Enter_the_Amount) - parseFloat(values.Enter_the_Amount) * 0.03;

  const handleStyle = {
    backgroundColor: "#ffbe0b",
    color: "white",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    border: "none",
    width: "40%",
    padding: "10px 10px",
    fontWeight: "bold",
  };

  const config = {
    headers: {
      Authorization: `Bearer ${Token}`,
    },
  };

  if (loading) {
    return (
      <div className="loading">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    );
  }
  

  return (
    <Paper
      elevation={2}
      sx={{
        // padding: "20px",
        width: {
          lg: "30%",
        },

        margin: "110px auto",
      }}
    >
      <Box
        className="p-3"
        sx={{
          textAlign: "center",
          backgroundColor: "#ffc107",
          color: "black",
          marginBottom: "10px",
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Withdraw
        </Typography>
      </Box>

      <form onSubmit={handleSubmit}>
        <Grid className="p-5">
          <Box
            sx={{ textAlign: "center", marginBottom: "50px", marginTop: "0px" }}
          >
            {amount <= 500 ? (
              <div>
                <Typography variant="h6" sx={{ fontWeight: "bold",color: "#588157" }}>
                <i class="fa-solid fa-wallet"></i>  Wallet Amount :₹{amount}
                </Typography>
                <br></br>
                <p
                  className=" p-1"
                  style={{ color: "red", backgroundColor: "#edebeb" }}
                >
                  Cannot withdraw due to insufficient funds in your wallet .
                </p>
              </div>
            ) : (
              
              <Typography variant="h6" sx={{ fontWeight: "bold",color: "#588157"  }}>
               <i class="fa-solid fa-wallet"></i> Wallet Amount :₹{amount}
              </Typography>
            )}
          </Box>
        <Grid>
            

            <TextField
              id="outlined-basic"
              label="Enter the Amount"
              fullWidth
              sx={{ margin: "5px 0" }}
              name="Enter_the_Amount"
              placeholder="₹"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.Enter_the_Amount}
              error={
                touched.Enter_the_Amount && Boolean(errors.Enter_the_Amount)
              }
              helperText={
                touched.Enter_the_Amount && errors.Enter_the_Amount
                  ? errors.Enter_the_Amount
                  : null
              }
            />
          </Grid>
          <hr>
          </hr>
          <b>
              {" "}
              <i
                style={{ color: "#588157" }}
                class="fa-solid fa-money-bill"
              ></i>{" "}
              Actual amount you received : {values.Enter_the_Amount? `₹${acutalAmountRecieved}`:"0"}
            </b>
          <hr></hr>

          <p>
              {" "}
              <i
                style={{ color: "#dd6464" }}
                class="fa-solid fa-circle-exclamation"
              ></i>{" "}
              Minimum withdraw Amount is ₹500
            </p>
            <p>
              {" "}
              <i
                style={{ color: "#dd6464" }}
                class="fa-solid fa-circle-exclamation"
              ></i>{" "}
              Withdraw will be charged with 3.0% Payment channel fee
            </p>
            <p>
              {" "}
              <i
                style={{ color: "#dd6464" }}
                class="fa-solid fa-circle-exclamation"
              ></i>{" "}
           To Protect Your fund ,we limit every account could only withdraw 3 times per day
            </p>
        </Grid>
        {amount <= 500 ? (
          <Box
            sx={{
              textAlign: "center",
              marginTop: "6px",
              paddingBottom: "15px",
            }}
          >
            <button type="submit" disabled style={handleStyle}>
              Submit
            </button>
          </Box>
        ) : (
          <Box
            sx={{
              textAlign: "center",
              marginTop: "6px",
              paddingBottom: "15px",
            }}
          >
            <button type="submit" style={handleStyle}>
              Submit
            </button>
          </Box>
        )}
      </form>
    </Paper>
    //         <div  className=" d-flex justify-content-center " style={{ marginTop: '200px' }}>

    //           <div className="">

    //             <h3 className='text-center' >
    //                WITHDRAW
    //             </h3>
    //             <form onSubmit={handleWithdrawal}  style={{ marginTop: '40px' }} >

    //               <h5 className='text-center'>Wallet Amount : ₹ {UserDetails.wallet}</h5>

    //               <div className="mb-4 mt-4 inputWidthIcon w-100">
    //                 <input
    //                   type="text"
    //                   placeholder=' ₹ Enter Your Amount
    // '
    //                   className="form-control Form-input"
    //                   value={userData.Account_Holder_Name}
    //                   name="Enter_Your_Amount"
    //                   onChange={(e) => setUserData({ ...userData, Enter_Your_Amount: e.target.value })}
    //                   required
    //                 />
    //               </div>

    //               <div className="col-4 mx-auto sign-up mt-2">
    //                 <button className="btn" type="submit">
    //                   Submit
    //                 </button>
    //               </div>
    //             </form>
    //           </div>
    //         </div>
  );
};

export default EnterWithdrawAmount;
