import React from "react";
import { Route, Routes } from "react-router-dom";
import AdminLogin from "../pages/admin/adminLogin.js";
import Adminpanel from "../pages/admin/adminpanel";
import Adminedit from "../pages/admin/Adminedit";
import Adminview from "../pages/admin/Adminview";
import Level from "../pages/admin/Level.js";
import Transaction from "../pages/admin/Transaction.js";
import Adminmembership from "../pages/admin/adminmembership.js";
import ProductAdd from "../pages/admin/newProductAdd";
import Tree from "../pages/admin/Tree.js"
import Bank_details from "../pages/admin/Bank_details.js";
import Product from "../pages/admin/Product.js";
import Dashboard from "../pages/admin/Dashboard.js"
import Productedit from "../pages/admin/Productedit.js";
import Manual_entry from "../pages/admin/manual_entry"
import Orderlist from "../pages/admin/Orderlist.js"
import Withdrawl from "../pages/admin/Withdrawl.js"
import WithdrawlTransaction from "../pages/admin/withdrawlTransaction.js"


function AccountRouter() {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<AdminLogin />} />
        <Route extact path="/adminpanel" element={<Adminpanel />} />
        <Route extact path="/Dashboard" element={<Dashboard />} />
        <Route extact path="/adminpanel/edit/:id" element={<Adminedit />} />
        <Route extact path="/adminpanel/view/:id" element={<Adminview />} />
        <Route extact path='/transaction' element={<Transaction />} />
        <Route extact path='/level' element={<Level />} />
        <Route extact path='/productAdd' element={<ProductAdd />} />
        <Route extact path='/tree' element={<Tree />} />
        <Route extact path='/Bank_details' element={<Bank_details />} />
        <Route extact path='/Product' element={<Product />} />
        <Route extact path='/Product/Productedit/:id' element={<Productedit />} />
        <Route extact path='/adminmembership' element={<Adminmembership />} />
        <Route extact path='/Manual_entry' element={<Manual_entry />} />
        <Route extact path='/Orderlist' element={<Orderlist />} />
        <Route extact path='/Withdrawl' element={<Withdrawl />} />
        <Route extact path='/withdrawlTransaction' element={<WithdrawlTransaction />} />

        
      </Routes>
    </>
  );
}

export default AccountRouter;
