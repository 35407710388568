import {
  Box,
  Grid,
  Paper,
  Typography,
  FormControl,
  InputAdornment,
  InputLabel,
  Input,
  IconButton,
  FormHelperText,
} from "@mui/material";
import React from "react";
import register from "../assests/images/enterpassword.png";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useFormik } from "formik";
import { useParams, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Swal from "sweetalert2";

import axios from "axios";
import { toast } from 'react-toastify';
import { useState } from "react";
function Enternewpassword() {
  const [showPassword, setShowPassword] = React.useState(false);
  const param = useParams();
  const [show, setShow] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShow = () => setShow((show) => !show);

  const handleMouse = (event) => {
    event.preventDefault();
  };
  const handleStyle = {
    backgroundColor: "#ffbe0b",
    color: "white",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    border: "none",
    width: "40%",
    padding: "10px 10px",
    fontWeight: "bold",
  };
  const { handleBlur, handleChange, values, errors, touched, handleSubmit } =
    useFormik({
      initialValues: {
        password: "",
        confirm_password: "",
      },
      validationSchema: Yup.object().shape({
        password: Yup.string()
          .min(8, "Password must be 8 characters long")
          .matches(/[0-9]/, "Password requires a number")
          .matches(/[a-z]/, "Password requires a lowercase letter")
          .matches(/[A-Z]/, "Password requires an uppercase letter")
          .matches(/[^\w]/, "Password requires a symbol")
          .required("Please enter new password"),
        confirm_password: Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required("Confirm Password is required"),
      }),
      onSubmit: (values) => {
        setLoading(true)
        axios
          .patch(`${process.env.REACT_APP_API_BASE_URL}/users/reset/${param.token}`, {
            password: values.password,
          })
          .then((res) => {
            Swal.fire("Success", " Your password has been successfully changed","success");
            
            console.log(res.data, "Success");
            setLoading(true)
            navigate('/account')
          })
        setLoading(false)
          .catch((err) => console.log(err, "Error in Api"));
      },
    });
  if (loading) {
    return (
      <div className="loading">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    )
  }
  return (
    <Box>
      <Paper
        elevation={3}
        sx={{
          width: {
            lg: "30%",
          },
          margin: "50px auto",
          height: "70vh",
        }}
      >
        <Grid alignItems="center">
          <Box textAlign="center" sx={{ padding: "50px" }}>
            <img src={register} alt="register_img" width="40%" />
            <Typography variant="h6" fontWeight="bold" color="#ffbe0b">
              Enter New Password
            </Typography>
            <form onSubmit={handleSubmit}>
              <FormControl
                sx={{ margin: "20px 0" }}
                label="fullWidth"
                fullWidth
                error={touched.password && Boolean(errors.password)}
              >
                <InputLabel htmlFor="standard-adornment-password">
                  Password
                </InputLabel>
                <Input
                  id="standard-adornment-password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {errors.password && touched.password ? (
                  <FormHelperText style={{ color: "red" }}>
                    {errors.password}
                  </FormHelperText>
                ) : null}
              </FormControl>
              <FormControl
                sx={{ margin: "20px 0" }}
                label="fullWidth"
                fullWidth
                error={
                  touched.confirm_password && Boolean(errors.confirm_password)
                }
              >
                <InputLabel htmlFor="standard-adornment-password">
                  Confirm Password
                </InputLabel>
                <Input
                  id="standard-adornment-password"
                  type={show ? "text" : "password"}
                  name="confirm_password"
                  value={values.confirm_password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShow}
                        onMouseDown={handleMouse}
                      >
                        {show ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {errors.confirm_password && touched.confirm_password ? (
                  <FormHelperText style={{ color: "red" }}>
                    {errors.confirm_password}
                  </FormHelperText>
                ) : null}
              </FormControl>
              <Box sx={{ textAlign: "center", marginTop: "30px" }}>
                <button type="submit" style={handleStyle}>
                  Submit
                </button>
              </Box>
            </form>
          </Box>
        </Grid>
      </Paper>
    </Box>
  );
}

export default Enternewpassword;