import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import AppRouter from "./routes/AppRouter";

function App() {
  
  return (
    <div>
       
      <AppRouter />
      <ToastContainer />
    </div>
  );
}
export default App;