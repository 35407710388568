import React, { useState, useEffect } from 'react';
import notifiction from '../assests/images/shopping2.gif'
import { useNavigate } from 'react-router-dom'
import Adressedit from './adressedit';
import axios from "axios"
import { Helmet } from 'react-helmet';
function Address() {
  const Token = localStorage.getItem("token")


  const config = {
    headers: {
      Authorization: `Bearer ${Token}`
    }
  }
  const navigate = useNavigate();
  const [openMenuIndex, setOpenMenuIndex] = useState(-1);
  const [value, setValue] = useState([]);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    setLoading(true);
    const userDetails = JSON.parse(localStorage.getItem("user_detail"));
      const UserId = userDetails.id;
    const fetchData = async () => {
      
  
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/adress/get/${UserId}`);
        setLoading(false);
        
        if (response.status !== 200) {
          throw new Error('Network response was not ok');
        }
        
        const data = response.data;
        console.log(data, "data");
        setValue(data);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    fetchData();
  }, []);
  
  

  useEffect(() => {
    const closeMenuOnClickOutside = (event) => {
      const menuIcons = document.querySelectorAll(".menu-icon");
      if (!Array.from(menuIcons).some((icon) => icon.contains(event.target))) {
        setOpenMenuIndex(-1);
      }
    };
    document.addEventListener('click', closeMenuOnClickOutside);
    return () => {
      document.removeEventListener('click', closeMenuOnClickOutside);
    };
  }, []);

  const toggleMenu = (index) => {
    if (openMenuIndex === index) {
      setOpenMenuIndex(-1);
    } else {
      setOpenMenuIndex(index);
    }
  };


  const handledelete = (id) => {
    axios.delete(`${process.env.REACT_APP_API_BASE_URL}/adress/delete/${id}`)
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.error('Error deleting record:', error);
      })
  }
  const dynamic = "Adress";
  if (loading) {
    return (
        <div className="loading">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    )
}
  return (
    <div>
      <Helmet>
        <title> DCrown Mart | {dynamic}</title>
      </Helmet>
      {
        value.length === 0 ? (
          <div className='container-fluid ' style={{ marginTop: '80px' }}>

            <div className='order-page'>
              <img src={notifiction} width="100%" />

            </div>
            <div className='d-flex justify-content-center '>
              <h4 className='fs-2 fw-bold' style={{ color: '#545557' }}> Address not found !!!</h4>
            </div>
            <div className=" col-4 mx-auto sign-up mt-5">
              <button className="btn" type="submit" onClick={() => navigate('/anotheraddress')}>Add New address</button>

            </div>
          </div>
        ) : (
          <div className='container address-view w-75' style={{ marginTop: '120px' }}>
            <div className='manage-add mb-3'>
              <h4 className='p-2'>Manage Address</h4>
              <button className="btn btn-secondary" type="submit" onClick={() => navigate("/anotheraddress")}>+ Add Address</button>
            </div>
            <div className='addres-detail'>
              {value.map((item, index) => (
                <div className='row p-2 for-under'>
                  <div className='col-lg-8'>
                    <h5>No.{item.flat}</h5>
                    <h5>{item.address}</h5>
                    <p>{item.landMark}</p>
                    <p>{item.district}</p>
                    <p>{item.state}</p>
                    <p>{item.pincode}</p>
                  </div>
                  <div className='col-lg-4'>
                    <div className='float-end'>
                      <div className="three-dot-menu">
                        <div className="menu-icon" onClick={() => toggleMenu(index)}>
                          <div className={`dot ${openMenuIndex ? 'active' : ''}`}></div>
                          <div className={`dot ${openMenuIndex ? 'active' : ''}`}></div>
                          <div className={`dot ${openMenuIndex ? 'active' : ''}`}></div>
                        </div>
                        {openMenuIndex === index && (
                          <ul className="menu-options">
                            <li
                              onClick={() => navigate(`/adressedit/${item.id}`)}
                            >Edit</li>
                            <li
                              onClick={() => handledelete(item.id)}
                            >Delete</li>
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )
      }



    </div>
  )
}

export default Address