import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

function Checkout() {

  const [openMenuIndex, setOpenMenuIndex] = useState(-1);
  const [value, setValue] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [userToken, setUserToken] = useState([]);
  const [productId, setProductId] = useState([]);


  const [loading, setLoading] = useState(false);
  // let detail=true
  const [detail, setDetail] = useState(true);
  const Token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${Token}`,
    },
  };




  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/adress/get`,config);
        if (!response.ok) {
          throw new Error("Network response  was not ok");
        }
        const data = await response.json();
        setLoading(false)
        setValue(data);
      } catch (error) {
        setLoading(false)
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    const closeMenuOnClickOutside = (event) => {
      const menuIcons = document.querySelectorAll(".menu-icon");
      if (!Array.from(menuIcons).some((icon) => icon.contains(event.target))) {
        setOpenMenuIndex(-1);
      }
    };
    document.addEventListener("click", closeMenuOnClickOutside);
    return () => {
      document.removeEventListener("click", closeMenuOnClickOutside);
    };
  }, []);

  const location = useLocation();
  const cart = location.state.cartData;
  const price = location.state.price;

  const extractedProductIds = cart.map(item => item.productId);
  useEffect(() => {
    setDetail(true);
    setProductId(extractedProductIds)

    console.log(productId,"yokesh")
  }, [value]);

  const handleOpenRazorpay = (data, selectedValue, selectedProductId) => {
    const options = {
      key: "rzp_test_momglBzxCVKXbZ",
      amount: Number(data.amount), 
      currency: data.currency,
      order_id: data.id,
      created_at: data.created_at,
      Date: data.date,
      time: data.time,

      name: "DcrownMart",
      description: "Refer and earn",
      billing_address: {
        flat: selectedValue.flat,
        address: selectedValue.address,
        landMark: selectedValue.landMark,
        district: selectedValue.district,
        state: selectedValue.state,
        pincode:selectedValue.pincode,
      },
   
      productIds:selectedProductId,
      handler: function (response) {
        axios
          .post(`${process.env.REACT_APP_API_BASE_URL}/productOrders/verify`, { response })
          .then((response) => {
            if (response.status === 200) {

              console.log(response,"verifyk      sadas jsdhajksdhkjashdkas")
              const order_id = data.id;
             const  billing_address= {
              flat: selectedValue.flat,
              address: selectedValue.address,
              landMark: selectedValue.landMark,
              district: selectedValue.district,
              state: selectedValue.state,
              pincode:selectedValue.pincode,
            }
            const  shipping_address= {
              flat: selectedValue.flat,
              address: selectedValue.address,
              landMark: selectedValue.landMark,
              district: selectedValue.district,
              state: selectedValue.state,
              pincode:selectedValue.pincode,
            }



              const Date = data.date;

              const Time = data.time;

              console.log(order_id, Date, Time,billing_address, "order_id");

              axios
                .post(
                  `${process.env.REACT_APP_API_BASE_URL}/productOrders/ordersDetails`,
                  { order_id, Date, Time, billing_address,shipping_address,productId:selectedProductId,price},config
                )
                .then((res) => {
                  console.log(res, "orderList");
                  navigate("/orderPlaced",{ state: { cartData: cart, price: price ,order_id:order_id,Date:Date,Time:Time,billing_address:billing_address,shipping_address:shipping_address} });
                })
                .catch((err) => {               
                  console.log(err);
                });
            } else {
              console.error(
                "Failed to remove product. Server responded with status:",
                response.status
              );
            }
          })

          .catch((err) => {
            console.log(err);
          });
      },
    };

    console.log(options,"options")

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  useEffect(() => {
    const Token = localStorage.getItem("token");
    setUserToken(Token);
  });

  const handlePayment = async (amount,value,productId,e) => {
    e.preventDefault();



  
    const data = { amount: amount };
    const selectedValue = value.find((item) => item.isChecked); 
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/productOrders/orders`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        console.log(res, "created Date");

        handleOpenRazorpay(res.data.data, selectedValue, productId);
      })
      .catch((err) => {
        console.log(err, "error in order send ");
      });
  };

 

  const handleCheck = (item) => {
    setValue(value.map((data) => ({ ...data, isChecked: data.id === item })));
    setIsChecked(!isChecked)
  }

  if (loading) {
    return (
      <div className="loading">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    )
  }



  

  return (
    <div className="container" style={{ marginTop: "130px" }}>
      <div className="row">
        <div className="col-lg-7 col-12 ">
          <div
            className="container address-view"
            style={{ marginTop: "10px" }}
          >
            <div className="manage-add mb-3">
              <h4 className="p-2">Manage Address</h4>
              <button
                className=""
                type="submit"
                onClick={() => navigate("/anotheraddress")}
              >
                + Add New Address
              </button>
            </div>
            <div className="addres-detail">
              {value.map((item) => (
                <div className="row  for-under" key={item.id}>
                  <div className="col-lg-8 col-12">
                    
                    <input type="checkbox" value={item.id}
                      checked={item.isChecked || false}
                      onChange={() => handleCheck(item.id)} />
                      <div className="">
                    <p>No.{item.flat} ,
                     
                      {item.address},
                 
                  <br>
                  </br>
                     <span> {item.district} - {item.pincode}</span>,
                    
                      {item.landMark} 
<br></br>
                 
                      {item.state}
                      
                    
                    </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="col-lg-5 col-12  h-25 shadow position-sticky top-0 mt-1 pt-3">
          <div>
            <div className="">
              <h4>Your Orders</h4>
            </div>
            <hr></hr>
            <div className="d-flex justify-content-evenly mt-4">
              <b>
                <h5>Products </h5>
              </b>
              <b>
                {" "}
                <h5>SubTotal</h5>
              </b>
            </div>
            <hr></hr>
            {cart.map((item) => (
              <div className=" mt-3">
                <ul className="d-flex list justify-content-evenly p-1">
                  <li>
                    {item["productlist.name"]} × {item.quantity}
                  </li>{" "}
                  -
                  <b>
                    <li>₹{item["productlist.price"]}.00</li>
                  </b>
                </ul>
              </div>
            ))}
            <hr></hr>
            <div className="d-flex justify-content-around mt-3">
              <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                {" "}
                Total Amount
              </p>
              <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                ₹{price}.00
              </p>
            </div>
              <div className="cart-button  mt-3 mb-2">
                <button
                  type="submit"
                  className=""
                  disabled={!isChecked}
                  onClick={(e) => {
                    handlePayment(price,value,productId,e);
                  }}
                >
                  Place Order
                </button>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Checkout;
