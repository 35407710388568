
import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

function PaymentStatus() {

//   const localStorageitmes = JSON.parse(localStorage.getItem('user_detail'))
  //  const membership_package = localStorageitmes.membership_package;
   // const id = localStorageitmes.id;
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const status = searchParams.get('status');
    console.log(status)
    const navigate = useNavigate();
    return (
        <div className='payment-container'>
            <div className='row d-flex justify-content-center' style={{ width: '100%' }}>
                {
                    status == 'success' ? (<div className="centerContent">
                        <div className="paymentSuccessMessage alert alert-success" role="alert">
                            Payment successful!! Thanks for upgrading your membership package.
                        </div>
                        <div className="centerContent">
                            <Button className="btn btn-warning text-white " onClick={() => {
                                navigate("/myprofile");
                            }} variant="primary">
                                View Profile
                            </Button>
                        </div>
                    </div>) : status == 'PAYMENT_PENDING' ? (<div className="centerContent">
                        <div className="paymentPendingMessage alert alert-warning" role="alert">
                            Payment is pending. Please be patient and contact the admin if necessary.
                        </div>
                        <Button className="btn btn-warning text-white " onClick={() => {
                                navigate("/myprofile");
                            }}  variant="primary">
                            View Profile
                        </Button>
                    </div>) : (<div className="centerContent">
                        <div className="paymentFailedMessage alert alert-danger" role="alert">
                            Payment failed. If your bank account was debited, please contact the admin.
                        </div>
                        <Button className="btn btn-warning text-white " onClick={() => {
                                navigate("/myprofile");
                            }}  variant="primary">
                            View Profile
                        </Button>
                    </div>)
                }

            </div>
        </div>
    )
}

export default PaymentStatus;
