import React, { useEffect, useState } from 'react'
import axios from 'axios';
import OTPInput, { ResendOTP } from "otp-input-react";
import { useNavigate ,useLocation} from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Enterotp() {

    
  const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    //Enter OTP UseState
    const [OTP, setOTP] = useState("");
    const [email,setEmail]=useState("")
    const user_id=localStorage.getItem('user_id')

    const showToast = (msg) => {

      // if (msg === "error") {
      //   toast.error(msg, {
      //         position: toast.POSITION.TOP_RIGHT,
      //         autoClose: 3000, // Duration in milliseconds, or set to false for manual dismissal

      //     });
      // } 
      if(msg==="OTP is incorrect") {
          toast.error(msg, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000, // Duration in milliseconds, or set to false for manual dismissal

          });

      }


  };
    const handleSubmit = async (e) => {
          setLoading(true)
          e.preventDefault();
          await axios.post(`${process.env.REACT_APP_API_BASE_URL}/forgots/otpVerify/${user_id}`, { otp: OTP})
          .then((response) => {
            localStorage.removeItem("user_id",response.data);
            

            toast.success("We've sent a password reset link to your email", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1300,
              });
            setLoading(false)
            console.log('POST response:', response.data);

            
            
            navigate('/account/enternew', { state: { user_id: user_id, email: email } });
          })
          .catch((error) => {
          setLoading(false)
          showToast("OTP is incorrect");
          console.error('POST error:', error);
        });
    };
  
  
   
    //Toasitfy



   
 



useEffect(() => {
  setEmail(location.state.email)
}, [OTP])


             
if (loading) {
  return (
      <div className="loading">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
      </div>
  )
}



return (
  <div className='container otp-container col-12' id="otpForm">
    <div >
      <div >
        <img className="img-responsive d-flex justify-content-center " src={(require('../assests/images/enterotp.png'))} alt='' width='50%' style={{ margin: "auto" }} />
      </div>
      <div className='text-center enterotp-title mb-3'>
        <h3>Enter OTP</h3>
      </div>
      <div className='text-center mb-3'>

        <span className='star-of-icon'><i className="fa-solid fa-star-of-life"></i></span>
        <span className='star-of-icon'><i className="fa-solid fa-star-of-life"></i></span>
        <span className='star-of-icon'><i className="fa-solid fa-star-of-life"></i></span>
        <span className='star-of-icon'><i className="fa-solid fa-star-of-life"></i></span>
        <span className='star-of-icon'><i className="fa-solid fa-star-of-life"></i></span>
        <span className='star-of-icon'><i className="fa-solid fa-star-of-life"></i></span>

    <div className='text-center enterotp-Text mb-4'>
                    <p>We have send you an mail <b>{email}</b> with 6 digit vertification code</p>
                </div>
                <div className="prelativeosition">
                    <div className="card  text-center">

          <div id="otp" className="inputs d-flex flex-row justify-content-center ">
            {/* {
                                    otp.map((data, index) => {
                                        return (
                                            <>
                                                <input className="m-2 text-center form-enterotp rounded enterotp " type="number" id="first" max="1" key={index} value={data} onChange={(e) => (handleChange(e.target, index))} onFocus={e => e.target.select()} />
                                            </>

                                        )
                                    })
                                } */}


                            <OTPInput  value={OTP} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={false} name='otp'   />
                             





            {/* <input className="m-2 text-center form-enterotp rounded enterotp " type="number" id="second" maxlength="1" />
                            <input className="m-2 text-center form-enterotp rounded enterotp " type="number" id="third" maxlength="1" />
                            <input className="m-2 text-center form-enterotp rounded enterotp " type="number" id="fourth" maxlength="1" />
                            <input className="m-2 text-center form-enterotp rounded enterotp " type="number" id="fifth" maxlength="1" />
                            <input className="m-2 text-center form-enterotp rounded enterotp " type="number" id="sixth" maxlength="1" /> */}
          </div> <div className="mt-4"> <button className="btn  px-4 validate" onClick={
            handleSubmit}>Submit</button>
          </div>
        </div>
      </div>
      <div className="card-2">
        <div className="content text-center">
          <span>Didn't receive the code?</span>
        </div>
      </div>
    </div>
  </div>
       </div>
    )

}
export default Enterotp