import React, { useState, useEffect } from "react";
import axios from "axios";
import Profile from "../../assests/images/avatar_profile.png";
import { useNavigate } from "react-router-dom";



function Superdeal() {
    const navigate = useNavigate();

    const user_detail = JSON.parse(localStorage.getItem("user_detail"));
  const user_id  = user_detail.id;
  const token = user_detail.token;
  const   user_address  = user_detail.userAddress;
  const [user_details, setUser_details] = useState();

    const [downline, setDownline] = useState([]);
    const [error, setError] = useState("");
    const [s1r1, setS1r1] = useState();
    const [s1r2, setS1r2] = useState();
    const [s1r3, setS1r3] = useState();


     const [s2r1, setS2r1] = useState();
     const [s2r2, setS2r2] = useState();
     const [s2r3, setS2r3] = useState();
     const [s2r4, setS2r4] = useState();
     const [s2r5, setS2r5] = useState();
     const [s2r6, setS2r6] = useState();
     const [s2r7, setS2r7] = useState();
     const [s2r8, setS2r8] = useState();
     const [s2r9, setS2r9] = useState();
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            // Redirect to login or handle unauthorized access
        } else {
            fetchDownline();
        }
    }, []);

    const fetchDownline = async () => {
        try {
            



            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/admin/kingdomTree`,{user_address},  { headers: { Authorization: token } });


                console.log(response.data,"data")
                if (!response.data.s1r1 || !response.data.s1r2 || !response.data.s1r3) {
                    const errorWithEmptyImage = (
                        <div className="container">
                        <div className=" row">
                          <div className="col-lg-5 col-12  ">
                            <div className="user_ref_data">
                              <img src={Profile} width={100} height={100} alt="Empty" />
                            </div>
                          </div>
                          <div className="mt-2 col-lg-5 col-12">
                            <h5 className="mt-4">
                              There is no members for this Package{" "}
                              <b style={{ color: "#32582E " }}></b>
                            </h5>
                          </div>
                        </div>
                      </div>
                    );
                    setError(errorWithEmptyImage);
                  }
                   else {
                setError(null);
                setUser_details(response.data.user_details[0]);
                setS1r1(response.data.s1r1[0]);
                setS1r2(response.data.s1r2[0]);
                setS1r3(response.data.s1r3[0]);
                setS2r1(response.data.s2r1[0]);
                setS2r2(response.data.s2r2[0]);
                setS2r3(response.data.s2r3[0]);
                setS2r4(response.data.s2r4[0]);
                setS2r5(response.data.s2r5[0]);
                setS2r6(response.data.s2r6[0]);
                setS2r7(response.data.s2r7[0]);
                setS2r8(response.data.s2r8[0]);
                setS2r9(response.data.s2r9[0]);
            }
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div className="supTree container w-100">
        <div>
      <button className="  btn1 btn10 " onClick={() => navigate("/myprofile")}> <i class="fa fa-arrow-left" aria-hidden="true"></i> Back</button>
   </div>
<div className="card-body ">
                   {error ? (
                       <p>{error}</p>
                   ) : (
                       <div className="row text-center justify-content-center llll">
                           <div className="w-100">
                               {user_details?.user_address == null ? (
                                   <div></div>
                               ) : (
                                   <div className="parent">
                                       <div className="  text-center" id="showdetails" type="button">
                                           <img src={Profile} className="free-user" />
                                           <p className="user-name">{user_details?.user_address}</p>
                                           <p className="user-name ">{user_details?.name}</p>

                                       </div>
                                       <span className="line1"></span>
                                       <span className="line32"></span>
                                   </div>
                               )}
                               <div className="child-157">
                                   <div className="d-flex ">
                                       {s1r1?.user_address == null ? (
                                           <div></div>
                                       ) : (
                                           <div className="child-details child-details-row2  " id="" type="button">
                                               <img src={Profile} className="free-user" />
                                               <p className="user-name">{s1r1?.user_address}</p>
                                               <p className="user-name">{s1r1?.name}</p>

                                               <span className="line33"></span>
                                               
                                               <span className="line22"></span>
                                           </div>
                                       )}
                                       {s1r2?.user_address == null ? (
                                           <div></div>
                                       ) : (
                                           <div className="child-details  child-details-row2   " id="" type="button">
                                               <img src={Profile} className="free-user" />
                                               <p className="user-name">{s1r2?.user_address}</p>
                                               <p className="user-name">{s1r2?.name}</p>

                                               <span className="line22"></span>
                                               <span className="line33"></span>
                                           </div>
                                       )}
                                       {s1r3?.user_address == null ? (
                                           <div></div>
                                       ) : (
                                           <div className="child-details  child-details-row2   " id="" type="button">
                                               <img src={Profile} className="free-user" />
                                               <p className="user-name">{s1r3?.user_address}</p>
                                               <p className="user-name">{s1r3?.name}</p>

                                               <span className="line22"></span>
                                               <span className="line33"></span>
                                           </div>
                                       )}
                                   </div>

                               </div>

                               <div className="child-2 row child-head1  ">

                                  
                                   <div className=" col-lg-4 col-4 d-flex justify-content-around">
                                       {s2r1?.user_address == null ? (
                                           <div></div>
                                       ) : (
                                           <div className="child-details" id="" type="button">
                                               <img src={Profile} className="free-user" />
                                               <p className="user-name">{s2r1?.user_address}</p>
                                               <p className="user-name">{s2r1?.name}</p>

                                           </div>
                                       )}
                                       {s2r2?.user_address == null ? (
                                           <div></div>
                                       ) : (
                                           <div className="child-details" id="" type="button">
                                               <img src={Profile} className="free-user" />
                                               <p className="user-name">{s2r2?.user_address}</p>
                                               <p className="user-name">{s2r2?.name}</p>

                                           </div>
                                       )}
                                       {s2r3?.user_address == null ? (
                                           <div></div>
                                       ) : (
                                           <div className="child-details" id="" type="button">
                                               <img src={Profile} className="free-user" />
                                               <p className="user-name">{s2r3?.user_address}</p>
                                               <p className="user-name">{s2r3?.name}</p>

                                           </div>
                                       )}
                                   </div>
                                   <div className="col-lg-4 col-4 d-flex justify-content-around">
                                       {s2r4?.user_address == null ? (
                                           <div></div>
                                       ) : (
                                           <div className="child-details" id="" type="button">
                                               <img src={Profile} className="free-user" />
                                               <p className="user-name">{s2r4?.user_address}</p>
                                               <p className="user-name">{s2r4?.name}</p>

                                           </div>
                                       )}
                                       {s2r5?.user_address == null ? (
                                           <div></div>
                                       ) : (
                                           <div className="child-details" id="" type="button">
                                               <img src={Profile} className="free-user" />
                                               <p className="user-name">{s2r5?.user_address}</p>
                                               <p className="user-name">{s2r5?.name}</p>

                                           </div>
                                       )}
                                       {s2r6?.user_address == null ? (
                                           <div></div>
                                       ) : (
                                           <div className="child-details" id="" type="button">
                                               <img src={Profile} className="free-user" />
                                               <p className="user-name">{s2r6?.user_address}</p>
                                               <p className="user-name">{s2r6?.name}</p>

                                           </div>
                                       )}
                                   </div>
                                   <div className=" col-lg-4 col-4 d-flex justify-content-around">
                                       {s2r7?.user_address == null ? (
                                           <div></div>
                                       ) : (
                                           <div className="child-details" id="" type="button">
                                               <img src={Profile} className="free-user" />
                                               <p className="user-name">{s2r7?.user_address}</p>
                                               <p className="user-name">{s2r7?.name}</p>

                                           </div>
                                       )}
                                       {s2r8?.user_address == null ? (
                                           <div></div>
                                       ) : (
                                           <div className="child-details" id="" type="button">
                                               <img src={Profile} className="free-user" />
                                               <p className="user-name">{s2r8?.user_address}</p>
                                               <p className="user-name">{s2r8?.name}</p>

                                           </div>
                                       )}
                                       {s2r9?.user_address == null ? (
                                           <div></div>
                                       ) : (
                                           <div className="child-details" id="" type="button">
                                               <img src={Profile} className="free-user" />
                                               <p className="user-name">{s2r9?.user_address}</p>
                                               <p className="user-name">{s2r9?.name}</p>

                                           </div>
                                       )}
                                   </div>
                                   </div>

                           </div>

                       </div>
                   )

                   }

               </div>
   </div>
    );
}

export default Superdeal;
