import React, { useEffect, useState } from "react";
import axios from "axios";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
function Adminedit() {
  const dynamic = "AdminPanel";
  const { id } = useParams();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    contact: "",
    user_address: "",
    ref_id: "",
    discount_point: "",
    wallet: "",
  });

  useEffect(() => {
    const token = localStorage.getItem("auth_token");
    if (!token) {
      return navigate("/");
    }
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/admin/views/${id}`)
      .then((res) => {
        const data = res.data;
        setUserData({
          name: data.name,
          email: data.email,
          contact: data.contact,
          user_address: data.user_address,
          ref_id: data.ref_id,
          discount_point: data.discount_point,
          membership_package: data.membership_package,

          wallet: data.wallet,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/admin/update/${id}`,
        userData
      );
      if (response.status === 200) {
        showToast("updated successful");
        navigate("/admin/adminpanel");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const showToast = (message, isError = false) => {
    toast[isError ? "error" : "success"](message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
    });
  };
  return (
    <>
      <Helmet>
        <title> DCrown Mart | {dynamic}</title>
      </Helmet>

      <div className="container adminedit w-50 mt-5">
        <h2>Edit User</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-3 mt-3">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="Enter name"
              name="name"
              value={userData.name}
              onChange={(e) =>
                setUserData({ ...userData, name: e.target.value })
              }
            />
          </div>
          <div className="mb-3">
            <label htmlFor="email">Email:</label>
            <input
              type="text"
              className="form-control"
              id="email"
              placeholder="Enter email"
              name="email"
              value={userData.email}
              onChange={(e) =>
                setUserData({ ...userData, email: e.target.value })
              }
            />
          </div>
          <div className="mb-3">
            <label htmlFor="contact">Number:</label>
            <input
              type="text"
              className="form-control"
              id="contact"
              placeholder="Enter number"
              name="contact"
              value={userData.contact}
              onChange={(e) =>
                setUserData({ ...userData, contact: e.target.value })
              }
            />
          </div>
          <div className="mb-3">
            <label htmlFor="user_address">User Address:</label>
            <input
              type="text"
              className="form-control"
              id="user_address"
              placeholder="Enter address"
              name="user_address"
              value={userData.user_address}
              onChange={(e) =>
                setUserData({ ...userData, user_address: e.target.value })
              }
            />
          </div>
          <div className="mb-3">
            <label htmlFor="ref_id">Referral ID:</label>
            <input
              type="text"
              className="form-control"
              id="ref_id"
              placeholder="Enter referral ID"
              name="ref_id"
              value={userData.ref_id}
              onChange={(e) =>
                setUserData({ ...userData, ref_id: e.target.value })
              }
            />
          </div>
          
          <div className="mb-3">
            <label htmlFor="membership_package">membership Package:</label>
            <input
              type="text"
              className="form-control"
              id="membership_package"
              placeholder="Enter membership package"
              name="membership_package"
              value={userData.membership_package}
              onChange={(e) =>
                setUserData({ ...userData, membership_package: e.target.value })
              }
            />
          </div>
          <div className="mb-3">
            <label htmlFor="discount_point">Discount Point:</label>
            <input
              type="text"
              className="form-control"
              id="discount_point"
              placeholder="Enter discount point"
              name="discount_point"
              value={userData.discount_point}
              onChange={(e) =>
                setUserData({ ...userData, discount_point: e.target.value })
              }
            />
          </div>
          <div className="mb-3">
            <label htmlFor="discount_point">wallet:</label>
            <input
              type="text"
              className="form-control"
              id="discount_point"
              placeholder="Enter wallet"
              name="wallet"
              value={userData.wallet}
              onChange={(e) =>
                setUserData({ ...userData, wallet: e.target.value })
              }
            />
          </div>
          <button type="submit" className="btn btn-primary">
            Update User
          </button>
        </form>
      </div>
    </>
  );
}

export default Adminedit;
