import React, { useEffect, useState } from 'react';
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { FaRegCircleCheck } from "react-icons/fa6";
import PaymentAppImage from "../assests/UPI-payment-.jpg";
import axios from 'axios';
function Placebooking() {
    const [state, setState] = useState(false)
    const [icon, setIcon] = useState(false)
    const [state1, setState1] = useState(false)
    const [icon1, setIcon1] = useState(false)
    const [state2, setState2] = useState(false)
    const [icon2, setIcon2] = useState(false)
    const [data, setData] = useState([])
   
    const handleStyle = {
        padding: "20px",
        border: "2px solid #ffbe0b",
        borderRadius: "20px",
        fontSize: '21px',
        fontWeight: 500,
        marginBottom: '20px'
    }
    const handleForm = {
        padding: "20px",
        border: "1px solid #ffbe0b",
    }
    const handleShow = () => {
        setState(!state)
        setIcon(!icon)
    }
    const handleShow1 = () => {
        setState1(!state1)
        setIcon1(!icon1)
    }
    const handleShow2 = () => {
        setState2(!state2)
        setIcon2(!icon2)
    }
    const handleButton = {
        width: '100%',
        backgroundColor: '#32582E',
        color: 'white',
        padding: '10px'

    }


    //image
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/product/getProduct`)
            .then(res => res.data)
            .then(res => setData(res))
            .catch(err => console.log(err, "huohuh"))
    }, [])
    
  
    return (
        <div className='container'>
            <div className='row' style={{ marginTop: '150px' }}>
                <div className='col-lg-6'>

                    <div className='d-flex justify-content-between' style={handleStyle} onClick={handleShow}>
                        <span>Add credit or debit cards</span>
                        <span >{icon ? <IoIosArrowUp /> : <IoIosArrowDown />}</span>
                    </div>
                    <div className={state ? "d-block p-3" : "d-none"}>
                        <div style={handleForm}>
                            <div className='d-flex'>
                                <span><FaRegCircleCheck style={{ color: '#ffbe0b', fontSize: '20px' }} /></span>
                                <span className='ms-2 '>Add credit or debit cards</span>
                            </div>
                            <form>
                                <div className='mt-3 form__Placebooking '>
                                    <input type="text" name="name" className="form-control form__input--primay" />
                                    <label className='form__input--label'>Name on Card</label>
                                </div>
                                <div className='mt-3 form__Placebooking'>
                                    <input type="text" name="name" className="form-control  form__input--primay" />
                                    <label className='form__input--label'>Card Number</label>
                                </div>
                                <div className='row mt-3 '>
                                    <div className='col-lg-7 form__Placebooking'>
                                        <input type="text" name="name" className="form-control  form__input--primay" />
                                        <label className='form__input--label'>Expiry Date (MM/YY)</label>
                                    </div>
                                    <div className='col-lg-5 form__Placebooking'>
                                        <input type="text" name="name" className="form-control  form__input--primay" />
                                        <label className='form__input--label'>CVV</label>
                                    </div>

                                </div>
                                <div className='mt-3'>
                                    <input type="text" name="name" className="form-control form__input--primay " placeholder="Nickname for card (Optional)" />
                                </div>
                                <div className='mt-3'>
                                    <button className='btn form__button' style={handleButton}>Checkout</button>
                                </div>
                            </form>
                        </div>

                    </div>

                    <div className='d-flex justify-content-between' style={handleStyle} onClick={handleShow1}>
                        <span>Add new UPI ID</span>
                        <span>{icon1 ? <IoIosArrowUp /> : <IoIosArrowDown />}</span>

                    </div>
                    <div className={state1 ? "d-block p-3" : "d-none"}>
                        <div style={handleForm}>
                            <div className='d-flex'>
                                <span><FaRegCircleCheck style={{ color: '#ffbe0b', fontSize: '20px' }} /></span>
                                <span className='ms-2 '>Add new UPI</span>
                            </div>
                            <div className='mt-4'>
                                <img src={PaymentAppImage} width="60%" />
                            </div>

                            <div className='row mt-4'>
                                <div className='col-lg-8 form__Placebooking'>
                                    <input type="text" name="name" className="form-control  form__input--primay" />
                                    <label className='form__input--label'>example@upi</label>
                                </div>
                                <div className='col-lg-4 '>
                                    <button className='btn w-100 form__button' style={handleButton}>Checkout</button>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='d-flex justify-content-between' style={handleStyle} onClick={handleShow2}>
                        <span>Cash</span>
                        <span>{icon2 ? <IoIosArrowUp /> : <IoIosArrowDown />}</span>
                    </div>
                    <div className={state2 ? "d-block p-3" : "d-none"}>
                        <div style={handleForm}>

                            <p> Please keep exact change handy to help us serve you better</p>

                        </div>

                    </div>
                </div>
                <div className='col-lg-3'></div>
                <div className='col-lg-3'>
                    <div className='cards w-75 p-3' style={{ border: '1px solid' }}>
                        <div>
                            <h4>Delivery Address</h4>
                            <p>Home: 1234, pondicherry</p>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <span>
                                My Cart
                            </span>
                            <span>
                                2 items
                            </span>
                        </div>
                        <div className='mt-2'>
                           <div>
                            
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Placebooking