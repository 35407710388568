
import React, { useEffect, useState } from 'react'
import axios from 'axios';

function Upgrade_membership() {
    const [data, setData] = useState([]);

    const [upgrade_packageName, setSelectedOption] = useState('super_deal')
    const [response, setResponse] = useState(null);

   const localStorageitmes=JSON.parse(localStorage.getItem('user_detail'))
   const membership_package = localStorageitmes.membership_package;
   const id = localStorageitmes.id;
   const details= {id,membership_package,upgrade_packageName};
   
    const handlePostRequest = () => {
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/membership/membershipupgrade`, details)
            .then(response => {
                setResponse(response.data);
            })
            .catch(error => {
                console.error('Error making POST request: ', error);
            });
    }


    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/membership/packageprice`); // Replace with your API endpoint
                const jsonData = await response.json();
                setData(jsonData);
           
            } catch (err) {
                console.log("error");
            }
        }

        fetchData();
    }, []);
    return (
        <div className='upgrade_membership'>
            <div className='row d-flex justify-content-center' style={{ width: '100%' }}>
                <div className='col-lg-5 shadow  '>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Package Type</th>
                                <th scope="col">Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item) => (
                                <tr key={item.si_no}>
                                    <td>{item.membership_type}</td>
                                    <td>{item.price}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <form>
                        <div className='d-flex justify-content-between'>
                            <div className='col-lg-3'>
                                <select value={upgrade_packageName} onChange={handleSelectChange}>

                                    <option value="super_deal">Super Deal</option>
                                    <option value="kingdom">Kingdom</option>
                                    <option value="crown_kingdom">Crown Kingdom</option>
                                    <option value="royal_crown">Crown Kingdom</option>

                                </select>
                            </div>

                            <div className='col-lg-3' >
                                <button className='btn upgrate_membership_button' onClick={handlePostRequest}>Pay</button>
                            </div>
                           
                        </div>
                    </form>









                </div>

            </div>
        </div>
    )
}

export default Upgrade_membership