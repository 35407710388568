import React, { useEffect } from "react";
import { useFormik } from "formik";
import axios from "axios";
import { toast } from "react-toastify";

import * as Yup from "yup";
import { useNavigate } from "react-router";
import { useState } from "react";
const referral1 = Yup.object().shape({
  referral: Yup.string()
    .min(4, " Referral Id must be 4 characters long")

    .required("Required"),
});

const showToast = (msg) => {
  if (msg === "Payment Successfully") {
    toast.success(msg, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000, // Duration in milliseconds, or set to false for manual dismissal
    });
  }
};

function MemberShip() {
  const { values, handleChange, handleBlur, handleSubmit, touched, errors } =
    useFormik({
      initialValues: {
        referral: "DCM",
      },
      onSubmit: (value) => {
      },
      validationSchema: referral1,
    });

  const [userDetails, setUserDetails] = React.useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      setUserDetails(JSON.parse(localStorage.getItem("user_detail")));
    };
    getData();
  }, []);

  useEffect(() => {
  }, [userDetails]);

  const membershipAdd = () => {
    setLoading(true)
    const memberDetails = {
      user_id: userDetails.id,
      amount: 99,
      ref_id: values.referral,
      package: "superdeals",
      package_level: 1,
    };
    
    axios
    .post(
      `${process.env.REACT_APP_API_BASE_URL}/membership/membershipadd`,
      memberDetails
      )
      .then((response) => {
        setLoading(false)
        showToast("Payment Successfully");
        setTimeout(() => {
          navigate("/");
        }, 4000);
        // response.data
      })
      .catch((error) => {
        // showToast("error");
        setLoading(false)
        console.error("POST error:", error);
      });
  };

  if (loading) {
    return (
        <div className="loading">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    )
}

  return (
    <div className="container">
      <div className="row justify-content-center ">
        <div className="col-lg-5 mt-4">
          <div className="d-flex justify-content-between">
            <div>
              <h4>Membership</h4>
            </div>
            <div>
              <h4>₹ 99</h4>
            </div>
          </div>
          <div>
            <form onSubmit={handleSubmit}>
              <div className="mt-5 inputWidthIcon">
                <input
                  type="text"
                  name="referral"
                  className="form-control   Form-input"
                  placeholder="Referral Id"
                  value={values.referral.toUpperCase()}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxLength="6"
                />
                <i
                  className="fa-regular fa-user fa-lg fa-wd"
                  aria-hidden="true"
                ></i>
                {touched.referral && errors.referral ? (
                  <div style={{ color: "red" }}>{errors.referral}</div>
                ) : (
                  ""
                )}
              </div>

              <div className="shadow p-3 mt-4 bg-body rounded">
                <div className="d-flex justify-content-start">
                  <div>
                    <h6>Payment Method</h6>
                  </div>
                  <div>
                    <h6>₹ 99</h6>
                  </div>
                </div>
                <div className="mt-4">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label className="form-check-label" for="flexRadioDefault1">
                      Payment Gateway
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label className="form-check-label" for="flexRadioDefault1">
                      Payment
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label className="form-check-label" for="flexRadioDefault1">
                      Google Pay
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label className="form-check-label" for="flexRadioDefault1">
                      PhonePe
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label className="form-check-label" for="flexRadioDefault1">
                      Direct Bank Transfer
                    </label>
                  </div>
                </div>
              </div>
              <div className="Membership-footer d-flex justify-content-between">
                <div>
                  <div>
                    <h6>Payment Amount</h6>
                  </div>
                  <div>
                    <h6>₹ 99</h6>
                  </div>
                  <div>
                    <h6>Handle Fee: ₹0.00</h6>
                  </div>
                </div>
                <div className="membership-btn">
                  <button
                    type="submit"
                    className="btn membership-button"
                    onClick={membershipAdd}
                  >
                    Pay
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MemberShip;
