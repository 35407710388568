// import Body from "../component/Body/Body";
import * as React from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Container, Grid } from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import Food from "../assests/images/Frame 26.png";
import Fresh from "../assests/images/Frame 29.png";
import vegetable from "../assests/images/Frame 30.png";
import Producttype from "./Producttype";
import ProductsShow from "./slider/ProductsShow";

import { Helmet } from 'react-helmet';


const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const dynamicTitle = 'Home';
<Helmet>
<title>{dynamicTitle} | DCrown </title>
</Helmet>
const imgAndName = [
  { name: "Grocery", imgName: "g9.png" },
  { name: "Mobiles", imgName: "mobile.jpg" },
  {
    name: "Fashion",
    imgName: "p1.jpg",
  },
  { name: "Appliances", imgName: "applience-removebg-preview.png" },
  { name: "Electronics", imgName: "pc-removebg-preview.png" },
  { name: "Furnitures", imgName: "furniture-removebg-preview.png" },
];

const images = [
  {
    label: "Food",
    imgPath: Food,
  },
  {
    label: "  vegetable",
    imgPath: vegetable,
  },
  {
    label: "Fresh",
    imgPath: Fresh,
  },
];

const imageStyles = {
  width: "10%", // Set the width of the image within the card
  height: "5px", // Let the height adjust automatically to maintain aspect ratio
};

function SwipeableTextMobileStepper({ handleClick }) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  const settings = {
    className: "center",
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 5,
    swipeToSlide: true,
    afterChange: function (index) {
    
    },
  };
  // useEffect(()=>{
  //   window.location.reload();
  // },[])
 


  return (
    <Box>

      <Box sx={{ flexGrow: 1 }}>
        <Grid sx={{ mt: 12, mb: 1 }}>


          <AutoPlaySwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents 
            className="AutoPlay"
          >
            {images.map((step, index) => (
              <div key={step.label}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <Box
                  className="AutoPlay"
                    component="img"
                    sx={{
                      // height: 40,
                      display: "block",
                      objectFit: "fill",
                      overflow: "hidden",
                      width: "100%",
                    }}
                    src={step.imgPath}
                    alt={step.label}
                  ></Box>
                ) : null}
              </div>
            ))}
          </AutoPlaySwipeableViews>
        </Grid>
      </Box>

      <Producttype />
      

      <Grid item xs={12} sx={{ marginTop: '50px' }}>
        <ProductsShow  />
       
      </Grid>
    </Box>
  );
}

export default SwipeableTextMobileStepper;
