import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { createData } from "../../routes/HomeRouter";
import Swal from "sweetalert2";
import err_img from "../../assests/images/error_img.png";
import { base_url } from "../../utility/Url";

function ProductsShow() {
  const navigate = useNavigate();
  const [masala, setMasala] = useState([]);
  const [rice, setRice] = useState([]);
  const [coffee, setCoffee] = useState([]);
  const [fruits, setFruits] = useState([]);
  const [juices, setJuices] = useState([]);
  const [biscuits, setBiscuits] = useState([]);
  const [snacks, setSnacks] = useState([]);

  const [userToken, setUserToken] = useState();
  const { setRender } = useContext(createData);

  useEffect(() => {
    console.log(process.env.REACT_APP_API_BASE_URL , "testing")
    async function fetchData() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/product/getProduct`
        );
        console.log(process.env.REACT_APP_API_BASE_URL , "testing")

        if (response && response.data) {
          const masalaProducts = response.data.filter(
            (product) => product.categories === "Masala,oil & More"
          );
          setMasala(masalaProducts);

          const biscuitProducts = response.data.filter(
            (product) => product.categories === "Bakery & Biscutes"
          );
          setBiscuits(biscuitProducts);

          const riceProducts = response.data.filter(
            (product) => product.categories === "Atta,Rice & Dal"
          );
          setRice(riceProducts);

          const coffeeProducts = response.data.filter(
            (product) => product.categories === "Tea,Coffee &Health drink"
          );
          setCoffee(coffeeProducts);

          const fruitsProducts = response.data.filter(
            (product) => product.categories === "Fruit & vegitables"
          );
          setFruits(fruitsProducts);

          const juicesProducts = response.data.filter(
            (product) => product.categories === "Cold Drinks & juices"
          );
          setJuices(juicesProducts);

          const snacksProducts = response.data.filter(
            (product) => product.categories === "Snacks & Munchies"
          );
          setSnacks(snacksProducts);
        } else {
          console.log("Response or response data is undefined");
        }
      } catch (error) {
        console.log(error, "axios");
      }
    }

    fetchData();
  }, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    const Token = localStorage.getItem("token");
    setUserToken(Token);
  });
  const Addtocart = async (item, e) => {
    e.preventDefault();

    if (!userToken) {
      Swal.fire({
        title: "Please Login",
        imageUrl: err_img,
confirmButtonColor: "#ffbe0b",
        confirmButtonText: "Login",
        html: "Please login and continue your shopping",
        showCancelButton: false,
        imageWidth: 200,
        imageHeight: 200,
        customClass: {
          image: "swal2-image",
          popup: "swal2-popup",
        },
      }).then(() => {
        navigate("/account");
      });
    } else {
      const productId = item.id;

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/addToCart/addProductToCard`,
          { productId },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        );

        toast.success("Added to Cart", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });

        console.log(response);
        setRender((per) => per + 1);
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <>
      <h3 className=" mx-5 ps-4 pb-3 ">Masala & oils</h3>
      <div className="slider">
        {masala.map((item) => (
          <div className="slide " key={item.id}>
            <div className="card">
              <div key={item.id}>
                <img
                  className="product2--image"
                  src={`${base_url}/${item.image}`}
                  alt="product image" // Include the item's ID in the UR
                  onClick={() => navigate(`cartdetails/${item.id}`)}
                />
                <br />
                <h5 className="mt-3 fs-5 ">{item.name}</h5>
                <p className="price">
                  <span className="text-danger fs-5  fw-bold me-1">₹</span>
                  <span className="fw-bold fs-5">{item.price}</span>
                </p>
                <div className="addtocart">
                  <div className="product2-add">
                    <button
                      type="submit"
                      className=""
                      onClick={(e) => {
                        Addtocart(item, e);
                      }}
                    >
                      {" "}
                      Add to Cart
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <h3 className=" mx-5 ps-4 pb-3 mt-5">Chocolates & Biscuits</h3>
      <div className="slider">
        {biscuits.map((item) => (
          <div className="slide " key={item.id}>
            <div className="card">
              <div key={item.id}>
                <img
                  className="product2--image"
                  src={`${base_url}/${item.image}`}
                  alt="product image" // Include the item's ID in the UR
                  onClick={() => navigate(`cartdetails/${item.id}`)}
                />
                <br />
                <h5 className="mt-3 fs-5 ">{item.name}</h5>
                <p className="price">
                  <span className="text-danger fs-5  fw-bold me-1">₹</span>
                  <span className="fw-bold fs-5">{item.price}</span>
                </p>
                <div className="addtocart">
                  <div className="product2-add">
                    <button
                      type="submit"
                      className=""
                      onClick={(e) => {
                        Addtocart(item, e);
                      }}
                    >
                      {" "}
                      Add to Cart
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

     
      <h3 className=" mx-5 ps-4 pb-3 mt-5">Tea & Coffees</h3>
      <div className="slider">
        {coffee.map((item) => (
          <div className="slide " key={item.id}>
            <div className="card">
              <div key={item.id}>
                <img
                  className="product2--image"
                  src={`${base_url}/${item.image}`}
                  alt="product image" // Include the item's ID in the UR
                  onClick={() => navigate(`cartdetails/${item.id}`)}
                />
                <br />
                <h5 className="mt-3 fs-5 ">{item.name}</h5>
                <p className="price">
                  <span className="text-danger fs-5  fw-bold me-1">₹</span>
                  <span className="fw-bold fs-5">{item.price}</span>
                </p>
                <div className="addtocart">
                  <div className="product2-add">
                    <button
                      type="submit"
                      className=""
                      onClick={(e) => {
                        Addtocart(item, e);
                      }}
                    >
                      {" "}
                      Add to Cart
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

   

      <h3 className=" mx-5 ps-4 pb-3 mt-5">Fruits & vegetables</h3>

      <div className="slider">
        {fruits.map((item) => (
          <div className="slide " key={item.id}>
            <div className="card">
              <div key={item.id}>
                <img
                  className="product2--image"
                  src={`${base_url}/${item.image}`}
                  alt="product image" // Include the item's ID in the UR
                  onClick={() => navigate(`cartdetails/${item.id}`)}
                />
                <br />
                <h5 className="mt-3 fs-5 ">{item.name}</h5>
                <p className="price">
                  <span className="text-danger fs-5  fw-bold me-1">₹</span>
                  <span className="fw-bold fs-5">{item.price}</span>
                </p>
                <div className="addtocart">
                  <div className="product2-add">
                    <button
                      type="submit"
                      className=""
                      onClick={(e) => {
                        Addtocart(item, e);
                      }}
                    >
                      {" "}
                      Add to Cart
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      

      <h3 className=" mx-5 ps-4 pb-3 mt-5">Cold Drinks & Juices</h3>

      <div className="slider">
        {juices.map((item) => (
          <div className="slide " key={item.id}>
            <div className="card">
              <div key={item.id}>
                <img
                  className="product2--image"
                  src={`${base_url}/${item.image}`}
                  alt="product image" // Include the item's ID in the UR
                  onClick={() => navigate(`cartdetails/${item.id}`)}
                />
                <br />
                <h5 className="mt-3 fs-5 ">{item.name}</h5>
                <p className="price">
                  <span className="text-danger fs-5  fw-bold me-1">₹</span>
                  <span className="fw-bold fs-5">{item.price}</span>
                </p>
                <div className="addtocart">
                  <div className="product2-add">
                    <button
                      type="submit"
                      className=""
                      onClick={(e) => {
                        Addtocart(item, e);
                      }}
                    >
                      {" "}
                      Add to Cart
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

   

      <h3 className=" mx-5 ps-4 pb-3 mt-5">Snacks & Munchies</h3>

      <div className="slider">
        {snacks.map((item) => (
          <div className="slide " key={item.id}>
            <div className="card">
              <div key={item.id}>
                <img
                  className="product2--image"
                  src={`${base_url}/${item.image}`}
                  alt="product image" // Include the item's ID in the UR
                  onClick={() => navigate(`cartdetails/${item.id}`)}
                />
                <br />
                <h5 className="mt-3 fs-5 ">{item.name}</h5>
                <p className="price">
                  <span className="text-danger fs-5  fw-bold me-1">₹</span>
                  <span className="fw-bold fs-5">{item.price}</span>
                </p>
                <div className="addtocart">
                  <div className="product2-add">
                    <button
                      type="submit"
                      className=""
                      onClick={(e) => {
                        Addtocart(item, e);
                      }}
                    >
                      {" "}
                      Add to Cart
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

     

      <h3 className=" mx-5 ps-4 pb-3 mt-5">Atta & Rice</h3>

      <div className="slider">
        {rice.map((item) => (
          <div className="slide " key={item.id}>
            <div className="card">
              <div key={item.id}>
                <img
                  className="product2--image"
                  src={`${base_url}/${item.image}`}
                  alt="product image" // Include the item's ID in the UR
                  onClick={() => navigate(`cartdetails/${item.id}`)}
                />
                <br />
                <h5 className="mt-3 fs-5 ">{item.name}</h5>
                <p className="price">
                  <span className="text-danger fs-5  fw-bold me-1">₹</span>
                  <span className="fw-bold fs-5">{item.price}</span>
                </p>
                <div className="addtocart">
                  <div className="product2-add">
                    <button
                      type="submit"
                      className=""
                      onClick={(e) => {
                        Addtocart(item, e);
                      }}
                    >
                      {" "}
                      Add to Cart
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

   
    </>
  );
}
export default ProductsShow;
