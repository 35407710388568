import React from 'react'
import { Route, Routes } from 'react-router-dom'

import HomeRouter from './HomeRouter';
import AccountRouter from './AccountRouter';
import AdminRouter from './AdminRouter';




export default function AppRouter() {
  return (
    <>
    <Routes>
      
    <Route exact path='/*' element={<HomeRouter/>}/>  
    <Route exact path='/account/*' element={<AccountRouter/>} />
    <Route exact path='/admin/*' element={<AdminRouter/>} />
    </Routes>
     
          </>
          
  )
}
