import React from "react";
import about from "../assests/images/About.jpg";
import { Box, Container, Typography, Grid } from "@mui/material";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import Aos from "aos";
import 'aos/dist/aos.css'
import { useEffect } from "react";

function About() {

  useEffect(() => {
    // Scroll to the top of the page when component mounts
    window.scrollTo(0, 0);
  }, []);
  const [text] = useTypewriter({
    words: ["Dcrownmart"],
    loop: {},
    typeSpeed: 100,
    deleteSpeed: 40,
  });
  React.useEffect(() => {
    Aos.init({ duration: 1000 });
  },[]);
  return (
    <Container>
      <Box sx={{ marginTop: "150px" }}>
        <Typography
          variant="h4"
          sx={{ textAlign: "center", fontFamily: "outfit" }}
        >
          Welcome to
          <span
            style={{ fontWeight: "bold", color: "#FFD700", marginLeft: "10px" }}
          >
            {text}
          </span>
          <span style={{ fontWeight: "bold" }}>
            <Cursor />
          </span>
        </Typography>
        <Typography
          variant="body1"
          sx={{ textAlign: "justify", marginTop: "20px" }}
          data-aos="zoom-in"
        >
          At <span style={{ fontWeight: "bold" }}>Dcrownmart,</span>
          isn't just a place to shop; it's a destination for discovering curated
          products that enhance your lifestyle. We're not your average online
          store – we're a collective of enthusiasts who believe in the power of
          great products and exceptional service.
        </Typography>
        <dl style={{ marginTop: "20px" }}  data-aos="zoom-in">
          <dt>Crafting Memorable Experiences</dt>
          <dd>
            At Dcrownmart, we understand that shopping is not just a transaction;
            it's an experience. Our platform is designed to make your journey
            seamless, from discovery to delivery. We meticulously curate our
            selection to bring you products that not only meet your needs but
            also elevate your everyday moments.
          </dd>
        </dl>
        <dl style={{ marginTop: "20px" }}  data-aos="zoom-in">
          <dt>Passionate Curators, Dedicated Service</dt>
          <dd>
            Our team at Dcrownmart is more than just employees; we are passionate
            curators who are continually seeking out unique, high-quality
            products that align with the latest trends and timeless classics. We
            take pride in offering not just products but solutions to make your
            life better.
          </dd>
        </dl>
        <dl style={{ marginTop: "20px" }}  data-aos="zoom-in">
          <dt>Empowering Your Choices</dt>
          <dd>
            Shopping at Dcrownmart is not just about buying – it's about
            empowerment. We believe in empowering our customers by providing a
            diverse range of choices that cater to various tastes and
            preferences. Your satisfaction is our priority, and we're here to
            ensure you find exactly what you're looking for.
          </dd>
        </dl>
        <Grid container spacing={2} sx={{marginTop:'20px'}}>
          <Grid item lg={6}>
            <Box sx={{ width: "70%", margin: "auto" }} data-aos="fade-right">
              <img src={about} width="100%" />
            </Box>
          </Grid>
          <Grid item lg={6}  data-aos="fade-left">
            <dl >
              <dt>Quality Products</dt>
              <dd>
                We source only the best products to ensure your satisfaction.
              </dd>
            </dl>
            <dl>
              <dt>Secure Shopping</dt>
              <dd>
              Your privacy and security are our priorities. Shop with confidence knowing your information is safe.
              </dd>
            </dl>
            <dl>
              <dt>Customer Support</dt>
              <dd>
              Our friendly customer support team is here to assist you. Contact us anytime with questions or concerns.
              </dd>
            </dl>
            <dl>
              <dt>Join Our Community</dt>
              <dd>
              Become a part of the <span style={{fontWeight:'bold'}}>Dcrownmart</span> community! Connect with us on social media [include social media links] for the latest updates, promotions, and to join a community of like-minded shoppers.
              </dd>
            </dl>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default About;
