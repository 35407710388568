import React from "react";
import NavBar from "../component/Navbar/NavBar";
import { useState, useEffect } from "react";
import { useRef } from "react";
import uploads from "../assests/images/upload_images.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { TextField } from "@mui/material";
import Swal from "sweetalert2";

import PaymentAppImage from "../assests/UPI-payment-.jpg";
import Spinner from 'react-bootstrap/Spinner';
import { Helmet } from "react-helmet";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { toast } from "react-toastify";

function Payment() {
  const [upgrade_packageName, setSelectedOption] = useState("super_deal");
  const [price, setPrice] = useState("");
  const [price2, setPrice2] = useState(null);
  const [image, setImage] = useState(null);
  const [userDetails, setUserDetails] = useState("");
  const [data, setData] = useState([]);
  const [memberPrice, setMemberPrice] = useState([]);

  const [showField, setShowField] = useState(false);
  const [upgrademembership, setUpgradeMembership] = useState("");
  const [userId, setUserId] = useState("");
  const [loading, setLoading] = useState(false);
  const [refid, setRefID] = useState(false);



  const Detail = localStorage.getItem("user_detail");

  const parsedUserDetail = JSON.parse(Detail);


  const id = parsedUserDetail.url_referal_Id

  console.log(id, "UrlReferId")

  useEffect(() => {
    const ownFunction = async () => {
      const storedUserDetail = localStorage.getItem("user_detail");
      if (storedUserDetail) {
        const parsedUserDetail = JSON.parse(storedUserDetail);
        setUserId(parsedUserDetail);

        const id = parsedUserDetail.id;

        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/users/getuserdata`,
            { id: id }
          );
          setUserDetails(response.data.userData[0]);
        } catch (error) {
          console.error(error);
        }
      }
    };
    ownFunction();
  }, []);

  React.useEffect(() => {
    async function fetchData() {
      const token = localStorage.getItem("token");

      if (!token) {
        navigate("/")
      }

      await axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/membership/packageprice`, {
          headers: {
            Authorization: token,
          },
        })
        .then((res) => {
          setData(res.data);
          const data = res.data;
          const indicesToGet = [0, 3, 4, 5];
          const value = data
            .slice(0, 6)
            .filter((obj, index) => indicesToGet.includes(index));
          setMemberPrice(value);

          console.log(res.data, data, value, "res.data");
        })
        .catch((err) => {
          console.log(err, "axios");
        });
    }

    fetchData();
  }, []);
  React.useEffect(() => {
    if (userDetails.membership_package == null) {
      setShowField(true);
      setUpgradeMembership("super_deal");
      setPrice(data[0]?.price);
    } else if (userDetails.membership_package == "super_deal") {
      setShowField(false);
      setUpgradeMembership("kingdom");
      setPrice(data[3]?.price);
    } else if (userDetails.membership_package == "kingdom") {
      setShowField(false);
      setUpgradeMembership("crown_kingdom");
      setPrice(data[4]?.price);
    } else if (userDetails.membership_package == "crown_kingdom") {
      setShowField(false);
      setUpgradeMembership("royal_crown_kigdom");
      setPrice(data[5]?.price);
    } else if (userDetails.membership_package == "royal_crown_kingdom") {
      setShowField(false);
      //hadle for no more upgrade
    }
  }, [data]);
  const navigate = useNavigate();
  const handleChange16 = (e) => {
    setImage(e.target.files[0]);
  };

  const handleUserAddress = async (e) => {
    e.preventDefault();
    const referralId = e.target.value;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/users/getuserAddress`,
        {
          referralId: referralId,
        }
      );

      if (response.status === 200) {
        setRefID(true);
        console.log("Referral ID exists");
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setFieldError("referral", "Referral ID  does not exists");
        setRefID(false);
        console.log("Referral ID does not exist");
      } else {
        console.error("Error:", error);
      }
    }
  };

  const eventsubmit = async () => {


    if (userDetails.membership_package == "royal_crown_kingdom") {
      Swal.fire(
        "Success!",
        "You completed All menbership packages",
        "success"
      );
      return;
    }


    if (!id && !values.referral && upgrademembership == 'super_deal') {
      toast.error('Referral Id Required', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000, // Duration in milliseconds, or set to false for manual dismissal
      });
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("user_address", userDetails.user_address);
    formData.append("user_id", userDetails.id);
    formData.append("name", userDetails.name);
    formData.append("amount", price2 == null ? price : price + price2); // Make sure 'values' is defined.
    formData.append("payment_method", values.payment_method);
    //formData.append("transaction_id", values.transaction); // Make sure 'values' is defined.
    formData.append(
      "ref_id",
      id ? id : values.referral
    );

    formData.append(
      "package_level",
      userDetails.package_level ? userDetails.package_level : null
    );
    formData.append(
      "membership_package",
      !userDetails.membership_package ? null : userDetails.membership_package
    );
    //formData.append("payment_screenshot", image);
    formData.append("upgrade_package", upgrademembership);

    console.log(formData, "formData");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/transaction/new/paymentdetails`,
        formData,

        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.success) {
        window.location.href = response.data.data.instrumentResponse.redirectInfo.url;
      } else {
        toast.error('Payment initiated failed.Please try again.', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000, // Duration in milliseconds, or set to false for manual dismissal
        });
      }
      // if (response.status === 200) {
      //   Swal.fire(
      //     "Success!",
      //     "Thank you for submitting your payment details. We're in the process of verifying the information you provided. Approval might take a little time.",
      //     "success"
      //   )
      //     .then(() => {
      //       setTimeout(() => {
      //         setLoading(false);

      //         navigate("/myprofile");
      //       }, 1000);
      //     })
      //     .catch((error) => console.error(error));
      // }
      //console.log(response, "response");
    } catch (error) {
      setLoading(false);
      toast.error('Payment initiated failed.Please try again.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000, // Duration in milliseconds, or set to false for manual dismissal
      });
      console.log("Error:", error.message);
    }
    setLoading(false);
  };

  let validationSchema;
  if (userDetails.ref_id) {
    validationSchema = Yup.object().shape({
      //transaction: Yup.string().required("Transaction ID is required"),
      //payment_method: Yup.string().required("Please select an Payment option"),
    });
  }
  // else if(userDetails) {
  //   validationSchema = Yup.object().shape({
  //     transaction: Yup.string().required("Transaction ID is required"),
  //     payment_method: Yup.string().required("Please select an Payment option"),

  //     referral: Yup.string()
  //       .required("Referral ID is required")
  //       .matches(
  //         /^[a-zA-Z0-9]*$/,
  //         "Referral ID should contain only alphanumeric characters"
  //       )
  //       .min(5, "Referral ID should be at least 5 characters")
  //       .max(7, "Referral ID should not exceed 7 characters"),
  //   })
  // }

  else {
    validationSchema = Yup.object().shape({
      //transaction: Yup.string().required("Transaction ID is required"),
      //payment_method: Yup.string().required("Please select an Payment option"),
      // referral: Yup.string()
      //   .required("Referral ID is required")
      //   .matches(
      //     /^[a-zA-Z0-9]*$/,
      //     "Referral ID should contain only alphanumeric characters"
      //   )
      //   .min(5, "Referral ID should be at least 5 characters")
      //   .max(7, "Referral ID should not exceed 7 characters")


    });
  }
  const {
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    handleSubmit,
    setFieldError,
  } = useFormik({
    initialValues: {
      referral: "",
      membershipType: "",
      transaction: "",
      payment_method: "",
    },
    validationSchema: validationSchema,
    onSubmit: eventsubmit,
  });

  const [value, setValue] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");

        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/admin/acccount`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        const data = await response.json(); // Use response.json() to parse JSON data
        setValue(data[0]);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);
  console.log(errors, "ref_id");

  const dynamic = "Payment";
  if (loading) {
    return (
      <div class="loading">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    );
  }
  return (
    <>
      <Helmet>
        <title> DCrown Mart | {dynamic}</title>
      </Helmet>
      <div className="container   ">
        <div className="row justify-content-evenly">
          <div className="col-12 col-lg-5 p-5 payment-container border ">
            <table className="table ">
              <thead>
                <tr>
                  <th scope="col">Package Type</th>
                  <th scope="col">Price</th>
                </tr>
              </thead>
              <tbody>
                {memberPrice.map((item) => (
                  <tr key={item.si_no}>
                    <td>{item.membership_type}</td>
                    <td> ₹ {item.price}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* <div>
              <div className="">
                <div className="  row">
                  <div className=" mt-1 col-6 ">
                    <img src={PaymentAppImage} width="100%" />
                  </div>

                  <div className="col-6 mt-3 text-center ">
                    <h4> {value.gpay} </h4>
                  </div>
                </div>
                <h6 className="text-danger text-center "> (OR) </h6>

                <div className=" shadow p-4 mt-4 bg-white rounded">
                  <h4 className="text-green text-center">Account Details </h4>
                  <div className="row ">
                    <div className="col-lg-5 col-12 ">
                      <p className="text-danger">Name : {value.name}</p>
                      <p className="text-danger">Bank : {value.bank}</p>
                      <p className="text-danger">Branch: {value.branch}</p>
                    </div>

                    <div className="col-lg-7 col-12 ">
                      <p className="text-danger">Acc No : {value.acc_no}</p>
                      <p className="text-danger">IFSC : {value.ifsc}</p>
                      <p className="text-danger">Phone.No : {value.phone}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>

          <div className="col-12 col-lg-5 payment-container  p-5" id="pay">
            <form
              onSubmit={handleSubmit}
              action="/paymentdetails"
              method="post"
              encType="multipart/form-data"
            >
              {/* <div>
                <h5 className="text-danger mt-1">
                  Note : "After making a payment through UPI or a bank account,
                  take a screenshot of the transaction, and then upload the
                  photo in the fields below."
                </h5>
              </div> */}
              <div className="mb-3 mt-5 inputWidthIcon">
                <div className="input-field mb-3  for-icon">
                  <TextField
                    fullWidth
                    // style={{    backgroundColor: "#ebebeb",color:"black"}}
                    label="Upgrade Package Name"
                    variant="outlined"
                    value={upgrademembership}
                    disabled
                  />
                </div>
              </div>
              {showField ? (


                <div className="mb-3 refid">

                  {id ? <TextField
                    fullWidth
                    id="referral"
                    name="referral"
                    label="Referral Id"
                    variant="outlined"
                    value={id}
                    disabled

                  /> : <TextField
                    fullWidth
                    id="referral"
                    name="referral"
                    label="Referral Id"
                    variant="outlined"
                    value={values.referral}

                    onChange={(e) => {
                      handleUserAddress(e);
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    placeholder="DCM1001"
                    maxLength="6"
                    error={touched.referral && !!errors.referral}
                    helperText={touched.referral && errors.referral}

                  />}

                </div>
              ) : (
                <div style={{ display: "none" }}></div>
              )}
              {/* 
              <div className="mb-3 inputWidthIcon w-100">
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="payment-method-label">
                    Select payment Mode
                  </InputLabel>
                  <Select
                    labelId="payment-method-label"
                    id="payment_method"
                    name="payment_method"
                    value={values.payment_method}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.payment_method && !!errors.payment_method}
                    helperText={touched.payment_method && errors.payment_method}
                    label="Select payment Mode"
                  >
                    <MenuItem value="">Select payment Mode</MenuItem>
                    <MenuItem value="direct_payment">
                      Direct Payment Mode
                    </MenuItem>
                    <MenuItem value="google_pay">Google Pay</MenuItem>
                    <MenuItem value="phone_pay">Phone Pay</MenuItem>
                    <MenuItem value="Upline">UpLine</MenuItem>

                    <MenuItem value="others">Others</MenuItem>
                  </Select>
                </FormControl>
                {touched.payment_method && errors.payment_method ? (
                  <div
                    style={{
                      color: "#dc3545",
                      fontSize: "13px",
                      marginLeft: "17px",
                    }}
                  >
                    {errors.payment_method}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="input-field mb-3 for-icon">
                <div className="icon-form"></div>
                <TextField
                  fullWidth
                  id="transaction"
                  name="transaction"
                  label="Transaction Id"
                  variant="outlined"
                  value={values.transaction}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Transaction Id"
                  maxLength="36"
                  error={touched.transaction && !!errors.transaction}
                  helperText={touched.transaction && errors.transaction}
                />
              </div>

              <div className="upload-image row mb-5">
                <label className="mb-2 text-secondary">
                  Upload Screenshot :
                </label>

                <input
                  type="file"
                  accept="image/*"
                  name="image"
                  required
                  onChange={handleChange16}
                />
              </div> */}

              <div className="text-center upload-button row" style={{ alignItems: 'center' }}>
                <div className="col-6"> Payment amount : ₹ {price}  </div>
                <div className="col-6 upbutton" style={{ marginTop: '10px' }}>
                  <button className="btn btn-warning text-white " type="submit" style={{ marginBottom: '10px' }}>
                    Pay now
                  </button>

                </div>
                <div className="row" >
                  <p style={{ color: 'red' }}>Note:
                    For easy and secure online payments, we are using PhonePe.</p>

                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Payment;
