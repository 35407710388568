import React, { useEffect, useState } from "react";
import Profile from "../assests/images/avatar_profile.png";
import Swal from "sweetalert2";

import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
function My_profile() {
  const [userDetails, setUserDetails] = useState("");
  const [membershipAndLevel, setMembershipAndLevel] = useState("");
  const [userId, setUserId] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [record, setRecord] = useState("");
  const [sino, setSino] = useState("");

  let id;
  const Token = localStorage.getItem("token");

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    const ownFunction = async () => {
      const storedUserDetail = localStorage.getItem("user_detail");
      if (storedUserDetail) {
        const parsedUserDetail = JSON.parse(storedUserDetail);
        setUserId(parsedUserDetail);
        const id = parsedUserDetail.id;
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/users/getuserdata`,
            { id: id }
          );
          console.log(response.data.userData, "");
          setLoading(false);
          setUserDetails(response.data.userData[0]);
        } catch (error) {
          setLoading(false);
          console.error(error);
        }
      }
    };
    ownFunction();
  }, []);

  useEffect(() => {
    setMembershipAndLevel(
      userDetails.membership_package + " " + userDetails.package_level
    );
  }, [userDetails]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/users/getuserdata`,
          { id: id }
        );
        if (response.status === 200) {
          const data = response;
          setRecord(data);
          setSino(id);
          console.log(data, "data");
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
  const handleInputChange = (field, value) => {
    setRecord((prevRecord) => ({
      ...prevRecord,
      [field]: value,
    }));
  };

  const containerStyle = {
    backgroundColor: "#ffbe0b",
    marginTop: "120px",
  };
  const navigate = useNavigate();

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };
  const dynamic = "Profile";
  if (loading) {
    return (
      <div className="loading">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    );

  }

  // const withdraw = async () => {
  //   const name = userDetails.name;
  //   const email = userDetails.email;

  //   const contact = userDetails.contact;

  //   const contactResponse = await axios.post(
  //     `${process.env.REACT_APP_API_BASE_URL}/users/contactID`,
  //     { name, email, contact }
  //   );

  //   if (contactResponse.status == 201) {
  //     const contactID = contactResponse.data.contactId;

  //     //update contact id api
  //     const userId = id;
  //     const updateresonse = await axios.put(
  //       `${process.env.REACT_APP_API_BASE_URL}/users/updateContactID`,
  //       { contactID, userId }
  //     );

  //     if (updateresonse.status == 200) {
  //       console.log(updateresonse, "contactId created");
  //     }
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/admin/accountedit/${sino}`,
        record
      );
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    }
  };
  const fetchData1 = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/users/getMembershipData`,
        {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        }
      );
      if (response.status === 200) {
        const data = response.data.userData[0];
        setRecord(data);
        setSino(data.id);
        console.log(data, "data");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const config = {
    headers: {
      Authorization: `Bearer ${Token}`,
    },
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/users/updateProfile`,
        record,
        config
      );
      if (response.status === 200) {
        
        const data =response.data.name[0].name
        let userDetails = JSON.parse(localStorage.getItem('user_detail'));
        let name =userDetails.name = data;
        
        
        localStorage.setItem('name', JSON.stringify(name)); 
        Swal.fire("Updated!", "The Profile has been Updated.", "success")
          .then(() => {
            setTimeout(() => {
              window.location.reload();
            }, 500);

            
          })
          .catch((error) => console.error(error));
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleShare = async () => {

    if(userDetails.membership_package === null){
      Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "If you can share your referral ID, you must first upgrade your membership to the super deal Package.",
      });
    }

  else{
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Check out DCrown Mart',
          text: `Hey there! I've been using this amazing website called DCrown Mart: https://dcrownmart.com/account/signup/${userDetails.user_address} and fantastic platform where you can earn money! Sign up using my reference ID `,
          // url: 'https://dcrownmart.com',
        })
        console.log('Shared successfully');
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      console.log('Share API not supported.');
      // Fallback if the Share API is not supported
      // Display a prompt or use a library like react-share for fallback options
    }
  }
  };

  return (
    <>
      <Helmet>
        <title> DCrown Mart | {dynamic}</title>
      </Helmet>
      <div className="container">
        <div className="row">
          <div className=" col-lg-8 profile-card  " style={containerStyle}>
            <div className="profile text-center">
              <div className="profile-img  ">
                <img
                  src={Profile}
                  className="img-responsive mt-3 rounded-circle"
                  height="10%"
                  width="10%"
                />
                <div className=" Withdraw d-flex justify-content-end ">
                  <button
                    className="btn btn-success "
                    onClick={() =>
                      navigate("/withdraw", {
                        state: {
                          userData: userDetails,
                        },
                      })
                    }
                  >
                    Withdraw
                  </button>
                </div>
              </div>
              <div className="profile-name ">
                <h3 className="text-light">{userDetails?.name}</h3>
              </div>
              <div className="profile-name2 mt-1 d-flex justify-content-center">
                <h4 className="text-light text-uppercase">
                  {userDetails?.membership_package == null
                    ? "Free Member"
                    : userDetails.membership_package}
                </h4>
              </div>
            </div>

            <div className="row wallet-details">
              <div className="col-lg-6 col-12 text-center">
                <div className="wallet">
                  <p>
                    <i className="fa-solid fa-wallet"></i> Wallet: ₹{" "}
                    {userDetails?.wallet == null ? "0.00" : userDetails?.wallet}
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-12 text-center mb-4">
                <div className="wallet">
                  <p>
                  <i class="fa-solid fa-coins"></i> Discount
                    Points :{" "}
                    {userDetails?.discount_point == null
                      ? "0"
                      : userDetails?.discount_point}
                  </p>
                </div>
              </div>
              {/* <div className="options fs-4 mt-1 text-center ">
              <h4 style={{ color: "#ffffff" }}>
                  Reference Id : {userDetails.user_address}
                </h4>
              </div> */}
            </div>

            <div className="profile-details mt-3">
              <div className="member-button text-center   d-flex justify-content-center">
                {userDetails.payment_status == "pending" ? (
                <div className="m-3 earn1 p-3">
                  <h5 className="  ">
                    Your Payment Details is being processed,approval might take a little time ...
                  </h5></div>
                ) : (
                  <div>
                    <div className="member-button text-center  mt-4 ">
                      {userDetails.membership_package == "royal_crown_kingdom" ?
                       <button
                        className=""
                        
                        onClick={() =>
                          Swal.fire(
                            "Success!",
                            "You completed All menbership packages",
                            "success"
                          )
                        }
                      >
                        Upgrade membership
                      </button>: <button
                        className=""
                        onClick={() =>
                          navigate("/payment", {
                            state: {
                              membershipType: userDetails.membership_package,
                            },
                          })
                        }
                      >
                        Upgrade membership
                      </button>}
                      
                    </div>
                  </div>
                )}
              </div>

              <div className="profile-options">
              
                <div className="options fs-4 text-center mt-3 earn"onClick={handleShare} >
                {userDetails.user_address} - Refer & Earn{" "}  -
                    <i className="fa-solid fa-share-from-square share"></i>
                 
                </div>
              
                <div className="options">
                  <button
                    className="btn1  "
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    onClick={() => fetchData1(userDetails.id)}
                  >
                    <i class="fa-solid fa-pen-to-square "></i> Edit Profile
                  </button>
                </div>
                <div className="options " >
                <button
                    className="btn1  "
                    
                    onClick={() => navigate("/withdrawHistory",{
                      state: {
                        id: userDetails.id
                      },
                    })}
                  >
                  <i class="fa-solid fa-money-bill me-2"></i>
                  Withdraw History
                  </button>
                </div>
                <div className="options btn10"onClick={() => navigate("/superdealTree",{
                      state: {
                        id: userDetails.id
                      },
                    })}>
                  <i
                    className="
                  fa-solid fa-flag-checkered
                  me-2 "
                  ></i>
                  Crown Super Deal Members
                </div>

                
                <div className="options btn10" onClick={() => navigate("/kingdomTree",{
                      state: {
                        id: userDetails.id
                      },
                    })}>
                  <i class="fa-solid fa-place-of-worship me-2"></i>Kingdom
                  Member
                </div>
                <div className="options btn10" onClick={() => navigate("/crownKingdomTree",{
                      state: {
                        id: userDetails.id
                      },
                    })}>
                  <i className="fas fa-crown me-2"></i>Crown Kingdom Members
                </div>
                <div className="options btn10" onClick={() => navigate("/royalCrownKingdomTree",{
                      state: {
                        id: userDetails.id
                      },
                    })}>
                  <i className="fas fa-crown me-2"></i>Royal Crown Kingdom Members
                </div>
               
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <form onSubmit={handleFormSubmit}>
            <div
              class="modal fade profile_update"
              id="exampleModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1
                      className="modal-title fs-5 text-center"
                      id="exampleModalLabel"
                    >
                      Profile Details
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="">
                      <label>Name :</label>
                      <input
                        type="text"
                        className=" w-100 mb-3 searchbar form-control"
                        value={record.name}
                        placeholder="Enter"
                        onChange={(e) =>
                          handleInputChange("name", e.target.value)
                        }
                      />
                    </div>
                    <div className="">
                      <label>Email:</label>
                      <input
                        type="text"
                        className=" w-100 mb-3 searchbar form-control"
                        value={record.email}
                        placeholder="Enter"
                        onChange={(e) =>
                          handleInputChange("email", e.target.value)
                        }
                      />
                    </div>

                    <label>Mobile No :</label>
                    <input
                      type="number"
                      className=" w-100 mb-3 searchbar form-control"
                      value={record.contact}
                      placeholder="Enter"
                      onChange={(e) =>
                        handleInputChange("contact", e.target.value)
                      }
                    />
                  </div>
                  <div className="modal-footer pro">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default My_profile;
